.filter-dropdown-overlay .ant-popover-content {
	border: 1px solid #d9d9d9;
}
.filter-dropdown-overlay .ant-popover-arrow {
	background: #fff;
	border-width: 1px;
}

.filter-dropdown-overlay .ant-popover-inner {
	border-radius: 0;
}
.filter-dropdown-overlay .ant-popover-inner-content {
	padding: 15px 10px;
}
.filter-dropdown-overlay.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #d9d9d9;
	border-left-color: #d9d9d9;
}

.popover-slider {
	width: 20rem;
}
.psuedo-dropdown-slider-wrap {
	display: flex;
	align-items: center;
	padding: 0 20px;
}
.psuedo-dropdown-slider {
	.ant-slider-track {
		position: absolute;
		height: 4px;
		background-color: #58514b;
	}
	.ant-slider-handle {
		border: 1px solid #58514b;
		background-color: #58514b;
	}
	&:hover .ant-slider-track {
		background-color: #58514b;
	}
	&:hover .ant-slider-handle:not(.ant-tooltip-open) {
		border-color: #58514b;
	}
	.ant-slider-handle:focus {
		box-shadow: none;
	}
}

.slider-title {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
	p {
		font-weight: 500;
	}
	span {
		font-weight: 700;
	}
}
