.psuedo-dropdown-icon {
	color: #d9d9d9;
	font-size: 12px;
}
.psuedo-dropdown-badge {
	margin: 0 5px 0 8px !important;
	.ant-badge-count {
		background: #d2502d;
	}
}

.psuedo-dropdown {
	border: 1px solid #d9d9d9;
	background-color: #fff;
	color: #323643;
	font-size: 14px;
	font-weight: 500;
	padding: 3px 6px;
	margin: 0 3px 0px 3px;
	outline: none;
	height: 45px;
	cursor: pointer;
	// ${props =>
	// 	props.visible &&
	// 	css`
	// 		border: 2px solid #323643;
	// 	`};
	.anticon {
		margin-left: 5px;
	}
	svg {
		-webkit-transition: -webkit-transform 0.3s;
		transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		transition: transform 0.3s, -webkit-transform 0.3s;
		// ${props =>
		// 	props.visible
		// 		? css`
		// 				transform: rotate(180deg);
		// 		  `
		// 		: css`
		// 				transform: rotate(0deg);
		// 		  `}
	}
}

.filter-dropdown-body {
	padding-left: 8px;
	padding-right: 18px;
	max-height: 15rem;
	overflow: scroll;
	// ${verticalScroll("4px")}
}

.filter-dropdown-form-control {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
}

.filter-dropdown-form-checkbox {
	.ant-checkbox-inner {
		border-color: rgba(191, 191, 191, 0.64);
		border-radius: 3px;
	}
	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: #58514b;
		border-color: #58514b;
	}
	.ant-checkbox-checked::after {
		border: 1px solid #58514b;
	}
	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: #58514b;
	}
	.ant-checkbox-checked::after {
		border: 1px solid #58514b;
		border-radius: 3px;
	}
	.ant-checkbox-inner::after {
		left: 22%;
	}
	span {
		font-size: 13px;
		color: #58514b;
		font-weight: 500;
	}
	&.ant-checkbox-wrapper:hover .ant-checkbox-inner {
		border-color: #58514b !important;
	}
}
.filter-dropdown-overlay {
	z-index: 1300 !important;
}
.filter-dropdown-overlay .ant-popover-content {
	border: 1px solid #d9d9d9;
}
.filter-dropdown-overlay .ant-popover-arrow {
	background: #fff;
	border-width: 1px;
}

.filter-dropdown-overlay .ant-popover-inner {
	border-radius: 0;
}
.filter-dropdown-overlay .ant-popover-inner-content {
	padding: 15px 10px;
}
.filter-dropdown-overlay.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #d9d9d9;
	border-left-color: #d9d9d9;
}
