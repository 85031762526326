.navbar-wrapper {
    width: 18rem;
    height: 100%;
    background-color: black;
    border-right: 1px solid white;
    .logo-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        border-bottom: 1px solid #6e6e6e;
        font-size: 30px;
        font-weight: 800;
        img {
            width: 80px;
            cursor: pointer;
        }
    }
    .navbar-list {
        display: flex;
        align-items: center;
		height: calc(100% - 6rem);
		 ::-webkit-scrollbar {
                display: none;
            }
        ul {
            list-style: none;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            overflow: scroll;

            li {
                display: flex;
                align-items: center;
                height: 2.5rem;
                margin-top: 1rem;
                padding: 0 1rem 0 1.3rem;
                color: #ffffff;
                font-size: 13px;
                font-weight: 700;
                margin-bottom: 0.8rem;
                cursor: pointer;
                .list-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;
                    border-radius: 3px;
                    margin-right: 15px;
                    width: 35px;
                    height: 35px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                    .ant-badge-count {
                        font-size: 10px;
                        box-shadow: none;
                        top: -2px;
                        right: -2px;
                    }
                    .logout {
                        font-size: 18px;
                        color: #ffffff;
                    }
                }

                &.active {
                    color: #f2d6a0;
                    .list-image {
                        background-color: #f2d6a0;
                    }
                }
                &:hover {
                    color: #f2d6a0;
                }
            }
        }
    }
    .navbar-bottom {
        height: 2rem;
        font-size: 14px;
        font-weight: 500;
        color: #fe9600;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            margin: 0;
            cursor: pointer;
        }
    }
}
