@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400&display=swap);
.login-wrapper .login-box .login-form .form-control {
  display: flex;
  flex-direction: column; }

.login-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.example-class {
  color: #ffa600; }

.example-tab-button {
  border-radius: 5px; }

.example-image {
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.login-wrapper {
  height: 100%;
  background-color: #f5f5f5; }
  .login-wrapper .login-box {
    width: 24rem;
    border-radius: 5px; }
    .login-wrapper .login-box .logo-box {
      display: flex;
      justify-content: center; }
      .login-wrapper .login-box .logo-box img.logo-icon {
        width: 90px; }
      .login-wrapper .login-box .logo-box img.logo-text {
        width: 82px; }
    .login-wrapper .login-box .sub-text {
      font-size: 11px;
      letter-spacing: 0px;
      color: #58514b;
      text-align: center;
      position: relative;
      top: -6px;
      display: inline;
      left: 46%;
      font-weight: 500; }
    .login-wrapper .login-box .login-form {
      height: 100%;
      margin-top: 1.2rem;
      padding: 1rem 2.5rem 2rem;
      background-color: #fff;
      box-shadow: 3px 4px 10px 2px #EEE;
      border-radius: 7px; }
      .login-wrapper .login-box .login-form-header {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        padding-bottom: 2rem; }
      .login-wrapper .login-box .login-form .form-control {
        font-size: 12px;
        width: 100%;
        margin-bottom: 1rem; }
        .login-wrapper .login-box .login-form .form-control .font-password {
          font-size: 26px;
          letter-spacing: 4px; }
        .login-wrapper .login-box .login-form .form-control button {
          position: relative;
          width: 100%;
          height: 50px;
          font-weight: 500;
          border-color: #58514b;
          border-radius: 2px; }
          .login-wrapper .login-box .login-form .form-control button .login-progress {
            position: absolute;
            left: 46%;
            color: #58514b; }
      .login-wrapper .login-box .login-form .forget-link {
        margin-top: 0;
        cursor: pointer;
        display: inherit;
        float: right;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 1rem; }
        .login-wrapper .login-box .login-form .forget-link a {
          color: #58514b; }
    .login-wrapper .login-box .login-footer {
      text-align: center;
      padding-top: 1rem;
      font-size: 15px;
      font-weight: 500;
      color: #001f45; }
      .login-wrapper .login-box .login-footer a {
        color: #58514b;
        margin-left: 6px; }

.confirmation-dialog .confirmation-content {
  width: 25rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 520px) {
    .confirmation-dialog .confirmation-content {
      width: 100%; } }
  .confirmation-dialog .confirmation-content p {
    font-size: 14px;
    text-align: center;
    line-height: 1.5; }
  .confirmation-dialog .confirmation-content button {
    height: 35px;
    min-width: 10rem;
    margin-top: 1rem; }
    .confirmation-dialog .confirmation-content button .modal-button-loader {
      position: absolute;
      left: 50%; }

.navbar-wrapper {
  width: 18rem;
  height: 100%;
  background-color: black;
  border-right: 1px solid white; }
  .navbar-wrapper .logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    border-bottom: 1px solid #6e6e6e;
    font-size: 30px;
    font-weight: 800; }
    .navbar-wrapper .logo-box img {
      width: 80px;
      cursor: pointer; }
  .navbar-wrapper .navbar-list {
    display: flex;
    align-items: center;
    height: calc(100% - 6rem); }
    .navbar-wrapper .navbar-list ::-webkit-scrollbar {
      display: none; }
    .navbar-wrapper .navbar-list ul {
      list-style: none;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: scroll; }
      .navbar-wrapper .navbar-list ul li {
        display: flex;
        align-items: center;
        height: 2.5rem;
        margin-top: 1rem;
        padding: 0 1rem 0 1.3rem;
        color: #ffffff;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 0.8rem;
        cursor: pointer; }
        .navbar-wrapper .navbar-list ul li .list-image {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          border-radius: 3px;
          margin-right: 15px;
          width: 35px;
          height: 35px; }
          .navbar-wrapper .navbar-list ul li .list-image img {
            width: 20px;
            height: 20px; }
          .navbar-wrapper .navbar-list ul li .list-image .ant-badge-count {
            font-size: 10px;
            box-shadow: none;
            top: -2px;
            right: -2px; }
          .navbar-wrapper .navbar-list ul li .list-image .logout {
            font-size: 18px;
            color: #ffffff; }
        .navbar-wrapper .navbar-list ul li.active {
          color: #f2d6a0; }
          .navbar-wrapper .navbar-list ul li.active .list-image {
            background-color: #f2d6a0; }
        .navbar-wrapper .navbar-list ul li:hover {
          color: #f2d6a0; }
  .navbar-wrapper .navbar-bottom {
    height: 2rem;
    font-size: 14px;
    font-weight: 500;
    color: #fe9600;
    display: flex;
    justify-content: center;
    align-items: center; }
    .navbar-wrapper .navbar-bottom p {
      margin: 0;
      cursor: pointer; }

.wrapper {
  display: flex;
  height: 100%; }
  .wrapper .main-body-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden; }
    .wrapper .main-body-wrapper::-webkit-scrollbar {
      -webkit-appearance: none; }
    .wrapper .main-body-wrapper::-webkit-scrollbar:vertical {
      width: 0px; }
    .wrapper .main-body-wrapper::-webkit-scrollbar:horizontal {
      height: 0px; }

.topbar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid #6e6e6e;
  padding: 0 2rem; }
  .topbar-wrapper .topbar-title {
    display: flex;
    align-items: center;
    color: #d2502d;
    font-size: 15px;
    font-weight: 500; }
    .topbar-wrapper .topbar-title .breadCrumb-separator {
      width: 10px; }
    .topbar-wrapper .topbar-title .ant-breadcrumb a {
      color: #d2502d;
      font-size: 15px;
      font-weight: 500; }
      .topbar-wrapper .topbar-title .ant-breadcrumb a:hover {
        text-decoration: underline; }
  .topbar-wrapper .topbar-filter {
    display: flex;
    justify-content: center;
    align-items: center; }
    .topbar-wrapper .topbar-filter .filter-img {
      width: 26px;
      margin-right: 5px; }
    .topbar-wrapper .topbar-filter p {
      font-size: 11px;
      color: #000000;
      font-weight: 700;
      margin: 0; }
    .topbar-wrapper .topbar-filter .filter-field {
      width: 150px;
      margin-left: 10px;
      margin-right: 10px; }
      .topbar-wrapper .topbar-filter .filter-field .ant-select-selection {
        box-shadow: none !important;
        border-color: #eee;
        font-size: 13px;
        height: 32px; }
        .topbar-wrapper .topbar-filter .filter-field .ant-select-selection:active, .topbar-wrapper .topbar-filter .filter-field .ant-select-selection:focus {
          border: 2px solid #fe9600;
          border-right-width: 3px !important; }
  .topbar-wrapper .topbar-brand-filter {
    display: flex;
    align-items: center;
    padding-right: 12rem; }
    .topbar-wrapper .topbar-brand-filter .filter-img {
      width: 24px;
      margin-right: 5px; }
    .topbar-wrapper .topbar-brand-filter p {
      font-size: 12px;
      color: #000000;
      margin-bottom: 0;
      width: 50px;
      font-weight: 500; }
    .topbar-wrapper .topbar-brand-filter .category-filter {
      display: flex;
      height: 33px;
      width: 190px;
      border: 1px solid #f2f2f2;
      border-radius: 3px;
      justify-content: space-between;
      cursor: pointer;
      margin-left: 10px; }
      .topbar-wrapper .topbar-brand-filter .category-filter p {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 0;
        padding-left: 10px;
        padding-top: 5px; }
    .topbar-wrapper .topbar-brand-filter .filter-field {
      width: 100px; }
  .topbar-wrapper .topbar-search {
    display: flex;
    align-items: center; }
    .topbar-wrapper .topbar-search input {
      border: 1px solid #eee;
      border-radius: 4px;
      width: 250px;
      padding: 5px 20px 5px 10px;
      background-image: url(/static/media/Search.8e18218b.svg);
      background-repeat: no-repeat;
      background-position: 98% 50%;
      background-size: 22px; }
      .topbar-wrapper .topbar-search input:active, .topbar-wrapper .topbar-search input:focus {
        border: 2px solid #58514b; }
  .topbar-wrapper .category-modal {
    display: flex;
    width: 825px;
    height: 450px;
    position: absolute;
    z-index: 2000;
    background-color: #fff;
    margin-top: 3.5rem;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    margin-left: 12rem; }
    .topbar-wrapper .category-modal-body {
      display: flex;
      padding: 30px;
      width: 100%; }
      .topbar-wrapper .category-modal-body .main-category {
        display: flex;
        flex-direction: column;
        width: 35%;
        color: #fe9600;
        font-size: 18px;
        font-weight: 500;
        border-right: 1px solid #d9d9d9; }
        .topbar-wrapper .category-modal-body .main-category p {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          padding-top: 15px; }
      .topbar-wrapper .category-modal-body .sub-category {
        display: flex;
        flex-direction: column;
        width: 35%;
        color: #fe9600;
        font-size: 18px;
        font-weight: 500;
        border-right: 1px solid #d9d9d9;
        padding-left: 20px; }
        .topbar-wrapper .category-modal-body .sub-category p {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          padding-top: 15px; }
      .topbar-wrapper .category-modal-body .sub-category-last {
        display: flex;
        flex-direction: column;
        width: 30%;
        color: #fe9600;
        font-size: 18px;
        font-weight: 500;
        padding-left: 20px; }
        .topbar-wrapper .category-modal-body .sub-category-last p {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          padding-top: 15px; }
  .topbar-wrapper .priority-modal {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 170px;
    position: absolute;
    z-index: 2000;
    background-color: #fff;
    margin-top: 3.5rem;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    margin-left: 30.3rem; }
    .topbar-wrapper .priority-modal .priority-checks {
      display: flex;
      width: 82%;
      padding-top: 15px;
      height: 40px;
      margin-left: 22px;
      border-bottom: 1px solid #f1f1f1; }
      .topbar-wrapper .priority-modal .priority-checks input {
        -webkit-appearance: initial;
                appearance: initial;
        background-color: #fff;
        width: 17px;
        height: 17px;
        border: 1px solid #707070;
        position: relative;
        border-radius: 4px;
        margin-top: 2px;
        cursor: pointer; }
        .topbar-wrapper .priority-modal .priority-checks input:checked {
          background-color: #fe9600; }
      .topbar-wrapper .priority-modal .priority-checks p {
        color: #000000;
        font-size: 15px;
        font-weight: 500;
        padding-left: 10px; }
    .topbar-wrapper .priority-modal .priority-Normal {
      display: flex;
      width: 82%;
      padding-top: 15px;
      height: 40px;
      margin-left: 22px; }
      .topbar-wrapper .priority-modal .priority-Normal input {
        -webkit-appearance: initial;
                appearance: initial;
        background-color: #fff;
        width: 17px;
        height: 17px;
        border: 1px solid #707070;
        position: relative;
        border-radius: 4px;
        margin-top: 2px;
        cursor: pointer; }
        .topbar-wrapper .priority-modal .priority-Normal input:checked {
          background-color: #fe9600; }
      .topbar-wrapper .priority-modal .priority-Normal p {
        color: #000000;
        font-size: 15px;
        font-weight: 500;
        padding-left: 10px; }

.ant-select-dropdown-menu-item:active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e5e5e5 !important; }

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e5e5e5 !important; }

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e5e5e5 !important; }

.ant-select-dropdown-menu .artwork-image {
  height: 30px;
  width: 30px;
  object-fit: cover;
  margin-right: 5px; }

.recharts-layer .recharts-cartesian-axis-tick-value {
  font-size: 10px;
  color: #282D32; }

.recharts-default-tooltip {
  font-size: 12px;
  color: #000;
  border: none; }

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100% - 4rem);
  overflow-y: scroll; }
  .dashboard-wrapper::-webkit-scrollbar {
    -webkit-appearance: none; }
  .dashboard-wrapper::-webkit-scrollbar:vertical {
    width: 12px; }
  .dashboard-wrapper::-webkit-scrollbar:horizontal {
    height: 0px; }
  .dashboard-wrapper::-webkit-scrollbar-thumb {
    background-color: #c4c9ca;
    border-radius: 0px;
    border: 2px solid transparent !important; }
  .dashboard-wrapper::-webkit-scrollbar-track {
    border-radius: 50%;
    background-color: transparent !important; }
  .dashboard-wrapper-totallist {
    display: flex;
    padding: 0px; }
    .dashboard-wrapper-totallist-item {
      display: flex;
      width: 28%;
      margin: 10px;
      background-color: #d2502d;
      padding: 2px 16px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
      .dashboard-wrapper-totallist-item:first-of-type {
        margin-left: 0; }
      .dashboard-wrapper-totallist-item:last-of-type {
        margin-right: 0; }
      .dashboard-wrapper-totallist-item .small-box {
        padding: 10px 0; }
      .dashboard-wrapper-totallist-item p {
        margin-bottom: 0;
        text-align: left;
        font-weight: 400;
        color: #ffffff; }
      .dashboard-wrapper-totallist-item .title {
        font-size: 24px; }
      .dashboard-wrapper-totallist-item .title-text {
        font-size: 14px; }
      .dashboard-wrapper-totallist-item img {
        height: 48px;
        width: 48px; }
      .dashboard-wrapper-totallist-item.clickable {
        cursor: pointer; }
        .dashboard-wrapper-totallist-item.clickable:hover {
          box-shadow: 3px 3px 6px -1px rgba(121, 85, 72, 0.780392); }
      .dashboard-wrapper-totallist-item.hidden {
        opacity: 0; }
    .dashboard-wrapper-totallist.ptop {
      padding-top: 3rem; }
    .dashboard-wrapper-totallist.pbot {
      padding-bottom: 3rem; }
  .dashboard-wrapper-graph {
    display: flex;
    flex-direction: column;
    box-shadow: 0.25px 0.25px 2px #eee;
    border-radius: 3px; }
    .dashboard-wrapper-graph-title {
      display: flex;
      justify-content: space-between;
      padding: 15px 25px 10px 25px; }
      .dashboard-wrapper-graph-title .title {
        font-size: 16px;
        color: #000000;
        font-weight: 700; }
      .dashboard-wrapper-graph-title .filter-year {
        display: flex; }
        .dashboard-wrapper-graph-title .filter-year p {
          font-size: 14px;
          color: #000000;
          padding-right: 7px;
          padding-top: 5px; }
    .dashboard-wrapper-graph .product-summary {
      display: flex;
      justify-content: center; }
      .dashboard-wrapper-graph .product-summary-info {
        display: flex;
        flex-direction: column;
        padding-right: 40px; }
        .dashboard-wrapper-graph .product-summary-info-title {
          font-size: 12px;
          color: #d2502d;
          margin-bottom: 0; }
        .dashboard-wrapper-graph .product-summary-info-value {
          font-size: 16px;
          color: #3e3e3e;
          font-weight: 700; }

.add-category-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .add-category-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.add-category-dialog .add-category-content {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  padding: 2% 14%;
  overflow-y: unset; }
  .add-category-dialog .add-category-content .add-category-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .add-category-dialog .add-category-content .add-category-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-category-dialog .add-category-content .add-category-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-category-dialog .add-category-content .add-category-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-category-dialog .add-category-content .add-category-body .bg-white {
      background-color: #fff; }
    .add-category-dialog .add-category-content .add-category-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-category-dialog .add-category-content .add-category-body .form-control input {
        width: 100%; }
  .add-category-dialog .add-category-content .add-category-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-category-dialog .add-category-content .add-category-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-category-dialog .add-category-content .add-category-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .add-category-dialog .add-category-content .add-category-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem; }
    .add-category-dialog .add-category-content .add-category-action button {
      width: 170px; }
      .add-category-dialog .add-category-content .add-category-action button:first-of-type {
        margin-right: 2%; }
    .add-category-dialog .add-category-content .add-category-action .modal-button-loader {
      position: absolute;
      right: 22%;
      color: #ffffff !important; }

.add-property-dialog .dialog-title {
  border-bottom: 1px solid #EEE; }
  .add-property-dialog .dialog-title h2 {
    color: #D2502D; }

.add-property-dialog .add-property-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px; }
  .add-property-dialog .add-property-content .form-input {
    height: 35px; }
  .add-property-dialog .add-property-content .dialog-sub-title {
    text-align: left;
    margin-bottom: 1rem;
    font-size: 15px !important;
    font-weight: 500;
    color: #000; }
  .add-property-dialog .add-property-content .add-property-body {
    display: flex;
    align-items: center; }
    .add-property-dialog .add-property-content .add-property-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 1rem; }
      .add-property-dialog .add-property-content .add-property-body .form-control input {
        width: 100%; }
  .add-property-dialog .add-property-content .add-property-featured-box {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 0 5px; }
    .add-property-dialog .add-property-content .add-property-featured-box .ant-checkbox-inner {
      border-radius: 4px; }
    .add-property-dialog .add-property-content .add-property-featured-box .ant-checkbox-wrapper {
      margin-right: 10px; }
    .add-property-dialog .add-property-content .add-property-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-property-dialog .add-property-content .add-property-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #D2502D; }
  .add-property-dialog .add-property-content .add-property-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .add-property-dialog .add-property-content .add-property-content-wrapper .dialog-sub-title {
      min-width: 65%; }
    .add-property-dialog .add-property-content .add-property-content-wrapper .add-property-body {
      width: 65%; }
    .add-property-dialog .add-property-content .add-property-content-wrapper hr {
      color: #EEE !important;
      width: 75%;
      margin: 0;
      border-top: none;
      border-left: none;
      border-right: none; }
  .add-property-dialog .add-property-content .add-suggestion-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem; }
    .add-property-dialog .add-property-content .add-suggestion-wrapper .suggestion-details {
      display: flex;
      background-color: #F6F6F6;
      border-radius: 3px;
      flex-direction: column;
      width: 65%;
      padding-bottom: 20px; }
    .add-property-dialog .add-property-content .add-suggestion-wrapper .dialog-sub-title {
      min-width: 65%;
      padding: 10px 20px;
      margin-bottom: 0; }
    .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-body {
      display: flex;
      align-items: center;
      position: relative; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-body .form-control {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-body .form-control input {
          width: 100%; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-body .close-btn {
        position: absolute;
        right: 20px;
        bottom: 25px;
        padding: 0;
        border: 1px solid #F6F6F6;
        background-color: #F6F6F6;
        border-radius: 10px; }
    .add-property-dialog .add-property-content .add-suggestion-wrapper .suggestion-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around; }
    .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-featured-box {
      display: flex;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      padding: 0 20px; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-featured-box .ant-checkbox-inner {
        border-radius: 4px; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-featured-box .ant-checkbox-wrapper {
        margin-right: 10px; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-featured-box .ant-checkbox-inner {
        border-color: #D2502D; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-property-featured-box .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #D2502D; }
    .add-property-dialog .add-property-content .add-suggestion-wrapper .add-filter-button {
      float: right;
      height: 33px;
      width: 5rem;
      padding: 3px 10px;
      border-radius: 3px;
      background-color: #FFF;
      border: 1px solid #58514B;
      color: #58514B;
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
      .add-property-dialog .add-property-content .add-suggestion-wrapper .add-filter-button:hover, .add-property-dialog .add-property-content .add-suggestion-wrapper .add-filter-button:active, .add-property-dialog .add-property-content .add-suggestion-wrapper .add-filter-button:focus {
        background-color: #58514B;
        border: 1px solid #58514B;
        color: #FFF; }
  .add-property-dialog .add-property-content .add-property-filter-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem; }
    .add-property-dialog .add-property-content .add-property-filter-wrapper .filter-form-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll;
      width: 100%;
      align-items: center; }
    .add-property-dialog .add-property-content .add-property-filter-wrapper .dialog-sub-title {
      min-width: 65%; }
    .add-property-dialog .add-property-content .add-property-filter-wrapper .add-property-body {
      border-radius: 5px;
      padding: 1rem 0rem 0rem 1rem;
      margin-bottom: 5px;
      min-width: 65%;
      justify-content: center;
      border: 1px solid #CDCDCD; }
    .add-property-dialog .add-property-content .add-property-filter-wrapper .filter-close-btn {
      position: relative;
      float: right;
      top: -40px;
      right: 6px;
      cursor: pointer; }
    .add-property-dialog .add-property-content .add-property-filter-wrapper .add-filter-button {
      float: right;
      height: 33px;
      width: 5rem;
      padding: 3px 10px;
      border-radius: 3px;
      background-color: #FFF;
      border: 1px solid #58514B;
      color: #58514B;
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
      .add-property-dialog .add-property-content .add-property-filter-wrapper .add-filter-button:hover, .add-property-dialog .add-property-content .add-property-filter-wrapper .add-filter-button:active, .add-property-dialog .add-property-content .add-property-filter-wrapper .add-filter-button:focus {
        background-color: #58514B;
        border: 1px solid #58514B;
        color: #FFF; }

.add-property-dialog .add-property-action {
  display: flex;
  justify-content: center; }
  .add-property-dialog .add-property-action button {
    width: 25%;
    height: 35px; }
    .add-property-dialog .add-property-action button:first-of-type {
      margin-right: 2%; }
  .add-property-dialog .add-property-action .modal-button-loader {
    position: absolute;
    left: 70%; }

.delete-mainCategory-dialog .delete-mainCategory-content {
  width: 25rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .delete-mainCategory-dialog .delete-mainCategory-content p {
    font-size: 14px;
    text-align: center;
    line-height: 1.5; }
  .delete-mainCategory-dialog .delete-mainCategory-content .checkboxConfirmation {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .delete-mainCategory-dialog .delete-mainCategory-content .checkboxConfirmation label {
      margin-right: 5px; }
    .delete-mainCategory-dialog .delete-mainCategory-content .checkboxConfirmation .ant-checkbox-inner {
      border-color: #707070; }
    .delete-mainCategory-dialog .delete-mainCategory-content .checkboxConfirmation .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #FE9600; }
  .delete-mainCategory-dialog .delete-mainCategory-content button {
    height: 35px;
    min-width: 10rem;
    margin-top: 1rem;
    text-transform: inherit; }
    .delete-mainCategory-dialog .delete-mainCategory-content button .modal-button-loader {
      position: absolute;
      left: 50%; }

.category-wrapper {
  display: flex;
  height: calc(100% - 4rem); }
  .category-wrapper .category-list {
    width: 50%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
  .category-wrapper div.category-list-wrapper {
    height: 90%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    font-weight: 500; }
    .category-wrapper div.category-list-wrapper .circular-loader {
      margin-bottom: 10px; }
  .category-wrapper .list-head {
    display: flex;
    height: 2.5rem; }
    .category-wrapper .list-head .list-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e1e1;
      font-weight: 500;
      color: #000000;
      cursor: pointer; }
    .category-wrapper .list-head .active {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      background-color: #58514b;
      color: #ffffff; }
  .category-wrapper ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 90%;
    overflow-y: scroll; }
    .category-wrapper ul::-webkit-scrollbar {
      -webkit-appearance: none; }
    .category-wrapper ul::-webkit-scrollbar:vertical {
      width: 5px; }
    .category-wrapper ul::-webkit-scrollbar:horizontal {
      height: 0px; }
    .category-wrapper ul::-webkit-scrollbar-thumb {
      background-color: #fce9d1;
      border-radius: 5px;
      border: 2px solid transparent !important; }
    .category-wrapper ul::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
    .category-wrapper ul li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      border-left: 3px solid transparent;
      cursor: pointer; }
      .category-wrapper ul li .list-item-content {
        display: flex;
        align-items: center;
        flex: 3 1;
        padding: 0 0 0 1rem;
        height: 100%; }
        .category-wrapper ul li .list-item-content img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          margin-right: 15px;
          padding: 2px 1px;
          background-color: #eee;
          border: 1px solid #dcdcdc;
          border-radius: 2px; }
        .category-wrapper ul li .list-item-content p {
          margin: 0;
          color: #000000;
          font-size: 13px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 12rem; }
      .category-wrapper ul li .list-item-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem 0 0rem;
        flex: 1 1;
        height: 100%; }
        .category-wrapper ul li .list-item-action img.more-icon {
          display: block;
          width: 22px; }
        .category-wrapper ul li .list-item-action .edit-options {
          display: none; }
      .category-wrapper ul li.active {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .category-wrapper ul li.active .list-item-action img.more-icon {
          display: none; }
        .category-wrapper ul li.active .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .category-wrapper ul li.active .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .category-wrapper ul li.active .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .category-wrapper ul li.active .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
      .category-wrapper ul li:hover {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .category-wrapper ul li:hover .list-item-action img.more-icon {
          display: none; }
        .category-wrapper ul li:hover .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .category-wrapper ul li:hover .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .category-wrapper ul li:hover .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .category-wrapper ul li:hover .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
  .category-wrapper .add-button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    position: unset; }
    .category-wrapper .add-button-box .add-btn {
      border: 1px solid #58514b;
      border-radius: 3px;
      height: 35px;
      border: 1px solid #58514b;
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 0.25px 0.25px 12px #00000029;
      cursor: pointer;
      color: #58514b;
      font-size: 12px; }
      .category-wrapper .add-button-box .add-btn p {
        font-size: 20px;
        color: #58514b;
        margin: 0;
        padding-right: 5px; }

.list-rule-tax-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .list-rule-tax-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.list-rule-tax-dialog .list-rule-tax-content {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2% 14%; }
  .list-rule-tax-dialog .list-rule-tax-content .table-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column; }
    .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .head-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem; }
    .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .main-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .content-wrapper {
      display: flex;
      width: 100%; }
    .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .head-item {
      width: 100%;
      color: #000000;
      font-size: 16px;
      font-weight: 600; }
    .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .content-item {
      width: 100%;
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 10px; }
    .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .action-wrapper {
      display: flex;
      width: 50%;
      align-items: center; }
      .list-rule-tax-dialog .list-rule-tax-content .table-wrapper .action-wrapper :first-child {
        margin-right: 1rem; }
  .list-rule-tax-dialog .list-rule-tax-content .dialog-sub-title {
    width: 100%;
    padding-bottom: 20px; }
    .list-rule-tax-dialog .list-rule-tax-content .dialog-sub-title span {
      font-size: 16px;
      font-weight: 600; }
  .list-rule-tax-dialog .list-rule-tax-content .filter-close-btn {
    position: relative;
    float: right;
    top: -40px;
    right: 6px;
    cursor: pointer; }
  .list-rule-tax-dialog .list-rule-tax-content .add-filter-button {
    float: right;
    height: 33px;
    width: 5rem;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #58514b;
    color: #58514b;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
    .list-rule-tax-dialog .list-rule-tax-content .add-filter-button:hover, .list-rule-tax-dialog .list-rule-tax-content .add-filter-button:active, .list-rule-tax-dialog .list-rule-tax-content .add-filter-button:focus {
      background-color: #58514b;
      border: 1px solid #58514b;
      color: #fff; }
  .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 10px; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .bg-white {
      background-color: #fff; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .form-control input {
        width: 100%; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .form-control-new {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 1rem; }
      .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-body .form-control-new input {
        width: 100%; }
  .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-action button {
      width: 170px; }
      .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-action button:first-of-type {
        margin-right: 2%; }
    .list-rule-tax-dialog .list-rule-tax-content .list-rule-tax-action .modal-button-loader {
      position: absolute;
      right: 22%;
      color: #ffffff !important; }

.add-rule-tax-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .add-rule-tax-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.add-rule-tax-dialog .add-rule-tax-content {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2% 14%; }
  .add-rule-tax-dialog .add-rule-tax-content .table-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column; }
    .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .head-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem; }
    .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .main-content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .content-wrapper {
      display: flex;
      width: 100%; }
    .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .head-item {
      width: 100%;
      color: #000000;
      font-size: 16px;
      font-weight: 600; }
    .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .content-item {
      width: 100%;
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 10px; }
    .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .action-wrapper {
      display: flex;
      width: 50%;
      align-items: center; }
      .add-rule-tax-dialog .add-rule-tax-content .table-wrapper .action-wrapper :first-child {
        margin-right: 1rem; }
  .add-rule-tax-dialog .add-rule-tax-content .dialog-sub-title {
    width: 100%;
    padding-bottom: 20px; }
    .add-rule-tax-dialog .add-rule-tax-content .dialog-sub-title span {
      font-size: 16px;
      font-weight: 600; }
  .add-rule-tax-dialog .add-rule-tax-content .filter-close-btn {
    position: relative;
    float: right;
    top: -40px;
    right: 6px;
    cursor: pointer; }
  .add-rule-tax-dialog .add-rule-tax-content .add-filter-button {
    float: right;
    height: 33px;
    width: 5rem;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #58514b;
    color: #58514b;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
    .add-rule-tax-dialog .add-rule-tax-content .add-filter-button:hover, .add-rule-tax-dialog .add-rule-tax-content .add-filter-button:active, .add-rule-tax-dialog .add-rule-tax-content .add-filter-button:focus {
      background-color: #58514b;
      border: 1px solid #58514b;
      color: #fff; }
  .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 10px; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .bg-white {
      background-color: #fff; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .form-control input {
        width: 100%; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .form-control-new {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 1rem; }
      .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-body .form-control-new input {
        width: 100%; }
  .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-action button {
      width: 170px; }
      .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-action button:first-of-type {
        margin-right: 2%; }
    .add-rule-tax-dialog .add-rule-tax-content .add-rule-tax-action .modal-button-loader {
      position: absolute;
      right: 22%;
      color: #ffffff !important; }

.manageartworks-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .manageartworks-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .manageartworks-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
    .manageartworks-wrapper .filter-wrapper .filter-field {
      width: 200px; }
      .manageartworks-wrapper .filter-wrapper .filter-field .ant-select-selection {
        height: 45px !important; }
      .manageartworks-wrapper .filter-wrapper .filter-field .ant-select-selection__rendered {
        line-height: 45px; }
    .manageartworks-wrapper .filter-wrapper button {
      height: 45px;
      margin-left: 10px;
      margin-bottom: 0px; }
    .manageartworks-wrapper .filter-wrapper .artist-search {
      display: flex;
      align-items: center;
      padding-left: 10px; }
      .manageartworks-wrapper .filter-wrapper .artist-search input {
        border: 1px solid #eee;
        border-radius: 4px;
        width: 250px;
        height: 45px;
        padding: 5px 20px 5px 10px;
        background-image: url(/static/media/Search.8e18218b.svg);
        background-repeat: no-repeat;
        background-position: 98% 50%;
        background-size: 22px; }
        .manageartworks-wrapper .filter-wrapper .artist-search input:active, .manageartworks-wrapper .filter-wrapper .artist-search input:focus {
          border: 2px solid #58514b; }
    .manageartworks-wrapper .filter-wrapper .silder-filter {
      display: flex;
      height: 100%; }
    .manageartworks-wrapper .filter-wrapper .price-text-wrapper {
      display: flex;
      justify-content: space-between; }
    .manageartworks-wrapper .filter-wrapper .price-text-wrapper input {
      width: 80px;
      height: 30px;
      border: 0.5px solid #e5e5e5;
      opacity: 1;
      color: black;
      text-align: center; }
  .manageartworks-wrapper .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 1600px) {
      .manageartworks-wrapper .sub-wrapper {
        overflow-x: scroll;
        overflow-y: scroll; } }
    .manageartworks-wrapper .sub-wrapper .colWrapper {
      display: flex;
      position: relative; }
    .manageartworks-wrapper .sub-wrapper .labelImage {
      position: absolute;
      top: 0px;
      left: -0.5px; }
    .manageartworks-wrapper .sub-wrapper .colImage {
      height: 60px;
      width: 60px;
      object-fit: fill; }
    .manageartworks-wrapper .sub-wrapper .colDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px; }
    .manageartworks-wrapper .sub-wrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .manageartworks-wrapper .sub-wrapper .colGreyText {
      color: #727272;
      margin: 0;
      font-size: 12px;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .manageartworks-wrapper .sub-wrapper .rftext {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .manageartworks-wrapper .sub-wrapper .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .manageartworks-wrapper .sub-wrapper .textCenterBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-align: center; }
    .manageartworks-wrapper .sub-wrapper .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fe9600; }
    .manageartworks-wrapper .sub-wrapper .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0b969b; }
    .manageartworks-wrapper .sub-wrapper .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0072d6; }
    .manageartworks-wrapper .sub-wrapper .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #499b0b; }
    .manageartworks-wrapper .sub-wrapper .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #d2502d; }
    .manageartworks-wrapper .sub-wrapper .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #9b0b1e; }
    .manageartworks-wrapper .sub-wrapper .imageWrapper {
      text-align: center; }
    .manageartworks-wrapper .sub-wrapper .star-image {
      margin-left: 18px;
      height: 15px;
      width: 15px; }
    .manageartworks-wrapper .sub-wrapper .dropDown {
      display: flex;
      align-items: center;
      height: 35px;
      background-color: #f5f5f5;
      border-radius: 4px;
      width: 11rem;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px; }
      .manageartworks-wrapper .sub-wrapper .dropDown .anticon.anticon-down {
        font-weight: 900; }
    .manageartworks-wrapper .sub-wrapper .artistDiv {
      display: flex;
      align-items: center; }
      .manageartworks-wrapper .sub-wrapper .artistDiv img {
        height: 11px;
        width: 11px;
        margin-right: 8px;
        margin-bottom: 2px; }
      .manageartworks-wrapper .sub-wrapper .artistDiv p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        width: 6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    .manageartworks-wrapper .sub-wrapper .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000; }
    .manageartworks-wrapper .sub-wrapper .sorter-no-tooltip .ant-table-column-sorters:before {
      content: none !important; }
    .manageartworks-wrapper .sub-wrapper .ant-select {
      margin-right: 10px; }
    .manageartworks-wrapper .sub-wrapper .ant-select-selection:focus,
    .manageartworks-wrapper .sub-wrapper .ant-select-selection:active,
    .manageartworks-wrapper .sub-wrapper .ant-select-selection:hover {
      border-color: #000000;
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
    .manageartworks-wrapper .sub-wrapper .ant-table {
      color: #000000;
      font-weight: 500;
      margin-bottom: 40px; }
    .manageartworks-wrapper .sub-wrapper .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2rem;
      background-color: #f5f5f5;
      color: #000000; }
      .manageartworks-wrapper .sub-wrapper .ant-table-thead > tr > th:last-child {
        padding: 10px 2rem 10px 10px; }
    .manageartworks-wrapper .sub-wrapper .ant-table-tbody > tr {
      cursor: pointer; }
    .manageartworks-wrapper .sub-wrapper .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem; }
      .manageartworks-wrapper .sub-wrapper .ant-table-tbody > tr > td:last-child {
        padding: 10px 10px 10px 10px; }
    .manageartworks-wrapper .sub-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e3d2b0;
      box-shadow: 0px 5px 0px 0px #e3d2b0; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      margin-bottom: 20px; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item {
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      border-color: #eeeeee;
      border-radius: 0; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-jump-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item a {
      color: #000000; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item:focus a,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item:hover a {
      color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item-active:focus a,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item-active:hover a,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item.ant-pagination-item-active a {
      border-color: #58514b;
      color: #ffffff; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item-active {
      background-color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item:focus,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-item:hover {
      border-color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .anticon {
      color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-prev .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-next .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .manageartworks-wrapper .sub-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #58514b; }
    .manageartworks-wrapper .sub-wrapper .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.ant-slider-track {
  background-color: #7d7d7d !important; }

.ant-slider-handle {
  border: solid 2px #707070 !important; }

.manage-artworks-action-popover .ant-popover-inner {
  background-color: #000000; }

.manage-artworks-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.manage-artworks-action-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000;
  border-bottom-color: #000000;
  border-right-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain; }
  .action-image:hover {
    cursor: pointer; }

.action-span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8b8b8b;
  justify-content: flex-start;
  cursor: pointer; }
  .action-span .anticon {
    margin-right: 8px; }
  .action-span img {
    height: 20px;
    width: 20px;
    margin-right: 5px; }
  .action-span .pop-item {
    color: #ffffff;
    margin: 0; }
    .action-span .pop-item:hover {
      color: #ad9972; }

.checkbox-wrapper {
  border: 1px solid #d9d9d9;
  padding: 10px; }

.psuedo-dropdown-icon {
  color: #d9d9d9;
  font-size: 12px; }

.psuedo-dropdown-badge {
  margin: 0 5px 0 8px !important; }
  .psuedo-dropdown-badge .ant-badge-count {
    background: #d2502d; }

.psuedo-dropdown {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  color: #323643;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 6px;
  margin: 0 3px 0px 3px;
  outline: none;
  height: 45px;
  cursor: pointer; }
  .psuedo-dropdown .anticon {
    margin-left: 5px; }
  .psuedo-dropdown svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }

.filter-dropdown-body {
  padding-left: 8px;
  padding-right: 18px;
  max-height: 15rem;
  overflow: scroll; }

.filter-dropdown-form-control {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem; }

.filter-dropdown-form-checkbox .ant-checkbox-inner {
  border-color: rgba(191, 191, 191, 0.64);
  border-radius: 3px; }

.filter-dropdown-form-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #58514b;
  border-color: #58514b; }

.filter-dropdown-form-checkbox .ant-checkbox-checked::after {
  border: 1px solid #58514b; }

.filter-dropdown-form-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.filter-dropdown-form-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.filter-dropdown-form-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #58514b; }

.filter-dropdown-form-checkbox .ant-checkbox-checked::after {
  border: 1px solid #58514b;
  border-radius: 3px; }

.filter-dropdown-form-checkbox .ant-checkbox-inner::after {
  left: 22%; }

.filter-dropdown-form-checkbox span {
  font-size: 13px;
  color: #58514b;
  font-weight: 500; }

.filter-dropdown-form-checkbox.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #58514b !important; }

.filter-dropdown-overlay {
  z-index: 1300 !important; }

.filter-dropdown-overlay .ant-popover-content {
  border: 1px solid #d9d9d9; }

.filter-dropdown-overlay .ant-popover-arrow {
  background: #fff;
  border-width: 1px; }

.filter-dropdown-overlay .ant-popover-inner {
  border-radius: 0; }

.filter-dropdown-overlay .ant-popover-inner-content {
  padding: 15px 10px; }

.filter-dropdown-overlay.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #d9d9d9;
  border-left-color: #d9d9d9; }

.filter-dropdown-overlay .ant-popover-content {
  border: 1px solid #d9d9d9; }

.filter-dropdown-overlay .ant-popover-arrow {
  background: #fff;
  border-width: 1px; }

.filter-dropdown-overlay .ant-popover-inner {
  border-radius: 0; }

.filter-dropdown-overlay .ant-popover-inner-content {
  padding: 15px 10px; }

.filter-dropdown-overlay.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.filter-dropdown-overlay.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #d9d9d9;
  border-left-color: #d9d9d9; }

.popover-slider {
  width: 20rem; }

.psuedo-dropdown-slider-wrap {
  display: flex;
  align-items: center;
  padding: 0 20px; }

.psuedo-dropdown-slider .ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #58514b; }

.psuedo-dropdown-slider .ant-slider-handle {
  border: 1px solid #58514b;
  background-color: #58514b; }

.psuedo-dropdown-slider:hover .ant-slider-track {
  background-color: #58514b; }

.psuedo-dropdown-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #58514b; }

.psuedo-dropdown-slider .ant-slider-handle:focus {
  box-shadow: none; }

.slider-title {
  display: flex;
  justify-content: space-between;
  font-size: 13px; }
  .slider-title p {
    font-weight: 500; }
  .slider-title span {
    font-weight: 700; }

.add-kyc-dialog .add-kyc-content {
  width: 37rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .add-kyc-dialog .add-kyc-content .add-kyc-body {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center; }
    .add-kyc-dialog .add-kyc-content .add-kyc-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-kyc-dialog .add-kyc-content .add-kyc-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-kyc-dialog .add-kyc-content .add-kyc-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-kyc-dialog .add-kyc-content .add-kyc-body .bg-white {
      background-color: #fff; }
    .add-kyc-dialog .add-kyc-content .add-kyc-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-kyc-dialog .add-kyc-content .add-kyc-body .form-control input {
        width: 100%; }
  .add-kyc-dialog .add-kyc-content .add-kyc-featured-box {
    display: flex;
    width: 90%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px;
    flex-direction: column; }
    .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section h6 {
      color: #747474;
      font-size: 14px;
      font-weight: 400; }
    .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section h4 {
      color: #000000;
      font-size: 16px; }
    .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 20px; }
      .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .image-container {
        margin-right: 15px;
        align-items: center;
        display: flex;
        flex-direction: column;
        cursor: pointer; }
        .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .image-container img {
          width: 7rem;
          height: 7rem;
          object-fit: contain;
          margin-top: 10px;
          background-color: #58514b;
          padding: 10px; }
        .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .image-container h4 {
          color: #747474;
          font-size: 14px;
          font-weight: 400; }
        .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .image-container h5 {
          font-size: 12px;
          color: #747474; }
        .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .image-container .viewpdf {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7rem;
          height: 7rem;
          background-color: #58514b;
          border: 1px solid #58514b !important;
          color: #ffffff;
          font-size: 16px;
          border-radius: 5px;
          margin-top: 10px; }
      .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .ant-checkbox-inner {
        border-color: #707070; }
      .add-kyc-dialog .add-kyc-content .add-kyc-featured-box .section-image .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #fe9600; }
  .add-kyc-dialog .add-kyc-content .add-kyc-action {
    display: flex;
    width: 90%;
    justify-content: space-around; }
    .add-kyc-dialog .add-kyc-content .add-kyc-action button {
      width: 35%;
      margin-right: 2%; }
      .add-kyc-dialog .add-kyc-content .add-kyc-action button:first-of-type {
        margin-right: 2%; }
    .add-kyc-dialog .add-kyc-content .add-kyc-action .modal-button-loader {
      position: absolute;
      left: 70%; }
  .add-kyc-dialog .add-kyc-content .dialog-title h2 {
    font-size: 24px !important; }
  .add-kyc-dialog .add-kyc-content .hr-line {
    border-bottom: solid 0.4px gray;
    margin: 15px 0; }
  .add-kyc-dialog .add-kyc-content .no-record {
    color: #747474;
    text-align: center; }

.manageusers-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .manageusers-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .manageusers-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
    .manageusers-wrapper .filter-wrapper .filter-field {
      width: 200px;
      margin: 0 5px; }
      .manageusers-wrapper .filter-wrapper .filter-field .ant-select-selection {
        height: 45px !important; }
      .manageusers-wrapper .filter-wrapper .filter-field .ant-select-selection__rendered {
        line-height: 45px; }
  .manageusers-wrapper .top-wrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0; }
    .manageusers-wrapper .top-wrapper span {
      height: 35px;
      border: 1px solid #929190;
      width: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #989898;
      cursor: pointer; }
    .manageusers-wrapper .top-wrapper .active {
      background-color: #58514b;
      color: #ffffff; }
    .manageusers-wrapper .top-wrapper .second-active {
      margin-left: 20px;
      background-color: #58514b;
      color: #ffffff; }
    .manageusers-wrapper .top-wrapper .second-span {
      margin-left: 20px; }
  .manageusers-wrapper .colWrapper {
    display: flex;
    align-items: center;
    width: 12rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .manageusers-wrapper .colWrapper .colImage {
      height: 27px;
      width: 27px;
      object-fit: fill; }
    .manageusers-wrapper .colWrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      padding-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 12rem; }
    .manageusers-wrapper .colWrapper .icon-image {
      height: 27px;
      width: 27px;
      border: 1px solid #5a5a5a;
      font-size: 21px;
      padding: 2px;
      color: #1a1818; }
  .manageusers-wrapper .star-image {
    margin-left: 18px;
    height: 15px;
    width: 15px; }
  .manageusers-wrapper .countText {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    margin-right: 3rem; }
  .manageusers-wrapper .mailtext {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 12rem; }
  .manageusers-wrapper .textBlack {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000; }
  .manageusers-wrapper .textnewBlack {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    text-align: center; }
  .manageusers-wrapper .textnewYellow {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #d2a44c;
    text-align: center; }
  .manageusers-wrapper .textOrange {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #fe9600; }
  .manageusers-wrapper .textBlue {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #0b969b; }
  .manageusers-wrapper .textDarkBlue {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #0072d6; }
  .manageusers-wrapper .textGreen {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #499b0b; }
  .manageusers-wrapper .textBrown {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #d2502d; }
  .manageusers-wrapper .textRed {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #9b0b1e; }
  .manageusers-wrapper .empty-table {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: 100%;
    align-items: center;
    font-size: 15px;
    color: #000000; }
  .manageusers-wrapper .ant-select {
    margin-right: 10px; }
  .manageusers-wrapper .ant-select-selection:focus,
  .manageusers-wrapper .ant-select-selection:active,
  .manageusers-wrapper .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .manageusers-wrapper .ant-table {
    color: #000000;
    font-weight: 400;
    margin-bottom: 20px; }
    @media screen and (max-width: 1395px) {
      .manageusers-wrapper .ant-table {
        overflow-x: scroll;
        overflow-y: scroll; } }
  .manageusers-wrapper .ant-table-thead > tr > th {
    padding: 10px 10px 10px 2.2rem;
    background-color: #f5f5f5;
    color: #000000; }
  .manageusers-wrapper .ant-table-tbody > tr {
    cursor: pointer;
    border-left: 4px solid #ffffff; }
    .manageusers-wrapper .ant-table-tbody > tr:hover {
      border-left: 4px solid #d2502d; }
  .manageusers-wrapper .ant-table-tbody > tr > td {
    padding: 10px 10px 10px 2rem;
    border-bottom: none; }
    .manageusers-wrapper .ant-table-tbody > tr > td p {
      margin-bottom: 0; }
  .manageusers-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #e3d2b0; }
  .manageusers-wrapper .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin-bottom: 20px; }
  .manageusers-wrapper .ant-pagination-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageusers-wrapper .ant-pagination-item {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-color: #eeeeee;
    border-radius: 0; }
  .manageusers-wrapper .ant-pagination-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageusers-wrapper .ant-pagination-jump-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageusers-wrapper .ant-pagination-jump-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageusers-wrapper .ant-pagination-item a {
    color: #000000; }
  .manageusers-wrapper .ant-pagination-item:focus a,
  .manageusers-wrapper .ant-pagination-item:hover a {
    color: #58514b; }
  .manageusers-wrapper .ant-pagination-item-active:focus a,
  .manageusers-wrapper .ant-pagination-item-active:hover a,
  .manageusers-wrapper .ant-pagination-item.ant-pagination-item-active a {
    border-color: #58514b;
    color: #ffffff; }
  .manageusers-wrapper .ant-pagination-item-active {
    background-color: #58514b; }
  .manageusers-wrapper .ant-pagination-item:focus,
  .manageusers-wrapper .ant-pagination-item:hover {
    border-color: #58514b; }
  .manageusers-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #58514b; }
  .manageusers-wrapper .anticon {
    color: #58514b; }
  .manageusers-wrapper .ant-pagination-prev .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .manageusers-wrapper .ant-pagination-next .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .manageusers-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
  .manageusers-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
  .manageusers-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
  .manageusers-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #58514b; }
  .manageusers-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .manageusers-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #58514b; }
  .manageusers-wrapper .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%; }
  .manageusers-wrapper .action-span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px; }
    .manageusers-wrapper .action-span img {
      height: 20px;
      width: 20px;
      margin-right: 0px; }
    .manageusers-wrapper .action-span .pop-item {
      color: #ffffff;
      margin: 0; }
      .manageusers-wrapper .action-span .pop-item:hover {
        color: #ad9972;
        cursor: pointer; }

.manage-users-action-popover .ant-popover-inner {
  background-color: #000000; }

.manage-users-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-left: 10px; }
  .action-image:hover {
    cursor: pointer; }

.action-span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8b8b8b;
  justify-content: flex-start;
  cursor: pointer; }
  .action-span .anticon {
    margin-right: 8px; }
  .action-span img {
    height: 20px;
    width: 20px;
    margin-right: 5px; }
  .action-span .pop-item {
    color: #ffffff;
    margin: 0; }
    .action-span .pop-item:hover {
      color: #ad9972; }

.manageartists-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .manageartists-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .manageartists-wrapper .filter-wrapper .filter-field {
      width: 200px;
      margin: 0 5px; }
      .manageartists-wrapper .filter-wrapper .filter-field .ant-select-selection {
        height: 45px !important; }
      .manageartists-wrapper .filter-wrapper .filter-field .ant-select-selection__rendered {
        line-height: 45px; }
    .manageartists-wrapper .filter-wrapper button {
      height: 45px;
      margin-bottom: 0px; }
      .manageartists-wrapper .filter-wrapper button .login-progress {
        position: absolute;
        left: 30%;
        color: #ffffff; }
  .manageartists-wrapper .colWrapper {
    display: flex;
    align-items: center; }
    .manageartists-wrapper .colWrapper .colImage {
      height: 27px;
      width: 27px;
      object-fit: fill; }
    .manageartists-wrapper .colWrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      padding-left: 20px;
      width: 12rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .manageartists-wrapper .colWrapper .icon-image {
      height: 27px;
      width: 27px;
      border: 1px solid #5a5a5a;
      font-size: 21px;
      padding: 2px;
      color: #1a1818; }
  .manageartists-wrapper .idtag {
    margin: 0; }
  .manageartists-wrapper .star-image {
    margin-left: 18px;
    height: 15px;
    width: 15px; }
  .manageartists-wrapper .artworkcountText {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    margin-right: 3rem;
    width: 100px; }
  .manageartists-wrapper .textBlack {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    min-width: 7rem; }
  .manageartists-wrapper .featureTextBlack {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center; }
  .manageartists-wrapper .textOrange {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #fe9600; }
  .manageartists-wrapper .textBlue {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #0b969b; }
  .manageartists-wrapper .textDarkBlue {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #0072d6; }
  .manageartists-wrapper .textGreen {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #499b0b; }
  .manageartists-wrapper .textBrown {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #d2502d; }
  .manageartists-wrapper .textRed {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #9b0b1e; }
  .manageartists-wrapper .empty-table {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: 100%;
    align-items: center;
    font-size: 15px;
    color: #000000; }
  .manageartists-wrapper .sorter-no-tooltip .ant-table-column-sorters:before {
    content: none !important; }
  .manageartists-wrapper .ant-select {
    margin-right: 10px; }
  .manageartists-wrapper .ant-select-selection:focus,
  .manageartists-wrapper .ant-select-selection:active,
  .manageartists-wrapper .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .manageartists-wrapper .ant-table {
    color: #000000;
    font-weight: 400;
    margin-bottom: 20px; }
  .manageartists-wrapper .ant-table-thead > tr > th {
    padding: 10px 10px 10px 2.2rem;
    background-color: #f5f5f5;
    color: #000000; }
    .manageartists-wrapper .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up {
      font-size: 14px !important; }
    .manageartists-wrapper .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
      font-size: 14px !important; }
  .manageartists-wrapper .ant-table-thead > tr:first-child > th:first-child {
    width: 12rem; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
    width: 6rem;
    padding: 10px 10px 10px 2.2rem; }
  .manageartists-wrapper .ant-table-tbody > tr {
    cursor: pointer;
    border-left: 4px solid #ffffff; }
    .manageartists-wrapper .ant-table-tbody > tr:hover {
      border-left: 4px solid #d2502d; }
  .manageartists-wrapper .ant-table-tbody > tr:hover {
    background: #e3d2b0; }
  .manageartists-wrapper .ant-table-tbody > tr > td {
    padding: 10px 10px 10px 2rem;
    border-bottom: none; }
  .manageartists-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #e3d2b0; }
  .manageartists-wrapper .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td {
    background: #e3d2b0; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-thead > tr > th {
    padding: 10px 10px 10px 2.2rem;
    background-color: #f5f5f5;
    color: #000000; }
    .manageartists-wrapper .ant-table-fixed-right .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up {
      font-size: 14px !important; }
    .manageartists-wrapper .ant-table-fixed-right .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
      font-size: 14px !important; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-tbody > tr {
    cursor: pointer;
    border-left: none; }
    .manageartists-wrapper .ant-table-fixed-right .ant-table-tbody > tr:hover {
      border-left: none;
      background: #e3d2b0; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-tbody > tr:hover {
    background: #e3d2b0; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-tbody > tr > td {
    padding: 10px 10px 10px 2rem;
    border-bottom: none; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #e3d2b0; }
  .manageartists-wrapper .ant-table-fixed-right .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td {
    background: #e3d2b0; }
  .manageartists-wrapper .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin-bottom: 20px; }
  .manageartists-wrapper .ant-pagination-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageartists-wrapper .ant-pagination-item {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-color: #eeeeee;
    border-radius: 0; }
  .manageartists-wrapper .ant-pagination-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageartists-wrapper .ant-pagination-jump-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageartists-wrapper .ant-pagination-jump-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .manageartists-wrapper .ant-pagination-item a {
    color: #000000; }
  .manageartists-wrapper .ant-pagination-item:focus a,
  .manageartists-wrapper .ant-pagination-item:hover a {
    color: #58514b; }
  .manageartists-wrapper .ant-pagination-item-active:focus a,
  .manageartists-wrapper .ant-pagination-item-active:hover a,
  .manageartists-wrapper .ant-pagination-item.ant-pagination-item-active a {
    border-color: #58514b;
    color: #ffffff; }
  .manageartists-wrapper .ant-pagination-item-active {
    background-color: #58514b; }
  .manageartists-wrapper .ant-pagination-item:focus,
  .manageartists-wrapper .ant-pagination-item:hover {
    border-color: #58514b; }
  .manageartists-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #58514b; }
  .manageartists-wrapper .anticon {
    color: #58514b; }
  .manageartists-wrapper .ant-pagination-prev .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .manageartists-wrapper .ant-pagination-next .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .manageartists-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
  .manageartists-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
  .manageartists-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
  .manageartists-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #58514b; }
  .manageartists-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .manageartists-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #58514b; }
  .manageartists-wrapper .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%; }

.manage-artists-action-popover .ant-popover-inner {
  background-color: #000000; }

.manage-artists-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-left: 10px; }
  .action-image:hover {
    cursor: pointer; }

.add-carrer-level-dialog .add-carrer-level-content {
  width: 25rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body .bg-white {
      background-color: #fff; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-body .form-control input {
        width: 100%; }
  .add-carrer-level-dialog .add-carrer-level-content .ant-calendar-picker {
    margin: 0rem 0rem 1rem; }
  .add-carrer-level-dialog .add-carrer-level-content .ant-input {
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    height: 45px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #323643; }
  .add-carrer-level-dialog .add-carrer-level-content .ant-select-selection:focus,
  .add-carrer-level-dialog .add-carrer-level-content .ant-select-selection:active,
  .add-carrer-level-dialog .add-carrer-level-content .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-featured-box span {
      padding-right: 10px; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #707070; }
  .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-action {
    display: flex;
    width: 100%;
    justify-content: center; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-action button {
      width: 49%; }
      .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-action button:first-of-type {
        margin-right: 2%; }
    .add-carrer-level-dialog .add-carrer-level-content .add-carrer-level-action .modal-button-loader {
      position: absolute;
      left: 70%; }

.ant-calendar-picker-container {
  z-index: 2000 !important; }


.managegallery-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .managegallery-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .managegallery-wrapper .filter-wrapper .filter-field {
      width: 200px;
      margin: 0 5px; }
      .managegallery-wrapper .filter-wrapper .filter-field .ant-select-selection {
        height: 45px !important; }
      .managegallery-wrapper .filter-wrapper .filter-field .ant-select-selection__rendered {
        line-height: 45px; }
    .managegallery-wrapper .filter-wrapper button {
      height: 45px;
      margin-bottom: 0px; }
      .managegallery-wrapper .filter-wrapper button .login-progress {
        position: absolute;
        left: 30%;
        color: #ffffff; }
  .managegallery-wrapper .colWrapper {
    display: flex;
    align-items: center; }
    .managegallery-wrapper .colWrapper .colImage {
      height: 27px;
      width: 27px;
      object-fit: fill; }
    .managegallery-wrapper .colWrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      padding-left: 20px;
      width: 12rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .managegallery-wrapper .colWrapper .icon-image {
      height: 27px;
      width: 27px;
      border: 1px solid #5a5a5a;
      font-size: 21px;
      padding: 2px;
      color: #1a1818; }
  .managegallery-wrapper .star-image {
    margin-left: 18px;
    height: 15px;
    width: 15px; }
  .managegallery-wrapper .artworkcountText {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    margin-right: 3rem; }
  .managegallery-wrapper .textBlack {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000; }
  .managegallery-wrapper .textOrange {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #fe9600; }
  .managegallery-wrapper .textBlue {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #0b969b; }
  .managegallery-wrapper .textDarkBlue {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #0072d6; }
  .managegallery-wrapper .textGreen {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #499b0b; }
  .managegallery-wrapper .textBrown {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #d2502d; }
  .managegallery-wrapper .textRed {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #9b0b1e; }
  .managegallery-wrapper .empty-table {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: 100%;
    align-items: center;
    font-size: 15px;
    color: #000000; }
  .managegallery-wrapper .sorter-no-tooltip .ant-table-column-sorters:before {
    content: none !important; }
  .managegallery-wrapper .ant-select {
    margin-right: 10px; }
  .managegallery-wrapper .ant-select-selection:focus,
  .managegallery-wrapper .ant-select-selection:active,
  .managegallery-wrapper .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .managegallery-wrapper .ant-table {
    color: #000000;
    font-weight: 400;
    margin-bottom: 20px; }
    @media screen and (max-width: 1395px) {
      .managegallery-wrapper .ant-table {
        overflow-x: scroll;
        overflow-y: scroll; } }
  .managegallery-wrapper .ant-table-thead > tr > th {
    padding: 10px 10px 10px 2.2rem;
    background-color: #f5f5f5;
    color: #000000; }
    .managegallery-wrapper .ant-table-thead > tr > th .ant-table-column-sorter
.ant-table-column-sorter-inner
.ant-table-column-sorter-up {
      font-size: 14px !important; }
    .managegallery-wrapper .ant-table-thead > tr > th .ant-table-column-sorter
.ant-table-column-sorter-inner
.ant-table-column-sorter-down {
      font-size: 14px !important; }
  .managegallery-wrapper .ant-table-tbody > tr {
    cursor: pointer;
    border-left: 4px solid #ffffff; }
    .managegallery-wrapper .ant-table-tbody > tr:hover {
      border-left: 4px solid #d2502d; }
  .managegallery-wrapper .ant-table-tbody > tr > td {
    padding: 10px 10px 10px 2rem;
    border-bottom: none; }
  .managegallery-wrapper .ant-table-tbody
> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
> td {
    background: #e3d2b0; }
  .managegallery-wrapper .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin-bottom: 20px; }
  .managegallery-wrapper .ant-pagination-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .managegallery-wrapper .ant-pagination-item {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-color: #eeeeee;
    border-radius: 0; }
  .managegallery-wrapper .ant-pagination-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .managegallery-wrapper .ant-pagination-jump-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .managegallery-wrapper .ant-pagination-jump-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .managegallery-wrapper .ant-pagination-item a {
    color: #000000; }
  .managegallery-wrapper .ant-pagination-item:focus a,
  .managegallery-wrapper .ant-pagination-item:hover a {
    color: #58514b; }
  .managegallery-wrapper .ant-pagination-item-active:focus a,
  .managegallery-wrapper .ant-pagination-item-active:hover a,
  .managegallery-wrapper .ant-pagination-item.ant-pagination-item-active a {
    border-color: #58514b;
    color: #ffffff; }
  .managegallery-wrapper .ant-pagination-item-active {
    background-color: #58514b; }
  .managegallery-wrapper .ant-pagination-item:focus,
  .managegallery-wrapper .ant-pagination-item:hover {
    border-color: #58514b; }
  .managegallery-wrapper .ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-ellipsis {
    color: #58514b; }
  .managegallery-wrapper .anticon {
    color: #58514b; }
  .managegallery-wrapper .ant-pagination-prev .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .managegallery-wrapper .ant-pagination-next .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .managegallery-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
  .managegallery-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
  .managegallery-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
  .managegallery-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #58514b; }
  .managegallery-wrapper .ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-link-icon,
  .managegallery-wrapper .ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-link-icon {
    color: #58514b; }
  .managegallery-wrapper .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%; }

.manage-gallery-action-popover .ant-popover-inner {
  background-color: #000000; }

.manage-gallery-action-popover.ant-popover-placement-bottom
> .ant-popover-content
> .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-left: 10px; }
  .action-image:hover {
    cursor: pointer; }

.add-slider-dialog .dialog-title {
  margin: 0 !important;
  background-color: #f5f5f5;
  border-bottom: 1px solid #eeeeee; }
  .add-slider-dialog .dialog-title h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center; }

.add-slider-dialog .add-slider-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-size: 15px; }
  .add-slider-dialog .add-slider-content .add-slider-body {
    overflow-y: scroll; }
    .add-slider-dialog .add-slider-content .add-slider-body::-webkit-scrollbar {
      -webkit-appearance: none; }
    .add-slider-dialog .add-slider-content .add-slider-body::-webkit-scrollbar:vertical {
      width: 1px; }
    .add-slider-dialog .add-slider-content .add-slider-body::-webkit-scrollbar:horizontal {
      height: 0px; }
    .add-slider-dialog .add-slider-content .add-slider-body::-webkit-scrollbar-thumb {
      background-color: #c4c9ca;
      border-radius: 0px;
      border: 2px solid transparent !important; }
    .add-slider-dialog .add-slider-content .add-slider-body::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
  .add-slider-dialog .add-slider-content .add-slider-body-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5rem 0;
    justify-content: center; }
    .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box {
      display: flex; }
      .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box {
        margin-bottom: 1rem; }
        .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box .upload-box {
          display: flex;
          background-color: #58514b;
          flex-direction: column;
          width: 14rem;
          height: 5rem;
          justify-content: center;
          align-items: center;
          border: 1px solid #cdcdcd;
          border-radius: 3px;
          font-size: 11px;
          color: #fff;
          font-weight: 500;
          cursor: pointer; }
          .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box .upload-box img.image-upload {
            margin-bottom: 4px;
            width: 23px;
            height: 23px; }
          .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box .upload-box img.media-thumb {
            width: 100%;
            max-height: 100%;
            object-fit: contain; }
          .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box .upload-box label {
            text-align: center;
            cursor: pointer; }
          .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box .upload-box.mobile-upload-box {
            width: 11rem; }
        .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-image-box .form-control-image-box .bg-white {
          background-color: #fff; }
    .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-description {
      margin-top: 1rem; }
      .add-slider-dialog .add-slider-content .add-slider-body-top .add-slider-description input {
        width: 100%; }
    .add-slider-dialog .add-slider-content .add-slider-body-top .form-control {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .add-slider-dialog .add-slider-content .add-slider-body-top .form-control input {
        height: 40px;
        width: 100%; }
    .add-slider-dialog .add-slider-content .add-slider-body-top input[type="date"] {
      background-image: url(/static/media/date.f5c5a9bc.svg);
      background-repeat: no-repeat;
      background-position: 91% 50%;
      background-size: 15px; }
  .add-slider-dialog .add-slider-content .add-slider-action {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2rem; }
    .add-slider-dialog .add-slider-content .add-slider-action button {
      width: 30%;
      height: 2rem; }
      .add-slider-dialog .add-slider-content .add-slider-action button:first-of-type {
        margin-right: 2%; }
    .add-slider-dialog .add-slider-content .add-slider-action .modal-button-loader {
      position: absolute;
      left: 53%;
      color: #ffffff !important; }
  .add-slider-dialog .add-slider-content .mr-custom {
    margin-right: 10px; }

.manage-home-wrapper {
  display: flex;
  height: calc(100% - 4rem); }
  .manage-home-wrapper .manage-home-list {
    width: 30%;
    min-width: 200px;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
    .manage-home-wrapper .manage-home-list hr {
      margin: 0 3%; }
  .manage-home-wrapper div.manage-home-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    font-size: 13px;
    padding-top: 3rem;
    font-weight: 500; }
    .manage-home-wrapper div.manage-home-list-wrapper .circular-loader {
      margin-bottom: 10px; }
  .manage-home-wrapper ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 90%;
    overflow-y: scroll; }
    .manage-home-wrapper ul::-webkit-scrollbar {
      -webkit-appearance: none; }
    .manage-home-wrapper ul::-webkit-scrollbar:vertical {
      width: 5px; }
    .manage-home-wrapper ul::-webkit-scrollbar:horizontal {
      height: 0px; }
    .manage-home-wrapper ul::-webkit-scrollbar-thumb {
      background-color: #fce9d1;
      border-radius: 5px;
      border: 2px solid transparent !important; }
    .manage-home-wrapper ul::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
    .manage-home-wrapper ul li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      border-left: 3px solid transparent;
      cursor: pointer; }
      .manage-home-wrapper ul li .list-item-count {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2 1;
        padding: 0 0 0 1rem;
        height: 100%; }
      .manage-home-wrapper ul li .list-item-content {
        display: flex;
        align-items: center;
        flex: 14 1;
        padding: 0 0 0 1rem;
        height: 100%; }
      .manage-home-wrapper ul li.active {
        background-color: #f8efe3;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
      .manage-home-wrapper ul li:hover {
        background-color: #f8efe3;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .manage-home-wrapper ul li:hover .list-item-action img.more-icon {
          display: none; }
        .manage-home-wrapper ul li:hover .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .manage-home-wrapper ul li:hover .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .manage-home-wrapper ul li:hover .list-item-action .edit-options .image-wrapper .edit-img {
              width: 18px !important; }
            .manage-home-wrapper ul li:hover .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .manage-home-wrapper ul li:hover .list-item-action .edit-options .image-wrapper:hover {
              background-color: rgba(254, 148, 0, 0.199); }
      .manage-home-wrapper ul li.head-list-item {
        background-color: #fff;
        border-left: none;
        cursor: default; }
  .manage-home-wrapper .empty-box {
    display: block;
    text-align: center;
    padding-top: 5%;
    font-size: 13px;
    font-weight: 500; }
  .manage-home-wrapper hr {
    border-top: 0;
    color: #eee !important; }
  .manage-home-wrapper .add-button-box {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #000000;
    font-weight: 500;
    border: 1px solid #565656;
    height: 35px;
    border-radius: 5px;
    box-shadow: 0.25px 0.25px 12px #00000029;
    cursor: pointer; }
  .manage-home-wrapper .manage-home-view-list {
    width: 70%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%;
    padding: 0 1rem; }
    .manage-home-wrapper .manage-home-view-list ul li {
      height: 5rem; }
      .manage-home-wrapper .manage-home-view-list ul li .banner-mob-box {
        display: flex;
        flex: 3 1;
        padding-left: 1rem;
        align-items: center; }
        .manage-home-wrapper .manage-home-view-list ul li .banner-mob-box .image-wrap {
          width: 100%;
          height: 4rem; }
        .manage-home-wrapper .manage-home-view-list ul li .banner-mob-box img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px; }
      .manage-home-wrapper .manage-home-view-list ul li .banner-web-box {
        display: flex;
        flex: 4 1;
        padding-left: 1rem;
        align-items: center; }
        .manage-home-wrapper .manage-home-view-list ul li .banner-web-box .image-wrap {
          width: 100%;
          height: 4rem; }
        .manage-home-wrapper .manage-home-view-list ul li .banner-web-box img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px; }
      .manage-home-wrapper .manage-home-view-list ul li .plain-image-placeholder {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 11px; }
      .manage-home-wrapper .manage-home-view-list ul li .product-box {
        display: flex;
        flex: 4 1;
        padding-left: 1rem; }
        .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box {
          width: 100%;
          height: 4rem;
          display: flex;
          align-items: center;
          padding: 7px;
          border: 1px solid #c9c7c7;
          background-color: #f5f5f5;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 400;
          color: #565656; }
          .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box .image-wrap {
            display: flex;
            width: 3rem;
            height: 100%;
            padding-right: 6px; }
          .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 4px; }
          .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box .product-data {
            width: calc(100% - 3rem);
            height: 100%; }
            .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box .product-data p {
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis; }
            .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box .product-data p.desc-text {
              height: calc(100% - 15px);
              overflow: hidden;
              text-overflow: ellipsis; }
            .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box .product-data p.price-text {
              height: 15px; }
          .manage-home-wrapper .manage-home-view-list ul li .product-box .product-details-box .w-100 {
            width: 100%; }
      .manage-home-wrapper .manage-home-view-list ul li .filter-box {
        display: flex;
        flex: 2 1;
        align-items: center;
        justify-content: center; }
      .manage-home-wrapper .manage-home-view-list ul li .actions-box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem 0 0rem;
        flex: 2 1;
        height: 100%; }
        .manage-home-wrapper .manage-home-view-list ul li .actions-box img.more-icon {
          display: block;
          width: 22px; }
        .manage-home-wrapper .manage-home-view-list ul li .actions-box .edit-options {
          display: none; }
      .manage-home-wrapper .manage-home-view-list ul li.active {
        background-color: #f8efe3;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .manage-home-wrapper .manage-home-view-list ul li.active .actions-box img.more-icon {
          display: none; }
        .manage-home-wrapper .manage-home-view-list ul li.active .actions-box .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .manage-home-wrapper .manage-home-view-list ul li.active .actions-box .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .manage-home-wrapper .manage-home-view-list ul li.active .actions-box .edit-options .image-wrapper img {
              width: 20px; }
            .manage-home-wrapper .manage-home-view-list ul li.active .actions-box .edit-options .image-wrapper:hover {
              background-color: rgba(254, 148, 0, 0.199); }
      .manage-home-wrapper .manage-home-view-list ul li:hover {
        background-color: #f8efe3;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .manage-home-wrapper .manage-home-view-list ul li:hover .actions-box img.more-icon {
          display: none; }
        .manage-home-wrapper .manage-home-view-list ul li:hover .actions-box .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .manage-home-wrapper .manage-home-view-list ul li:hover .actions-box .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .manage-home-wrapper .manage-home-view-list ul li:hover .actions-box .edit-options .image-wrapper img {
              width: 20px; }
            .manage-home-wrapper .manage-home-view-list ul li:hover .actions-box .edit-options .image-wrapper:hover {
              background-color: rgba(254, 148, 0, 0.199); }
    .manage-home-wrapper .manage-home-view-list hr {
      margin: 0; }
    .manage-home-wrapper .manage-home-view-list .manage-home-table-heads {
      font-size: 13px;
      font-weight: 500;
      cursor: default;
      pointer-events: none;
      height: 2.5rem !important; }
      .manage-home-wrapper .manage-home-view-list .manage-home-table-heads:hover {
        background-color: #fff;
        border-left: 3px solid #fff; }
    .manage-home-wrapper .manage-home-view-list .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.user-profile-main {
  display: flex;
  height: calc(100% - 4rem);
  overflow-y: scroll; }
  @media screen and (max-width: 1395px) {
    .user-profile-main {
      overflow-x: scroll;
      overflow-y: scroll; } }
  .user-profile-main-profile {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 320px !important;
    border-right: 1px solid #eee;
    padding: 20px; }
    .user-profile-main-profile .load-message {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 14px;
      font-weight: 500; }
    .user-profile-main-profile-view {
      display: flex; }
      .user-profile-main-profile-view .profile-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.25px solid #5a5a5a; }
        .user-profile-main-profile-view .profile-img img {
          object-fit: fill;
          width: 12rem;
          height: 12rem; }
    .user-profile-main-profile .name {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      padding-top: 3px;
      padding-bottom: 2px;
      margin-bottom: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .user-profile-main-profile .skillContainer {
      display: flex;
      margin-top: 5px;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #c2c2c2;
      flex-wrap: wrap; }
    .user-profile-main-profile .featured {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px; }
    .user-profile-main-profile .star {
      color: #d2502d; }
    .user-profile-main-profile .countContainer {
      border-top: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center; }
      .user-profile-main-profile .countContainer-count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 40px 5px 5px;
        font-size: 12px; }
      .user-profile-main-profile .countContainer-count-noBorder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 40px 5px 5px;
        font-size: 12px; }
    .user-profile-main-profile .contact {
      padding-top: 15px;
      padding-bottom: 15px; }
      .user-profile-main-profile .contact-rows {
        display: flex;
        align-items: center; }
      .user-profile-main-profile .contact .sub-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f5f5f5;
        padding-top: 10px; }
        .user-profile-main-profile .contact .sub-wrapper ul {
          list-style-type: none;
          padding-left: 0; }
          .user-profile-main-profile .contact .sub-wrapper ul li {
            color: #565656;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 0px; }
          .user-profile-main-profile .contact .sub-wrapper ul .greylist {
            color: #565656;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 0px; }
        .user-profile-main-profile .contact .sub-wrapper .detail-values {
          list-style-type: none; }
          .user-profile-main-profile .contact .sub-wrapper .detail-values li {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 10px; }
            .user-profile-main-profile .contact .sub-wrapper .detail-values li img {
              height: 90px;
              width: 90px;
              object-fit: cover;
              margin-left: 2.5rem; }
            .user-profile-main-profile .contact .sub-wrapper .detail-values li h2 {
              color: #000000;
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              padding-top: 10px;
              padding-bottom: 10px;
              padding-left: 2.5rem; }
            .user-profile-main-profile .contact .sub-wrapper .detail-values li .description {
              margin: 0;
              color: #000000;
              font-size: 13px;
              font-weight: 400;
              padding-left: 2.5rem; }
            .user-profile-main-profile .contact .sub-wrapper .detail-values li .artist-name {
              color: #1a1718;
              font-size: 14px;
              font-weight: 500; }
            .user-profile-main-profile .contact .sub-wrapper .detail-values li .addressform {
              display: flex;
              flex-direction: column; }
              .user-profile-main-profile .contact .sub-wrapper .detail-values li .addressform .nameText {
                padding-bottom: 10px;
                color: #000000;
                font-size: 16px;
                font-weight: 900;
                margin: 0; }
              .user-profile-main-profile .contact .sub-wrapper .detail-values li .addressform .addressData {
                color: #000000;
                font-size: 13px;
                font-weight: 400;
                margin: 0; }
    .user-profile-main-profile .view-more {
      display: flex;
      align-items: center;
      color: #d2502d;
      justify-content: center;
      cursor: pointer; }
  .user-profile-main .tabs-container {
    width: 100%;
    height: 100%; }
  .user-profile-main .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: -webkit-sticky;
    position: sticky; }
    .user-profile-main .tabs-switch {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer; }
      .user-profile-main .tabs-switch-dark {
        background: #58514b;
        color: #fff; }
      .user-profile-main .tabs-switch-white {
        background: #e1e1e1;
        color: #000; }

.history-container {
  height: 100%; }

.marginPhone {
  margin-left: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.marginEmail {
  margin-left: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.marginDate {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.chip {
  padding: 1px 4px 1px 4px;
  border: 1px solid #c2c2c2;
  margin: 3px; }

.text {
  color: #c2c2c2;
  font-size: 12px;
  margin-bottom: 0px; }

.colWrapper {
  display: flex; }

.colImage {
  height: 70px;
  width: 70px;
  object-fit: cover; }

.Pending {
  color: yellowgreen;
  font-weight: bold; }

.Order {
  color: #df500e;
  font-weight: bold; }

.Packed {
  color: #9c690a;
  font-weight: bold; }

.Collected {
  color: rebeccapurple;
  font-weight: bold; }

.Shipped {
  color: darkcyan;
  font-weight: bold; }

.Delivered {
  color: green;
  font-weight: bold; }

.Returned {
  color: blue;
  font-weight: bold; }

.Cancelled {
  color: red;
  font-weight: bold; }

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px !important; }

.main {
  height: 100%;
  padding-bottom: 10vh; }
  .main ::-webkit-scrollbar {
    display: none; }

.colWrapper {
  display: flex; }

.main {
  height: 100%;
  padding-bottom: 10vh;
  margin-bottom: 20px; }

.colImage {
  height: 70px;
  width: 70px;
  object-fit: cover; }

.countText {
  display: flex;
  align-items: center; }

.textBlue {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #0b969b; }

.textDarkBlue {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #0072d6; }

.textGreen {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #499b0b; }

.textBrown {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #d2502d; }

.textRed {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #9b0b1e; }

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px; }

.overflow-ls {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px; }

.collection-dialog-title {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  padding: 10px 26px !important;
  border-bottom: 1px solid #e5e5e5; }
  .collection-dialog-title.hideBorder {
    border-bottom: 0; }

.collection-dialog-subtitle {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0 !important; }

.add-collection-content {
  padding: 0 !important; }

.add-collection-dialog .add-collection-display-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem; }
  .add-collection-dialog .add-collection-display-body img {
    max-width: 12rem;
    max-height: 12rem; }
  .add-collection-dialog .add-collection-display-body p {
    margin-top: 1rem; }

.add-collection-dialog .add-collection-body {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem 15%; }
  .add-collection-dialog .add-collection-body .form-control-wrap {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10rem);
    margin-left: 1.5rem; }
  .add-collection-dialog .add-collection-body .upload-box {
    display: flex;
    flex-direction: column;
    width: 9rem;
    height: 9rem;
    justify-content: center;
    align-items: center;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
    cursor: pointer; }
    .add-collection-dialog .add-collection-body .upload-box img.image-upload {
      margin-bottom: -10px;
      width: 50px; }
    .add-collection-dialog .add-collection-body .upload-box img.media-thumb {
      max-width: 100%;
      max-height: 100%; }
  .add-collection-dialog .add-collection-body .bg-white {
    background-color: #fff; }
  .add-collection-dialog .add-collection-body .form-control {
    display: flex;
    flex-direction: column; }
    .add-collection-dialog .add-collection-body .form-control input {
      width: 100%; }
  .add-collection-dialog .add-collection-body .form-control-row-wrap {
    display: flex; }
    .add-collection-dialog .add-collection-body .form-control-row-wrap .form-control {
      flex: 1 1; }
      .add-collection-dialog .add-collection-body .form-control-row-wrap .form-control:first-of-type {
        margin-right: 1rem; }

.add-collection-dialog .add-collection-list-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e5e5e5; }
  .add-collection-dialog .add-collection-list-body .collection-dialog-subtitle {
    font-weight: 700; }
  .add-collection-dialog .add-collection-list-body .filter-artworks-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2%; }

.add-collection-dialog .add-collection-filter-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  padding: 2rem 1rem 70px; }
  .add-collection-dialog .add-collection-filter-body .search-input {
    border: 1px solid #e5e5e5;
    border-radius: 0px;
    height: 45px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #323643;
    outline: none;
    width: 50%;
    background: url(/static/media/Search.8e18218b.svg) no-repeat 96% 50%;
    background-size: 25px;
    padding-right: 6%; }
    .add-collection-dialog .add-collection-filter-body .search-input::-webkit-input-placeholder {
      color: #bbbbbb;
      font-size: 13px;
      font-weight: 400; }
    .add-collection-dialog .add-collection-filter-body .search-input::placeholder {
      color: #bbbbbb;
      font-size: 13px;
      font-weight: 400; }
    .add-collection-dialog .add-collection-filter-body .search-input:focus, .add-collection-dialog .add-collection-filter-body .search-input:active {
      border: 2px solid #58514b; }
  .add-collection-dialog .add-collection-filter-body .filter-wrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
  .add-collection-dialog .add-collection-filter-body .filter-artworks-searchLoader {
    display: flex;
    align-items: center; }
    .add-collection-dialog .add-collection-filter-body .filter-artworks-searchLoader .search-loader {
      width: 15px !important;
      height: 15px !important;
      color: #323643;
      margin-right: 5px; }
  .add-collection-dialog .add-collection-filter-body .filter-artworks-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .add-collection-dialog .add-collection-filter-body .filter-artworks-wrapper.filterWrapper-empty {
      justify-content: center; }
    .add-collection-dialog .add-collection-filter-body .filter-artworks-wrapper.filterWrapper-loader {
      opacity: 0.5; }
  .add-collection-dialog .add-collection-filter-body .sort-dropdown {
    margin: 0 3px 3px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #58514b; }
    .add-collection-dialog .add-collection-filter-body .sort-dropdown .ant-select-selection {
      background-color: #58514b;
      border: none !important; }
    .add-collection-dialog .add-collection-filter-body .sort-dropdown .ant-select-arrow {
      color: #bbbbbb; }
      .add-collection-dialog .add-collection-filter-body .sort-dropdown .ant-select-arrow .anticon {
        margin-left: 5px; }
    .add-collection-dialog .add-collection-filter-body .sort-dropdown .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
      color: #ffffff;
      margin-right: 5px; }
    .add-collection-dialog .add-collection-filter-body .sort-dropdown-label {
      font-size: 14px;
      color: #bbbbbb;
      padding: 0 0 0 14px;
      font-weight: 500; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    margin-bottom: 20px; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item {
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    border-color: #eeeeee;
    border-radius: 0; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-jump-next {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-jump-prev {
    min-width: 22px;
    height: 22px;
    line-height: 22px; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item a {
    color: #000000; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item:focus a,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item:hover a {
    color: #58514b; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item-active:focus a,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item-active:hover a,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item.ant-pagination-item-active a {
    border-color: #58514b;
    color: #ffffff; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item-active {
    background-color: #58514b; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item:focus,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-item:hover {
    border-color: #58514b; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #58514b; }
  .add-collection-dialog .add-collection-filter-body .anticon {
    color: #58514b; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-prev .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-next .ant-pagination-item-link {
    border-color: #eeeeee;
    border-radius: 0; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-prev:focus .ant-pagination-item-link,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-next:focus .ant-pagination-item-link,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-prev:hover .ant-pagination-item-link,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #58514b; }
  .add-collection-dialog .add-collection-filter-body .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .add-collection-dialog .add-collection-filter-body .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #58514b; }

.add-collection-footer-container {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
  height: 60px;
  border-top: 1px solid #e5e5e5; }
  .add-collection-footer-container p {
    margin: 0; }
  .add-collection-footer-container button {
    border: 0;
    margin-left: 1rem;
    padding: 5px 2rem;
    height: 35px; }

.sort-dropdown-select .ant-select-arrow {
  display: inline-block;
  top: 50%;
  left: unset; }

.sort-dropdown-select .ant-select-selection {
  min-height: 45px;
  border-radius: 0; }
  .sort-dropdown-select .ant-select-selection .ant-select-selection__rendered {
    height: 100%; }
    .sort-dropdown-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
      padding-top: 8px;
      font-weight: 500;
      color: #323643;
      font-size: 14px; }

.sort-dropdown-select .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
  height: 100%;
  line-height: 34px; }

.sort-dropdown-select .ant-select-selection--multiple {
  padding-bottom: 0; }

.sort-dropdown-select .ant-select-selection:hover {
  border-color: #e5e5e5; }

.sort-dropdown-select.ant-select-open .ant-select-selection,
.sort-dropdown-select.ant-select-focused .ant-select-selection {
  box-shadow: none;
  border-color: #e5e5e5; }
  .sort-dropdown-select.ant-select-open .ant-select-selection:focus-within,
  .sort-dropdown-select.ant-select-focused .ant-select-selection:focus-within {
    border: 2px solid #58514b;
    border-right-width: 2px !important; }

.sort-dropdown-select .ant-select-selection:focus,
.sort-dropdown-select .ant-select-selection:active {
  box-shadow: none;
  border: 1px solid #e5e5e5;
  border-right-width: 3px !important; }
  .sort-dropdown-select .ant-select-selection:focus:focus, .sort-dropdown-select .ant-select-selection:focus:active,
  .sort-dropdown-select .ant-select-selection:active:focus,
  .sort-dropdown-select .ant-select-selection:active:active {
    border: 2px solid #58514b; }

.sort-dropdown-select-options {
  z-index: 1300 !important; }

.artwork-card-image-wrapper {
  position: relative;
  display: flex;
  justify-content: center; }

.artwork-card-image {
  position: relative;
  height: 165px;
  max-width: 385px;
  cursor: pointer; }
  .artwork-card-image.isSelected {
    opacity: 0.5; }

.artwork-card-selection {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .artwork-card-selection .anticon {
    font-size: 37px;
    -webkit-animation: scale-up-center 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: scale-up-center 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.artwork-card-title {
  font-size: 12px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.artwork-card-actions {
  position: absolute;
  display: flex;
  height: 26px;
  align-items: center;
  background-color: #FFF;
  border-radius: 2rem;
  right: 8px;
  bottom: 8px;
  padding: 0 5px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.45); }
  .artwork-card-actions img {
    width: 33px;
    padding: 0 8.5px;
    border-right: 1px solid #bfbfbf;
    cursor: pointer; }
    .artwork-card-actions img:last-of-type {
      border: none; }

.artwork-card-footer {
  display: flex;
  justify-content: space-between; }

.artwork-card-artist {
  display: flex;
  align-items: center; }
  .artwork-card-artist img {
    width: 13px;
    height: 10px;
    margin-right: 1px;
    position: relative;
    left: -3px; }
  .artwork-card-artist p {
    font-size: 13px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px; }

.artwork-card-price {
  font-size: 13px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px; }

.artwork-card-wrapper {
  max-width: 385px;
  cursor: default;
  margin: 10px; }

.collectons-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 4rem);
  padding: 2rem 3rem 2rem 3.5rem; }
  .collectons-wrapper .collection-card {
    display: flex;
    flex-direction: column;
    margin-right: 2.5rem;
    margin-bottom: 3rem;
    height: 345px; }
    .collectons-wrapper .collection-card .title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
    .collectons-wrapper .collection-card .star-image {
      margin-left: 18px;
      height: 15px;
      width: 15px; }
    .collectons-wrapper .collection-card img {
      height: 300px;
      width: 350px;
      cursor: pointer;
      object-fit: fill; }
    .collectons-wrapper .collection-card .card-title {
      display: flex;
      padding-top: 15px;
      justify-content: space-between; }
      .collectons-wrapper .collection-card .card-title p {
        margin: 0;
        color: #58514b;
        font-size: 16px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .collectons-wrapper .collection-card .card-title .action-pill {
        display: flex;
        background-color: #ffffff;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 30px;
        border-radius: 15px;
        box-shadow: 0px 2px 4px 1px #d9d9d9; }
        .collectons-wrapper .collection-card .card-title .action-pill img {
          height: 20px;
          width: 20px; }
        .collectons-wrapper .collection-card .card-title .action-pill span {
          font-size: 18px;
          margin-left: 5px;
          margin-right: 5px; }
  .collectons-wrapper .add-button-box {
    display: flex;
    position: absolute;
    align-items: center;
    height: 45px;
    right: 2rem;
    bottom: 1rem; }
    .collectons-wrapper .add-button-box .add-btn {
      background-color: #ffffff;
      border: 1px solid #58514b;
      border-radius: 3px;
      height: 35px;
      border: 1px solid #58514b;
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 0.25px 0.25px 12px #00000029;
      cursor: pointer;
      color: #58514b;
      font-size: 12px;
      font-weight: 500; }
      .collectons-wrapper .add-button-box .add-btn p {
        font-size: 20px;
        color: #58514b;
        margin: 0;
        padding-right: 5px;
        font-weight: 500; }
  .collectons-wrapper .empty-collectons {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: 100%;
    align-items: center;
    font-size: 15px;
    color: #000000; }
  .collectons-wrapper .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%; }

.collection-wrapper-disabled {
  pointer-events: none; }
  .collection-wrapper-disabled .collection-card {
    opacity: 0.5; }
    .collection-wrapper-disabled .collection-card img {
      opacity: 0.4; }

.orders-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .orders-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .orders-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
    .orders-wrapper .filter-wrapper .ant-select {
      margin-right: 10px; }
    .orders-wrapper .filter-wrapper .ant-select-selection:focus,
    .orders-wrapper .filter-wrapper .ant-select-selection:active,
    .orders-wrapper .filter-wrapper .ant-select-selection:hover {
      border-color: #000000;
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .orders-wrapper .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 1395px) {
      .orders-wrapper .sub-wrapper {
        overflow-x: scroll;
        overflow-y: scroll; } }
    .orders-wrapper .sub-wrapper .colWrapper {
      display: flex;
      position: relative;
      cursor: pointer; }
    .orders-wrapper .sub-wrapper .labelImage {
      position: absolute;
      top: 0;
      left: -0.5px; }
    .orders-wrapper .sub-wrapper .colImage {
      height: 60px;
      width: 60px;
      object-fit: fill; }
    .orders-wrapper .sub-wrapper .colDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px; }
    .orders-wrapper .sub-wrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      width: 12rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .orders-wrapper .sub-wrapper .colGreyText {
      color: #727272;
      margin: 0;
      font-size: 12px;
      width: 12rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .orders-wrapper .sub-wrapper .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000; }
    .orders-wrapper .sub-wrapper .textCenterBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-align: center; }
    .orders-wrapper .sub-wrapper .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fe9600; }
    .orders-wrapper .sub-wrapper .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0b969b; }
    .orders-wrapper .sub-wrapper .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0072d6; }
    .orders-wrapper .sub-wrapper .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #499b0b; }
    .orders-wrapper .sub-wrapper .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #d2502d; }
    .orders-wrapper .sub-wrapper .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #9b0b1e; }
    .orders-wrapper .sub-wrapper .dropDown {
      display: flex;
      align-items: center; }
    .orders-wrapper .sub-wrapper .artistDiv {
      display: flex;
      align-items: center; }
      .orders-wrapper .sub-wrapper .artistDiv img {
        height: 11px;
        width: 11px;
        margin-right: 8px;
        margin-bottom: 2px; }
    .orders-wrapper .sub-wrapper .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000; }
    .orders-wrapper .sub-wrapper .ant-table {
      color: #000000;
      font-weight: 500;
      margin-bottom: 40px; }
    .orders-wrapper .sub-wrapper .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2rem;
      background-color: #f5f5f5;
      color: #000000; }
    .orders-wrapper .sub-wrapper .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem; }
    .orders-wrapper .sub-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e3d2b0;
      box-shadow: 0px 5px 0px 0px #e3d2b0; }
    .orders-wrapper .sub-wrapper .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      margin-bottom: 20px; }
    .orders-wrapper .sub-wrapper .ant-pagination-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .orders-wrapper .sub-wrapper .ant-pagination-item {
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      border-color: #eeeeee;
      border-radius: 0; }
    .orders-wrapper .sub-wrapper .ant-pagination-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .orders-wrapper .sub-wrapper .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .orders-wrapper .sub-wrapper .ant-pagination-jump-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .orders-wrapper .sub-wrapper .ant-pagination-item a {
      color: #000000; }
    .orders-wrapper .sub-wrapper .ant-pagination-item:focus a,
    .orders-wrapper .sub-wrapper .ant-pagination-item:hover a {
      color: #58514b; }
    .orders-wrapper .sub-wrapper .ant-pagination-item-active:focus a,
    .orders-wrapper .sub-wrapper .ant-pagination-item-active:hover a,
    .orders-wrapper .sub-wrapper .ant-pagination-item.ant-pagination-item-active a {
      border-color: #58514b;
      color: #ffffff; }
    .orders-wrapper .sub-wrapper .ant-pagination-item-active {
      background-color: #58514b; }
    .orders-wrapper .sub-wrapper .ant-pagination-item:focus,
    .orders-wrapper .sub-wrapper .ant-pagination-item:hover {
      border-color: #58514b; }
    .orders-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #58514b; }
    .orders-wrapper .sub-wrapper .anticon {
      color: #58514b; }
    .orders-wrapper .sub-wrapper .ant-pagination-prev .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .orders-wrapper .sub-wrapper .ant-pagination-next .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .orders-wrapper .sub-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
    .orders-wrapper .sub-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
    .orders-wrapper .sub-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
    .orders-wrapper .sub-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #58514b; }
    .orders-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .orders-wrapper .sub-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #58514b; }
    .orders-wrapper .sub-wrapper .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.orders-action-popover .ant-popover-inner {
  background-color: #000000; }

.orders-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain; }
  .action-image:hover {
    cursor: pointer; }

.order-status-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .order-status-wrapper .head-wrapper {
    display: flex; }
    .order-status-wrapper .head-wrapper .detail-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      padding-left: 2rem;
      color: #000000;
      font-weight: 500;
      font-size: 16px;
      background-color: #f5f5f5;
      width: 35%; }
    .order-status-wrapper .head-wrapper .form-grid {
      display: flex;
      justify-content: space-between;
      width: 65%; }
      .order-status-wrapper .head-wrapper .form-grid .detail-wrapper {
        display: flex;
        height: 40px;
        width: 40%;
        align-items: center;
        padding-left: 20px;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        background-color: #f5f5f5; }
      .order-status-wrapper .head-wrapper .form-grid .message-wrapper {
        display: flex;
        height: 40px;
        width: 60%;
        align-items: center;
        padding-left: 20px;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        background-color: #cfcfcf; }
  .order-status-wrapper .content-wrapper {
    display: flex;
    justify-content: space-between; }
    .order-status-wrapper .content-wrapper .colum-grid {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-right: 1px solid #f5f5f5;
      width: 35%;
      padding: 0 2rem 2rem 2rem; }
      .order-status-wrapper .content-wrapper .colum-grid .list-wrapper {
        display: flex;
        flex-direction: column; }
        .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper {
          display: flex;
          border-bottom: 1px solid #f5f5f5;
          padding-top: 1rem; }
          .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper ul {
            list-style-type: none;
            padding-left: 0; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper ul li {
              color: #565656;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper ul .greylist {
              color: #565656;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px; }
          .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values {
            list-style-type: none;
            padding-left: 15px; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li {
              color: #000000;
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 10px; }
              .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li img {
                height: 90px;
                width: 90px;
                object-fit: cover;
                margin-left: 2.5rem; }
              .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li h2 {
                color: #000000;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 2.5rem; }
              .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li .description {
                margin: 0;
                color: #000000;
                font-size: 13px;
                font-weight: 400;
                padding-left: 2.5rem; }
              .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li .artist-name {
                color: #1a1718;
                font-size: 14px;
                font-weight: 500; }
              .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li .addressform {
                display: flex;
                flex-direction: column; }
                .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li .addressform .nameText {
                  padding-bottom: 10px;
                  color: #000000;
                  font-size: 16px;
                  font-weight: 900;
                  margin: 0; }
                .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .sub-wrapper .detail-values li .addressform .addressData {
                  color: #000000;
                  font-size: 13px;
                  font-weight: 400;
                  margin: 0; }
        .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 2rem; }
          .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper .first-imagebutton {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            height: 35px;
            width: 150px;
            cursor: pointer;
            margin-bottom: 10px; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper .first-imagebutton .first-iconimage {
              margin-right: 10px;
              height: 18px;
              width: 18px; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper .first-imagebutton .title {
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              margin: 0; }
          .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper .second-imagebutton {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            height: 35px;
            width: 150px;
            cursor: pointer; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper .second-imagebutton .second-iconimage {
              margin-right: 10px;
              height: 25px;
              width: 25px; }
            .order-status-wrapper .content-wrapper .colum-grid .list-wrapper .end-wrapper .second-imagebutton .title {
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              margin: 0; }
    .order-status-wrapper .content-wrapper .colum-grid-new {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 65%; }
      .order-status-wrapper .content-wrapper .colum-grid-new .track-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40%; }
        .order-status-wrapper .content-wrapper .colum-grid-new .track-wrapper .tracking {
          display: flex;
          width: 40%; }
        .order-status-wrapper .content-wrapper .colum-grid-new .track-wrapper .track-details {
          display: flex;
          flex-direction: column;
          width: 60%;
          padding-left: 20px; }
          .order-status-wrapper .content-wrapper .colum-grid-new .track-wrapper .track-details .status-head {
            color: #d2502d;
            font-size: 14px;
            font-weight: 500;
            padding-top: 30px; }
          .order-status-wrapper .content-wrapper .colum-grid-new .track-wrapper .track-details .status-date {
            color: #000000;
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-weight: 500; }
          .order-status-wrapper .content-wrapper .colum-grid-new .track-wrapper .track-details .status-data {
            color: #585858;
            font-size: 12px; }
      .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f5f5f5;
        padding-left: 1rem;
        margin-bottom: 10px; }
        .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper .sub-wrapper {
          display: flex; }
          .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper .sub-wrapper ul {
            list-style-type: none;
            padding-left: 0; }
            .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper .sub-wrapper ul li {
              color: #565656;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px; }
            .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper .sub-wrapper ul .greylist {
              color: #565656;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px; }
          .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper .sub-wrapper .detail-values {
            list-style-type: none;
            padding-left: 15px; }
            .order-status-wrapper .content-wrapper .colum-grid-new .pickup-wrapper .sub-wrapper .detail-values li {
              color: #000000;
              font-size: 14px;
              font-weight: 600;
              padding-bottom: 10px; }
      .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper {
        display: flex;
        background-color: #f5f5f5;
        justify-content: space-around;
        align-items: center;
        height: 150px; }
        .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .form-status {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          height: 100%; }
          .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .form-status .selection-title {
            color: #707070;
            font-size: 14px;
            padding-bottom: 10px;
            margin: 0; }
        .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .note-status {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 50%; }
          .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .note-status .selection-title {
            color: #707070;
            font-size: 14px;
            padding-bottom: 10px;
            margin: 0; }
          .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .note-status .noteText {
            width: 100%;
            border: 1px solid #cdcdcd;
            border-radius: 3px;
            height: 33px;
            margin-bottom: 10px;
            font-size: 14px;
            outline: none; }
            .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .note-status .noteText:focus {
              border-color: #000000;
              outline: none; }
        .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .track-btn {
          height: 33px;
          background-color: #58514b;
          border: 0.5px solid #707070;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          width: 100px;
          outline: none;
          cursor: pointer;
          margin-top: 20px; }
          .order-status-wrapper .content-wrapper .colum-grid-new .status-wrapper .track-btn:hover {
            color: #f2d6a0;
            outline: none; }
  .order-status-wrapper .ant-steps {
    width: 100%;
    padding: 20px; }
  .order-status-wrapper .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail::after {
    width: 1.5px;
    height: 131%; }
  .order-status-wrapper .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
    top: 1px;
    left: -9px;
    margin: 0;
    padding: 14px 0 4px; }
  .order-status-wrapper .ant-steps-item-finish > .ant-steps-item-tail::after {
    background-color: #ad9972; }
  .order-status-wrapper .ant-steps-dot .ant-steps-item-content {
    width: 85%; }
  .order-status-wrapper .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #d2502d; }
  .order-status-wrapper .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #ad9972; }
  .order-status-wrapper .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #ad9972; }
  .order-status-wrapper .ant-steps-item-title {
    font-size: 14px; }
  .order-status-wrapper .ant-steps-item-description {
    font-size: 12px; }
  .order-status-wrapper .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: #d2502d; }
  .order-status-wrapper .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
    color: #000000;
    font-weight: 500; }
  .order-status-wrapper .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    font-weight: 500; }
  .order-status-wrapper .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-title {
    color: #af935a; }
  .order-status-wrapper .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"]:hover .ant-steps-item-description {
    color: #af935a; }
  .order-status-wrapper .ant-select {
    margin-bottom: 10px;
    width: 100%; }
  .order-status-wrapper .ant-select-selection {
    background-color: #dec390; }
  .order-status-wrapper .ant-select-selection:focus,
  .order-status-wrapper .ant-select-selection:active,
  .order-status-wrapper .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .order-status-wrapper .ant-select-selection__placeholder {
    color: #58514b; }
  .order-status-wrapper .empty-table {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: 100%;
    align-items: center;
    font-size: 15px;
    color: #000000; }
  .order-status-wrapper .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%; }

.report-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 30px; }
  .report-wrapper .download-button {
    color: #fff;
    border: none;
    background-color: #FE9600;
    border-radius: 3px;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500; }

.scroll-horizontal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll; }
  .scroll-horizontal .cascade-wrapper {
    border: 1px solid #CDCDCD;
    background-color: #FFF;
    border-radius: 3px;
    padding: 3px 10px 3px 3px;
    height: 40px;
    margin-bottom: 1rem;
    margin-right: 1rem; }
    .scroll-horizontal .cascade-wrapper.disabled {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 12rem;
      padding-left: 10px; }
      .scroll-horizontal .cascade-wrapper.disabled p {
        font-size: 13px;
        display: inline-block;
        margin: 0; }
    .scroll-horizontal .cascade-wrapper img {
      width: 20px; }
    .scroll-horizontal .cascade-wrapper:focus, .scroll-horizontal .cascade-wrapper:active, .scroll-horizontal .cascade-wrapper:focus-within {
      border: 2px solid #FE9600; }
  .scroll-horizontal .onlySelect {
    margin-right: 0 !important;
    width: 100%; }
    .scroll-horizontal .onlySelect .ant-cascader-picker.scroll-cascader {
      width: 100% !important; }
  .scroll-horizontal .add-button-box {
    position: relative;
    width: 4rem;
    height: 4rem;
    top: -8px; }
    .scroll-horizontal .add-button-box img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer; }

.ant-cascader-picker.scroll-cascader {
  height: 100%; }
  .ant-cascader-picker.scroll-cascader .ant-cascader-input.ant-input {
    position: static;
    width: 100%;
    background-color: transparent !important;
    cursor: pointer;
    height: 100%;
    border: none; }

.scroll-cascader .ant-cascader-picker-label:hover + .ant-cascader-input,
.scroll-cascader.ant-cascader-picker:focus .ant-cascader-input,
.ant-input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  border-right-width: 1px !important; }

.ant-cascader-menus.scroll-cascader-menu {
  z-index: 1400 !important;
  margin-top: 3.5rem !important;
  width: 78% !important;
  overflow-x: scroll !important;
  left: 7% !important;
  border: 1px solid #E0E0E0; }
  .ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu {
    min-width: 8rem !important; }
  .ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu-item:hover {
    background: #F8EFE3; }
  .ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar {
    -webkit-appearance: none; }
  .ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:vertical {
    width: 0px; }
  .ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:horizontal {
    height: 5px; }
  .ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-thumb {
    background-color: #F8EFE3;
    border-radius: 0px;
    border: 2px solid transparent !important; }
  .ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-track {
    border-radius: 50%;
    background-color: transparent !important; }

.ant-cascader-menus.scroll-cascader-menu.onlySelect {
  width: auto !important; }

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #F5F5F5 !important;
  color: #fe9600; }

.add-brands-dialog .dialog-title {
  margin: 0 !important;
  background-color: #F5F5F5;
  border-bottom: 1px solid #EEEEEE; }
  .add-brands-dialog .dialog-title h2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center; }

.add-brands-dialog .add-brands-checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.add-brands-dialog .ant-checkbox-inner {
  border-color: #d9d9d9; }

.add-brands-dialog .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FE9600;
  border-color: #FE9600; }

.add-brands-dialog .ant-checkbox-wrapper:hover .ant-checkbox-inner, .add-brands-dialog .ant-checkbox:hover .ant-checkbox-inner, .add-brands-dialog .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #FE9600; }

.add-brands-dialog input[type=number]::-webkit-inner-spin-button,
.add-brands-dialog input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.add-brands-dialog .add-brands-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-size: 15px; }
  .add-brands-dialog .add-brands-content .add-brands-body {
    height: 100%;
    overflow-y: scroll; }
    .add-brands-dialog .add-brands-content .add-brands-body::-webkit-scrollbar {
      -webkit-appearance: none; }
    .add-brands-dialog .add-brands-content .add-brands-body::-webkit-scrollbar:vertical {
      width: 1px; }
    .add-brands-dialog .add-brands-content .add-brands-body::-webkit-scrollbar:horizontal {
      height: 0px; }
    .add-brands-dialog .add-brands-content .add-brands-body::-webkit-scrollbar-thumb {
      background-color: #C4C9CA;
      border-radius: 0px;
      border: 2px solid transparent !important; }
    .add-brands-dialog .add-brands-content .add-brands-body::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
  .add-brands-dialog .add-brands-content .add-brands-body-top {
    display: flex;
    width: 100%;
    margin: 0.5rem 0;
    justify-content: center;
    height: 7rem; }
    .add-brands-dialog .add-brands-content .add-brands-body-top .form-control-image-box {
      margin-right: 2rem; }
      .add-brands-dialog .add-brands-content .add-brands-body-top .form-control-image-box .upload-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 7rem;
        background-color: #FE9600;
        border: 1px solid #CDCDCD;
        border-radius: 3px;
        font-size: 11px;
        color: #FFF;
        font-weight: 500;
        cursor: pointer; }
        .add-brands-dialog .add-brands-content .add-brands-body-top .form-control-image-box .upload-box img.image-upload {
          margin-bottom: 4px;
          width: 23px;
          height: 23px; }
        .add-brands-dialog .add-brands-content .add-brands-body-top .form-control-image-box .upload-box img.media-thumb {
          max-width: 100%;
          max-height: 100%; }
        .add-brands-dialog .add-brands-content .add-brands-body-top .form-control-image-box .upload-box label {
          text-align: center; }
      .add-brands-dialog .add-brands-content .add-brands-body-top .form-control-image-box .bg-white {
        background-color: #FFF; }
    .add-brands-dialog .add-brands-content .add-brands-body-top .add-brands-checkbox {
      flex-direction: column;
      padding: 0.6rem 1rem 0; }
      .add-brands-dialog .add-brands-content .add-brands-body-top .add-brands-checkbox label {
        margin-top: 0.5rem; }
    .add-brands-dialog .add-brands-content .add-brands-body-top .form-control {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 15rem; }
      .add-brands-dialog .add-brands-content .add-brands-body-top .form-control input {
        height: 40px;
        width: 100%; }
  .add-brands-dialog .add-brands-content .add-brands-body-bottom {
    min-height: calc(100% - 8rem);
    background-color: #F5F5F5;
    padding: 1rem 7%; }
    .add-brands-dialog .add-brands-content .add-brands-body-bottom .sub-title {
      color: #000000;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 1rem; }
    .add-brands-dialog .add-brands-content .add-brands-body-bottom .psuedo-inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; }
      .add-brands-dialog .add-brands-content .add-brands-body-bottom .psuedo-inputs .psuedo-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #CDCDCD;
        background-color: #fbfbfb;
        border-radius: 3px;
        height: 31px;
        margin-bottom: 1rem;
        margin-right: 1rem;
        padding: 0px 10px;
        min-width: 9rem;
        cursor: default; }
        .add-brands-dialog .add-brands-content .add-brands-body-bottom .psuedo-inputs .psuedo-input p {
          margin: 0;
          font-size: 14px;
          text-transform: capitalize; }
        .add-brands-dialog .add-brands-content .add-brands-body-bottom .psuedo-inputs .psuedo-input img {
          cursor: pointer;
          width: 18px; }

.add-brands-dialog .add-brands-action {
  display: flex;
  width: 100%;
  justify-content: center;
  width: 16rem; }
  .add-brands-dialog .add-brands-action button {
    width: 49%;
    height: 2rem; }
    .add-brands-dialog .add-brands-action button:first-of-type {
      margin-right: 2%; }
  .add-brands-dialog .add-brands-action .modal-button-loader {
    position: absolute;
    left: 70%; }

.add-brands-dialog .modal-button-loader {
  position: absolute;
  left: 70%; }

.ant-tooltip {
  z-index: 1500 !important; }

.add-brands-wrapper {
  height: calc(100% - 4rem); }
  .add-brands-wrapper .add-brands-table {
    height: 100%; }
    .add-brands-wrapper .add-brands-table-list {
      padding: 0;
      margin: 0;
      list-style: none; }
      .add-brands-wrapper .add-brands-table-list-header, .add-brands-wrapper .add-brands-table-list-body {
        display: flex;
        align-items: center; }
        .add-brands-wrapper .add-brands-table-list-header-item, .add-brands-wrapper .add-brands-table-list-body-item {
          font-size: 13px; }
      .add-brands-wrapper .add-brands-table-list-header {
        height: 3rem;
        background-color: #F5F5F5;
        padding: 0rem 1rem 0 2rem; }
        .add-brands-wrapper .add-brands-table-list-header-item {
          font-weight: 700; }
      .add-brands-wrapper .add-brands-table-list.bodyTable li {
        padding: 0rem 1rem;
        border-left: 3px solid #FFF; }
        .add-brands-wrapper .add-brands-table-list.bodyTable li:hover {
          background-color: #F8EFE3;
          transition: all 0.3s, height 0s;
          border-left: 3px solid #FE9600;
          cursor: pointer; }
        .add-brands-wrapper .add-brands-table-list.bodyTable li:last-child {
          margin-bottom: 3rem; }
      .add-brands-wrapper .add-brands-table-list-body {
        min-height: 4.5rem;
        padding-left: 1rem;
        border-bottom: 1px solid #F5F5F5; }
        .add-brands-wrapper .add-brands-table-list-body-item {
          font-weight: 500; }
          .add-brands-wrapper .add-brands-table-list-body-item .text-pending {
            color: #009900; }
      .add-brands-wrapper .add-brands-table-list .cell-one {
        display: flex;
        align-items: center;
        width: 50%; }
        .add-brands-wrapper .add-brands-table-list .cell-one .image-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          height: 4rem;
          margin-right: 7%; }
          .add-brands-wrapper .add-brands-table-list .cell-one .image-box img {
            max-width: 100%;
            max-height: 100%; }
      .add-brands-wrapper .add-brands-table-list .cell-four,
      .add-brands-wrapper .add-brands-table-list .cell-two {
        display: flex;
        align-items: center;
        width: 20%; }
      .add-brands-wrapper .add-brands-table-list .cell-three {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%; }
        .add-brands-wrapper .add-brands-table-list .cell-three img {
          height: 18px;
          cursor: pointer; }
          .add-brands-wrapper .add-brands-table-list .cell-three img.edit-icon {
            height: 15px;
            margin-right: 10px; }
  .add-brands-wrapper .bodyTable {
    height: calc(100% - 3rem);
    overflow: scroll; }
  .add-brands-wrapper .add-button-box {
    position: fixed;
    right: 1.5%;
    bottom: 2%;
    width: 4rem;
    height: 4rem; }
    .add-brands-wrapper .add-button-box img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer; }

.message-container-wrapper {
  height: 100%;
  width: 100%; }
  .message-container-wrapper #notfound {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center; }
    .message-container-wrapper #notfound .logo-box {
      padding-top: 4rem; }
      .message-container-wrapper #notfound .logo-box .logo-icon {
        margin-right: 10px; }
  .message-container-wrapper .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center; }
  .message-container-wrapper #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .message-container-wrapper .notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0 auto 20px;
    z-index: -1; }
  .message-container-wrapper .notfound .notfound-404 h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 236px;
    font-weight: 200;
    margin: 0;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .message-container-wrapper .notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    color: #211b19;
    background: #fff;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .message-container-wrapper .notfound a {
    font-family: montserrat,sans-serif;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: #FE9600;
    font-size: 18px;
    transition: .2s all;
    box-shadow: 3px 4px 10px 2px #EEE;
    cursor: pointer; }
  @media (max-width: 768px) {
    .message-container-wrapper .notfound .notfound-404 h1 {
      font-size: 14vh; }
    .message-container-wrapper .notfound .notfound-404 h2 {
      font-size: 2vh;
      top: 19vh;
      bottom: auto;
      width: 36vh; } }

.change-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  height: 100%; }
  .change-wrapper .app-logo {
    display: flex;
    justify-content: center;
    padding-bottom: 30px; }
  .change-wrapper .change-contents {
    display: flex;
    flex-direction: column;
    width: 393px;
    height: 423px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    padding: 50px 30px 30px 30px; }
    @media screen and (max-width: 430px) {
      .change-wrapper .change-contents {
        width: 80%; } }
    .change-wrapper .change-contents .page-title {
      font-size: 16px;
      color: #434343;
      margin: 0;
      text-align: center;
      font-weight: 700;
      padding-bottom: 2rem; }
    .change-wrapper .change-contents .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .change-wrapper .change-contents .form-control input {
        width: 100%;
        margin-bottom: 25px; }
    .change-wrapper .change-contents .end-btn {
      display: flex;
      flex-direction: column; }
      .change-wrapper .change-contents .end-btn button {
        height: 45px;
        width: 100%;
        position: relative; }
  .change-wrapper .otp-progress {
    position: absolute;
    top: 20%;
    left: 46%;
    color: #FE9600; }

.otp-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  height: 100%; }
  .otp-wrapper .app-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px; }
  .otp-wrapper .otp-contents {
    display: flex;
    flex-direction: column;
    width: 393px;
    height: 340px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    padding: 30px 30px 30px 30px; }
    @media screen and (max-width: 430px) {
      .otp-wrapper .otp-contents {
        width: 80%; } }
    .otp-wrapper .otp-contents .page-title {
      font-size: 16px;
      color: #434343;
      margin: 0;
      text-align: center;
      font-weight: 700;
      padding-bottom: 1rem; }
    .otp-wrapper .otp-contents .message {
      margin: 0;
      font-size: 14px;
      color: #434343;
      font-weight: 500;
      text-align: center;
      padding-bottom: 20px; }
    .otp-wrapper .otp-contents .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .otp-wrapper .otp-contents .form-control input {
        width: 100%;
        margin-bottom: 25px; }
      .otp-wrapper .otp-contents .form-control input[type=number]::-webkit-inner-spin-button,
      .otp-wrapper .otp-contents .form-control input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .otp-wrapper .otp-contents .end-btn {
      display: flex;
      flex-direction: column; }
      .otp-wrapper .otp-contents .end-btn button {
        height: 45px;
        width: 100%;
        position: relative; }
      .otp-wrapper .otp-contents .end-btn P {
        font-size: 14px;
        color: #FE9600;
        text-align: center;
        padding-top: 10px;
        font-weight: 500;
        cursor: pointer; }
  .otp-wrapper .otp-progress {
    position: absolute;
    top: 20%;
    left: 47%;
    color: #FE9600; }

.forgot-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  height: 100%; }
  .forgot-wrapper .app-logo {
    display: flex;
    justify-content: center;
    padding-bottom: 30px; }
  .forgot-wrapper .forgot-contents {
    display: flex;
    flex-direction: column;
    width: 24rem;
    height: 17rem;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    padding: 2rem 2.5rem 2rem; }
    @media screen and (max-width: 430px) {
      .forgot-wrapper .forgot-contents {
        width: 80%; } }
    .forgot-wrapper .forgot-contents .page-title {
      font-size: 16px;
      color: #434343;
      margin: 0;
      text-align: center;
      font-weight: 700;
      padding-bottom: 2rem; }
    .forgot-wrapper .forgot-contents .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .forgot-wrapper .forgot-contents .form-control input {
        width: 100%;
        margin-bottom: 25px; }
    .forgot-wrapper .forgot-contents .end-btn {
      display: flex; }
      .forgot-wrapper .forgot-contents .end-btn button {
        height: 45px;
        width: 100%;
        position: relative; }
  .forgot-wrapper .otp-progress {
    position: absolute;
    top: 20%;
    left: 46%;
    color: #FE9600; }

.notification-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .notification-wrapper .notification-card {
    display: flex;
    width: 100%;
    padding: 15px 0 15px 2rem;
    align-items: center;
    border-bottom: 1px solid #eeeeee; }
    .notification-wrapper .notification-card .imagebox {
      display: flex;
      border: 0.5px solid #acacac;
      height: 50px;
      width: 4%;
      border-radius: 25px;
      align-items: center;
      justify-content: center;
      position: relative; }
      .notification-wrapper .notification-card .imagebox .labelImage {
        position: absolute;
        top: 3px;
        right: -6px;
        background-color: #f60000;
        border-radius: 9px;
        padding: 2px;
        font-size: 10px;
        width: 17px;
        text-align: center;
        color: #ffffff;
        margin: 0; }
      .notification-wrapper .notification-card .imagebox .empty-image {
        height: 30px;
        width: 30px;
        object-fit: fill; }
      .notification-wrapper .notification-card .imagebox img {
        height: 49px;
        width: 48px;
        border-radius: 25px;
        object-fit: fill; }
    .notification-wrapper .notification-card .title-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      width: 75%; }
      .notification-wrapper .notification-card .title-wrapper p {
        color: #000000;
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 500; }
      .notification-wrapper .notification-card .title-wrapper .description-box {
        display: flex;
        color: #000000;
        font-size: 14px; }
        .notification-wrapper .notification-card .title-wrapper .description-box p {
          color: #000000;
          font-size: 14px;
          margin-bottom: 5px;
          font-weight: 500; }
    .notification-wrapper .notification-card .date-wrapper {
      display: flex;
      width: 21%;
      align-items: center;
      justify-content: flex-start; }
      .notification-wrapper .notification-card .date-wrapper p {
        color: #565656;
        font-size: 13px;
        margin-bottom: 5px;
        font-weight: 500; }
    .notification-wrapper .notification-card :hover {
      cursor: pointer; }
  .notification-wrapper .empty-table {
    display: flex;
    justify-content: center;
    height: calc(100% - 2rem);
    width: 100%;
    align-items: center;
    font-size: 15px;
    color: #000000; }
  .notification-wrapper .circular-loader {
    top: 50%;
    position: absolute;
    left: 56%; }

.tile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0; }
  .tile .imgContainer {
    height: 150px;
    width: 150px; }
    .tile .imgContainer-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
      cursor: pointer; }
  .tile .content {
    padding: 2px;
    width: 50rem !important;
    overflow: hidden;
    text-overflow: ellipsis; }
    .tile .content .title {
      font-size: 18px;
      font-weight: bold;
      color: #000;
      cursor: pointer; }
    .tile .content .content-text {
      font-size: 12px;
      color: #656565; }
  .tile .action {
    padding: 35px;
    display: flex;
    flex-direction: column;
    margin-right: 10px; }
  .tile .row {
    display: flex;
    cursor: pointer;
    align-items: center; }
    .tile .row img {
      height: 15px;
      width: 15px;
      margin-bottom: 5px;
      margin-right: 5px; }

.framewrapper {
  width: 100%;
  font-weight: 500; }

.editor-modal-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .editor-modal-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.editor-modal-dialog .editor-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  padding: 2% 14%;
  overflow-y: unset; }
  .editor-modal-dialog .editor-modal-content .editor-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .upload-box {
      display: flex;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      font-size: 13px;
      color: #58514b;
      font-weight: 500;
      cursor: pointer; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .upload-box img.image-upload {
        margin-bottom: 0px;
        width: 50px; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .upload-box img.media-thumb {
        max-width: 100%;
        max-height: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .bg-white {
      background-color: #fff; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .form-control {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .form-control input {
        width: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .rdw-editor-wrapper {
      width: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .ButtonsWrapper {
      width: 100%;
      text-align: right;
      margin-top: 2rem; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .CancelButton {
      cursor: pointer;
      width: 170px;
      margin: 0 10px 0 0;
      height: 50px;
      border-radius: 3.5px;
      color: #000000;
      border: 0.25px solid #58514b; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .PublishButton {
      position: relative;
      cursor: pointer;
      width: 170px;
      margin: auto;
      height: 50px;
      border-radius: 3.5px;
      color: #ffffff;
      background-color: #58514b;
      border: 0.25px solid #58514b; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .PublishButton .modal-button-loader {
        position: absolute;
        left: 10%;
        color: #ffffff !important; }

.editor-modal-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .editor-modal-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.editor-modal-dialog .editor-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  padding: 2% 14%;
  overflow-y: unset; }
  .editor-modal-dialog .editor-modal-content .editor-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .upload-box {
      display: flex;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      font-size: 13px;
      color: #58514b;
      font-weight: 500;
      cursor: pointer; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .upload-box img.image-upload {
        margin-bottom: 0px;
        width: 50px; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .upload-box img.media-thumb {
        max-width: 100%;
        max-height: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .bg-white {
      background-color: #fff; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .form-control {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .form-control input {
        width: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .rdw-editor-wrapper {
      width: 100%; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .ButtonsWrapper {
      width: 100%;
      text-align: right;
      margin-top: 2rem; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .CancelButton {
      cursor: pointer;
      width: 170px;
      margin: 0 10px 0 0;
      height: 50px;
      border-radius: 3.5px;
      color: #000000;
      border: 0.25px solid #58514b; }
    .editor-modal-dialog .editor-modal-content .editor-main-wrapper .PublishButton {
      position: relative;
      cursor: pointer;
      width: 170px;
      margin: auto;
      height: 50px;
      border-radius: 3.5px;
      color: #ffffff;
      background-color: #58514b;
      border: 0.25px solid #58514b; }
      .editor-modal-dialog .editor-modal-content .editor-main-wrapper .PublishButton .modal-button-loader {
        position: absolute;
        left: 10%;
        color: #ffffff !important; }

.blogcontainer {
  margin: 2%;
  height: 90%;
  width: 100%;
  overflow: scroll; }

.paginationWrapper {
  margin: 2%; }

.ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem; }

.ant-pagination-prev {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-item {
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  border-color: #eeeeee;
  border-radius: 0; }

.ant-pagination-next {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-jump-next {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-jump-prev {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-item a {
  color: #000000; }

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #58514b; }

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-item.ant-pagination-item-active a {
  border-color: #58514b;
  color: #ffffff; }

.ant-pagination-item-active {
  background-color: #58514b; }

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #58514b; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #58514b; }

.anticon {
  color: #58514b; }

.ant-pagination-prev .ant-pagination-item-link {
  border-color: #eeeeee;
  border-radius: 0; }

.ant-pagination-next .ant-pagination-item-link {
  border-color: #eeeeee;
  border-radius: 0; }

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #58514b; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #58514b; }

.add-button-box {
  display: flex;
  position: absolute;
  align-items: center;
  height: 45px;
  right: 2rem;
  bottom: 1rem; }
  .add-button-box .add-btn {
    background-color: #ffffff;
    border: 1px solid #58514b;
    border-radius: 3px;
    height: 35px;
    border: 1px solid #58514b;
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0.25px 0.25px 12px #00000029;
    cursor: pointer;
    color: #58514b;
    font-size: 12px;
    font-weight: 500; }
    .add-button-box .add-btn p {
      font-size: 20px;
      color: #58514b;
      margin: 0;
      padding-right: 5px;
      font-weight: 500; }

.container {
  margin: 2%;
  height: 90%;
  width: 100%;
  overflow: scroll;
  padding-bottom: 5%; }

.paginationWrapper {
  margin: 2%; }

.ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem; }

.ant-pagination-prev {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-item {
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  border-color: #eeeeee;
  border-radius: 0; }

.ant-pagination-next {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-jump-next {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-jump-prev {
  min-width: 22px;
  height: 22px;
  line-height: 22px; }

.ant-pagination-item a {
  color: #000000; }

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #58514b; }

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-item.ant-pagination-item-active a {
  border-color: #58514b;
  color: #ffffff; }

.ant-pagination-item-active {
  background-color: #58514b; }

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #58514b; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #58514b; }

.anticon {
  color: #58514b; }

.ant-pagination-prev .ant-pagination-item-link {
  border-color: #eeeeee;
  border-radius: 0; }

.ant-pagination-next .ant-pagination-item-link {
  border-color: #eeeeee;
  border-radius: 0; }

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #58514b; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #58514b; }

.add-button-box {
  display: flex;
  position: absolute;
  align-items: center;
  height: 45px;
  right: 2rem;
  bottom: 1rem; }
  .add-button-box .add-btn {
    background-color: #ffffff;
    border: 1px solid #58514b;
    border-radius: 3px;
    height: 35px;
    border: 1px solid #58514b;
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0.25px 0.25px 12px #00000029;
    cursor: pointer;
    color: #58514b;
    font-size: 12px;
    font-weight: 500; }
    .add-button-box .add-btn p {
      font-size: 20px;
      color: #58514b;
      margin: 0;
      padding-right: 5px;
      font-weight: 500; }

.table {
  height: 100%;
  overflow: scroll; }

.edit-product-wrapper {
  display: flex;
  justify-content: center;
  height: calc(100% - 4rem);
  overflow-y: scroll;
  padding: 1rem 15%; }
  .edit-product-wrapper-box {
    width: 25rem;
    height: 100%; }
  .edit-product-wrapper .edit-product-subtitle {
    font-weight: 500;
    font-size: 16px;
    border-bottom: 1px solid #bfbfbf;
    color: #d2502d;
    position: relative; }
    .edit-product-wrapper .edit-product-subtitle img {
      position: absolute;
      left: -25px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      transition: -webkit-transform 0.2s linear;
      transition: transform 0.2s linear;
      transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
      .edit-product-wrapper .edit-product-subtitle img.rotate-icon {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        transition: -webkit-transform 0.2s linear;
        transition: transform 0.2s linear;
        transition: transform 0.2s linear, -webkit-transform 0.2s linear; }
  .edit-product-wrapper .form-control {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem; }
    .edit-product-wrapper .form-control .artwork-image {
      height: 30px;
      width: 30px;
      object-fit: cover;
      margin-right: 5px; }
    .edit-product-wrapper .form-control .category-cascader .ant-input {
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      height: 45px;
      padding: 0px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #323643; }
    .edit-product-wrapper .form-control .category-cascader .ant-cascader-picker-label {
      display: flex;
      align-items: center;
      height: 100%;
      top: 0;
      margin-top: 0; }
    .edit-product-wrapper .form-control .ant-select-selection {
      height: 45px;
      border-color: #cdcdcd;
      font-size: 13px;
      font-weight: 500; }
      .edit-product-wrapper .form-control .ant-select-selection:hover {
        border-color: #cdcdcd;
        border-right-width: 1px !important; }
      .edit-product-wrapper .form-control .ant-select-selection .ant-select-selection__rendered {
        display: flex;
        align-items: center;
        height: 100%; }
        .edit-product-wrapper .form-control .ant-select-selection .ant-select-selection__rendered ul {
          height: 100%; }
    .edit-product-wrapper .form-control .ant-select-focused .ant-select-selection,
    .edit-product-wrapper .form-control .ant-select-selection:focus,
    .edit-product-wrapper .form-control .ant-select-selection:active {
      border-color: #323643;
      border-width: 2px;
      border-right-width: 2px !important;
      box-shadow: none; }
    .edit-product-wrapper .form-control .tags-select .ant-select-selection {
      height: 3.7rem;
      overflow-y: scroll; }
      .edit-product-wrapper .form-control .tags-select .ant-select-selection::-webkit-scrollbar {
        -webkit-appearance: none; }
    .edit-product-wrapper .form-control textarea {
      padding: 3px 6px;
      min-height: 70px;
      height: -webkit-max-content;
      height: max-content; }
    .edit-product-wrapper .form-control.form-checkbox {
      flex-direction: row;
      margin-bottom: 1rem; }
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox-inner {
        border-color: #cdcdcd; }
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #323643;
        border-color: #323643; }
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox-checked::after {
        border: 1px solid #323643; }
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox:hover .ant-checkbox-inner,
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #323643; }
      .edit-product-wrapper .form-control.form-checkbox .ant-checkbox-checked::after {
        border: 1px solid #323643; }
      .edit-product-wrapper .form-control.form-checkbox span {
        font-size: 13px;
        font-weight: 500;
        color: #707070; }
    .edit-product-wrapper .form-control .input-unit-wrapper {
      display: flex; }
      .edit-product-wrapper .form-control .input-unit-wrapper input {
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      .edit-product-wrapper .form-control .input-unit-wrapper span.unit {
        width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        border: 1px solid #cdcdcd;
        border-left: 0;
        border-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 12px;
        font-weight: 500;
        color: #323643; }
  .edit-product-wrapper .product-iv-title {
    font-size: 14px;
    margin: 1rem 0 5px 3px;
    font-weight: 500;
    color: #707070; }
  .edit-product-wrapper .product-iv-contents {
    display: flex;
    flex-wrap: wrap; }
    .edit-product-wrapper .product-iv-contents .display-hover-screen {
      position: absolute;
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.97);
      bottom: 0;
      border-radius: 3px;
      font-size: 13px;
      padding: 0 1rem;
      text-align: center;
      height: 100%;
      width: 100%; }
    .edit-product-wrapper .product-iv-contents .product-iv-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 5px;
      height: 10rem;
      width: 10rem;
      margin: 5px;
      cursor: pointer; }
      .edit-product-wrapper .product-iv-contents .product-iv-item img,
      .edit-product-wrapper .product-iv-contents .product-iv-item .video-container {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
        margin-top: -1px; }
      .edit-product-wrapper .product-iv-contents .product-iv-item .close-icon {
        position: absolute;
        top: -7px;
        right: -7px; }
      .edit-product-wrapper .product-iv-contents .product-iv-item .download-icon {
        font-size: 27px;
        padding: 15px;
        background-color: rgba(218, 218, 218, 0.56);
        border-radius: 50%; }
      .edit-product-wrapper .product-iv-contents .product-iv-item .edit-icon {
        margin-left: 5px;
        font-size: 27px;
        padding: 15px;
        background-color: rgba(218, 218, 218, 0.56);
        border-radius: 50%; }
      .edit-product-wrapper .product-iv-contents .product-iv-item:hover .display-hover-screen {
        display: flex; }
        .edit-product-wrapper .product-iv-contents .product-iv-item:hover .display-hover-screen .download-icon {
          -webkit-animation: ZoomInKey 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                  animation: ZoomInKey 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@-webkit-keyframes ZoomInKey {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes ZoomInKey {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
  .edit-product-wrapper .form-control-array {
    width: 100%;
    padding: 1rem 1rem 10px;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    margin-bottom: 1rem; }
    .edit-product-wrapper .form-control-array label {
      display: block;
      margin-bottom: 10px; }
    .edit-product-wrapper .form-control-array .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 13px;
      font-weight: 500;
      color: #707070; }
      .edit-product-wrapper .form-control-array .button-wrapper img {
        height: 40px;
        width: 40px;
        margin-bottom: -6px;
        cursor: pointer; }
  .edit-product-wrapper .mandatory {
    color: #ff0000; }
  .edit-product-wrapper .display-hover-screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.97);
    bottom: 0;
    border-radius: 3px;
    font-size: 13px;
    padding: 0 1rem;
    text-align: center;
    height: 100%;
    width: 100%; }
  .edit-product-wrapper .edit-product-button-container {
    display: flex;
    justify-content: flex-end;
    margin: 2rem .5rem; }
    .edit-product-wrapper .edit-product-button-container button {
      height: 50px;
      border: none;
      background-color: #58514b;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      padding: 3px 2rem;
      border-radius: 3px;
      width: 100%;
      margin-left: 5px; }
      .edit-product-wrapper .edit-product-button-container button:disabled {
        background-color: rgba(218, 218, 218, 0.56); }

.disabledbutton {
  pointer-events: none;
  opacity: 0.4; }

.countriestaxes-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .countriestaxes-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .countriestaxes-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
  .countriestaxes-wrapper .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 1395px) {
      .countriestaxes-wrapper .sub-wrapper {
        overflow-x: scroll;
        overflow-y: scroll; } }
    .countriestaxes-wrapper .sub-wrapper .colWrapper {
      display: flex; }
    .countriestaxes-wrapper .sub-wrapper .labelImage {
      position: absolute;
      top: 0px;
      left: -0.5px; }
    .countriestaxes-wrapper .sub-wrapper .colImage {
      height: 60px;
      width: 60px;
      object-fit: fill; }
    .countriestaxes-wrapper .sub-wrapper .colDiv {
      display: flex; }
    .countriestaxes-wrapper .sub-wrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .countriestaxes-wrapper .sub-wrapper .colGreyText {
      color: #727272;
      margin: 0;
      font-size: 12px;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .countriestaxes-wrapper .sub-wrapper .rftext {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .countriestaxes-wrapper .sub-wrapper .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .countriestaxes-wrapper .sub-wrapper .blackText {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000; }
    .countriestaxes-wrapper .sub-wrapper .textCenterBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-align: center; }
    .countriestaxes-wrapper .sub-wrapper .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fe9600; }
    .countriestaxes-wrapper .sub-wrapper .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0b969b; }
    .countriestaxes-wrapper .sub-wrapper .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0072d6; }
    .countriestaxes-wrapper .sub-wrapper .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #499b0b; }
    .countriestaxes-wrapper .sub-wrapper .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #d2502d; }
    .countriestaxes-wrapper .sub-wrapper .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #9b0b1e; }
    .countriestaxes-wrapper .sub-wrapper .dropDown {
      display: flex;
      align-items: center;
      height: 35px;
      background-color: #f5f5f5;
      border-radius: 4px;
      width: 11rem;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px; }
      .countriestaxes-wrapper .sub-wrapper .dropDown .anticon.anticon-down {
        font-weight: 900; }
    .countriestaxes-wrapper .sub-wrapper .artistDiv {
      display: flex;
      align-items: center; }
      .countriestaxes-wrapper .sub-wrapper .artistDiv img {
        height: 11px;
        width: 11px;
        margin-right: 8px;
        margin-bottom: 2px; }
      .countriestaxes-wrapper .sub-wrapper .artistDiv p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        width: 6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    .countriestaxes-wrapper .sub-wrapper .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000; }
    .countriestaxes-wrapper .sub-wrapper .add-button-box {
      display: flex;
      position: absolute;
      align-items: center;
      height: 45px;
      right: 2rem;
      bottom: 1rem; }
      .countriestaxes-wrapper .sub-wrapper .add-button-box .add-btn {
        background-color: #ffffff;
        border: 1px solid #58514b;
        border-radius: 3px;
        height: 35px;
        border: 1px solid #58514b;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0.25px 0.25px 12px #00000029;
        cursor: pointer;
        color: #58514b;
        font-size: 12px;
        font-weight: 500; }
        .countriestaxes-wrapper .sub-wrapper .add-button-box .add-btn p {
          font-size: 20px;
          color: #58514b;
          margin: 0;
          padding-right: 5px;
          font-weight: 500; }
    .countriestaxes-wrapper .sub-wrapper .ant-select {
      margin-right: 10px; }
    .countriestaxes-wrapper .sub-wrapper .ant-select-selection:focus,
    .countriestaxes-wrapper .sub-wrapper .ant-select-selection:active,
    .countriestaxes-wrapper .sub-wrapper .ant-select-selection:hover {
      border-color: #000000;
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
    .countriestaxes-wrapper .sub-wrapper .ant-table {
      color: #000000;
      font-weight: 500;
      margin-bottom: 40px; }
    .countriestaxes-wrapper .sub-wrapper .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2rem;
      background-color: #f5f5f5;
      color: #000000; }
      .countriestaxes-wrapper .sub-wrapper .ant-table-thead > tr > th:last-child {
        padding: 10px 2rem 10px 10px; }
    .countriestaxes-wrapper .sub-wrapper .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem; }
      .countriestaxes-wrapper .sub-wrapper .ant-table-tbody > tr > td:last-child {
        padding: 10px 10px 10px 10px; }
    .countriestaxes-wrapper .sub-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e3d2b0;
      box-shadow: 0px 5px 0px 0px #e3d2b0; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      margin-bottom: 20px; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item {
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      border-color: #eeeeee;
      border-radius: 0; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-jump-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item a {
      color: #000000; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item:focus a,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item:hover a {
      color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item-active:focus a,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item-active:hover a,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item.ant-pagination-item-active a {
      border-color: #58514b;
      color: #ffffff; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item-active {
      background-color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item:focus,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-item:hover {
      border-color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .anticon {
      color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-prev .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-next .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .countriestaxes-wrapper .sub-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #58514b; }
    .countriestaxes-wrapper .sub-wrapper .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.countries-taxes-action-popover .ant-popover-inner {
  background-color: #000000; }

.countries-taxes-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.countries-taxes-action-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000;
  border-bottom-color: #000000;
  border-right-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain; }
  .action-image:hover {
    cursor: pointer; }

.action-span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8b8b8b;
  justify-content: flex-start;
  cursor: pointer; }
  .action-span .anticon {
    margin-right: 8px; }
  .action-span img {
    height: 20px;
    width: 20px;
    margin-right: 5px; }
  .action-span .pop-item {
    color: #ffffff;
    margin: 0; }
    .action-span .pop-item:hover {
      color: #ad9972; }

.add-country-tax-dialog .add-country-tax-content {
  width: 25rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .add-country-tax-dialog .add-country-tax-content .add-country-tax-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-country-tax-dialog .add-country-tax-content .add-country-tax-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-country-tax-dialog .add-country-tax-content .add-country-tax-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-body .bg-white {
      background-color: #fff; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-country-tax-dialog .add-country-tax-content .add-country-tax-body .form-control input {
        width: 100%; }
  .add-country-tax-dialog .add-country-tax-content .ant-select-selection:focus,
  .add-country-tax-dialog .add-country-tax-content .ant-select-selection:active,
  .add-country-tax-dialog .add-country-tax-content .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .add-country-tax-dialog .add-country-tax-content .add-country-tax-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .add-country-tax-dialog .add-country-tax-content .add-country-tax-action {
    display: flex;
    width: 100%;
    justify-content: center; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-action button {
      width: 49%; }
      .add-country-tax-dialog .add-country-tax-content .add-country-tax-action button:first-of-type {
        margin-right: 2%; }
    .add-country-tax-dialog .add-country-tax-content .add-country-tax-action .modal-button-loader {
      position: absolute;
      left: 70%; }

.quotes-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .quotes-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .quotes-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
  .quotes-wrapper .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 1395px) {
      .quotes-wrapper .sub-wrapper {
        overflow-x: scroll;
        overflow-y: scroll; } }
    .quotes-wrapper .sub-wrapper .colWrapper {
      display: flex; }
    .quotes-wrapper .sub-wrapper .labelImage {
      position: absolute;
      top: 0px;
      left: -0.5px; }
    .quotes-wrapper .sub-wrapper .colImage {
      height: 60px;
      width: 60px;
      object-fit: fill; }
    .quotes-wrapper .sub-wrapper .colDiv {
      display: flex;
      flex-direction: column;
      padding-left: 10px; }
    .quotes-wrapper .sub-wrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      width: 10rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .quotes-wrapper .sub-wrapper .colGreyText {
      color: #727272;
      margin: 0;
      font-size: 14px;
      width: 5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .quotes-wrapper .sub-wrapper .rftext {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .quotes-wrapper .sub-wrapper .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 10rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .quotes-wrapper .sub-wrapper .textCenterBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000; }
    .quotes-wrapper .sub-wrapper .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fe9600; }
    .quotes-wrapper .sub-wrapper .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0b969b; }
    .quotes-wrapper .sub-wrapper .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0072d6; }
    .quotes-wrapper .sub-wrapper .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #499b0b; }
    .quotes-wrapper .sub-wrapper .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #d2502d; }
    .quotes-wrapper .sub-wrapper .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #9b0b1e; }
    .quotes-wrapper .sub-wrapper .dropDown {
      display: flex;
      align-items: center;
      height: 35px;
      background-color: #f5f5f5;
      border-radius: 4px;
      width: 11rem;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px; }
      .quotes-wrapper .sub-wrapper .dropDown .anticon.anticon-down {
        font-weight: 900; }
    .quotes-wrapper .sub-wrapper .artistDiv {
      display: flex;
      align-items: center; }
      .quotes-wrapper .sub-wrapper .artistDiv img {
        height: 11px;
        width: 11px;
        margin-right: 8px;
        margin-bottom: 2px; }
      .quotes-wrapper .sub-wrapper .artistDiv p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        width: 6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    .quotes-wrapper .sub-wrapper .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000; }
    .quotes-wrapper .sub-wrapper .add-button-box {
      display: flex;
      position: absolute;
      align-items: center;
      height: 45px;
      right: 2rem;
      bottom: 1rem; }
      .quotes-wrapper .sub-wrapper .add-button-box .add-btn {
        background-color: #ffffff;
        border: 1px solid #58514b;
        border-radius: 3px;
        height: 35px;
        border: 1px solid #58514b;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0.25px 0.25px 12px #00000029;
        cursor: pointer;
        color: #58514b;
        font-size: 12px;
        font-weight: 500; }
        .quotes-wrapper .sub-wrapper .add-button-box .add-btn p {
          font-size: 20px;
          color: #58514b;
          margin: 0;
          padding-right: 5px;
          font-weight: 500; }
    .quotes-wrapper .sub-wrapper .ant-select {
      margin-right: 10px; }
    .quotes-wrapper .sub-wrapper .ant-select-selection:focus,
    .quotes-wrapper .sub-wrapper .ant-select-selection:active,
    .quotes-wrapper .sub-wrapper .ant-select-selection:hover {
      border-color: #000000;
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
    .quotes-wrapper .sub-wrapper .ant-table {
      color: #000000;
      font-weight: 500;
      margin-bottom: 40px; }
    .quotes-wrapper .sub-wrapper .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2rem;
      background-color: #f5f5f5;
      color: #000000; }
      .quotes-wrapper .sub-wrapper .ant-table-thead > tr > th:last-child {
        padding: 10px 2rem 10px 10px; }
    .quotes-wrapper .sub-wrapper .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem; }
      .quotes-wrapper .sub-wrapper .ant-table-tbody > tr > td:last-child {
        padding: 10px 10px 10px 10px; }
    .quotes-wrapper .sub-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e3d2b0;
      box-shadow: 0px 5px 0px 0px #e3d2b0; }
    .quotes-wrapper .sub-wrapper .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      margin-bottom: 20px; }
    .quotes-wrapper .sub-wrapper .ant-pagination-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .quotes-wrapper .sub-wrapper .ant-pagination-item {
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      border-color: #eeeeee;
      border-radius: 0; }
    .quotes-wrapper .sub-wrapper .ant-pagination-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .quotes-wrapper .sub-wrapper .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .quotes-wrapper .sub-wrapper .ant-pagination-jump-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .quotes-wrapper .sub-wrapper .ant-pagination-item a {
      color: #000000; }
    .quotes-wrapper .sub-wrapper .ant-pagination-item:focus a,
    .quotes-wrapper .sub-wrapper .ant-pagination-item:hover a {
      color: #58514b; }
    .quotes-wrapper .sub-wrapper .ant-pagination-item-active:focus a,
    .quotes-wrapper .sub-wrapper .ant-pagination-item-active:hover a,
    .quotes-wrapper .sub-wrapper .ant-pagination-item.ant-pagination-item-active a {
      border-color: #58514b;
      color: #ffffff; }
    .quotes-wrapper .sub-wrapper .ant-pagination-item-active {
      background-color: #58514b; }
    .quotes-wrapper .sub-wrapper .ant-pagination-item:focus,
    .quotes-wrapper .sub-wrapper .ant-pagination-item:hover {
      border-color: #58514b; }
    .quotes-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #58514b; }
    .quotes-wrapper .sub-wrapper .anticon {
      color: #58514b; }
    .quotes-wrapper .sub-wrapper .ant-pagination-prev .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .quotes-wrapper .sub-wrapper .ant-pagination-next .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .quotes-wrapper .sub-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
    .quotes-wrapper .sub-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
    .quotes-wrapper .sub-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
    .quotes-wrapper .sub-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #58514b; }
    .quotes-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .quotes-wrapper .sub-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #58514b; }
    .quotes-wrapper .sub-wrapper .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.quotes-action-popover .ant-popover-inner {
  background-color: #000000; }

.quotes-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.quotes-action-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000;
  border-bottom-color: #000000;
  border-right-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain; }
  .action-image:hover {
    cursor: pointer; }

.action-span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8b8b8b;
  justify-content: flex-start;
  cursor: pointer; }
  .action-span .anticon {
    margin-right: 8px; }
  .action-span img {
    height: 20px;
    width: 20px;
    margin-right: 5px; }
  .action-span .pop-item {
    color: #ffffff;
    margin: 0; }
    .action-span .pop-item:hover {
      color: #ad9972; }

.nav-link {
  cursor: pointer; }

.two-factor-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  height: 100%; }
  .two-factor-wrapper .app-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px; }
  .two-factor-wrapper .two-factor-contents {
    display: flex;
    flex-direction: column;
    width: 393px;
    height: 340px;
    padding-bottom: 40px;
    background-color: #ffffff;
    padding: 30px 30px 30px 30px; }
    @media screen and (max-width: 430px) {
      .two-factor-wrapper .two-factor-contents {
        width: 80%; } }
    .two-factor-wrapper .two-factor-contents .page-title {
      font-size: 16px;
      color: #434343;
      margin: 0;
      text-align: center;
      font-weight: 700;
      padding-bottom: 1rem; }
    .two-factor-wrapper .two-factor-contents .message {
      margin: 0;
      font-size: 14px;
      color: #434343;
      font-weight: 500;
      text-align: center;
      padding-bottom: 20px; }
    .two-factor-wrapper .two-factor-contents .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .two-factor-wrapper .two-factor-contents .form-control input {
        width: 100%;
        margin-bottom: 25px; }
      .two-factor-wrapper .two-factor-contents .form-control input[type="number"]::-webkit-inner-spin-button,
      .two-factor-wrapper .two-factor-contents .form-control input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    .two-factor-wrapper .two-factor-contents .end-btn {
      display: flex;
      flex-direction: column; }
      .two-factor-wrapper .two-factor-contents .end-btn button {
        height: 45px;
        width: 100%;
        position: relative; }
      .two-factor-wrapper .two-factor-contents .end-btn p {
        font-size: 14px;
        color: #fe9600;
        text-align: center;
        padding-top: 10px;
        font-weight: 500;
        cursor: pointer; }
  .two-factor-wrapper .two-factor-progress {
    position: absolute;
    top: 20%;
    left: 47%;
    color: #fe9600; }

.shipmentprovider-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .shipmentprovider-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .shipmentprovider-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
  .shipmentprovider-wrapper .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 1395px) {
      .shipmentprovider-wrapper .sub-wrapper {
        overflow-x: scroll;
        overflow-y: scroll; } }
    .shipmentprovider-wrapper .sub-wrapper .colWrapper {
      display: flex; }
    .shipmentprovider-wrapper .sub-wrapper .labelImage {
      position: absolute;
      top: 0px;
      left: -0.5px; }
    .shipmentprovider-wrapper .sub-wrapper .colImage {
      height: 60px;
      width: 60px;
      object-fit: fill; }
    .shipmentprovider-wrapper .sub-wrapper .colDiv {
      display: flex; }
    .shipmentprovider-wrapper .sub-wrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shipmentprovider-wrapper .sub-wrapper .colGreyText {
      color: #727272;
      margin: 0;
      font-size: 12px;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shipmentprovider-wrapper .sub-wrapper .rftext {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shipmentprovider-wrapper .sub-wrapper .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shipmentprovider-wrapper .sub-wrapper .blackText {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000; }
    .shipmentprovider-wrapper .sub-wrapper .textCenterBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-align: center; }
    .shipmentprovider-wrapper .sub-wrapper .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fe9600; }
    .shipmentprovider-wrapper .sub-wrapper .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0b969b; }
    .shipmentprovider-wrapper .sub-wrapper .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0072d6; }
    .shipmentprovider-wrapper .sub-wrapper .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #499b0b; }
    .shipmentprovider-wrapper .sub-wrapper .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #d2502d; }
    .shipmentprovider-wrapper .sub-wrapper .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #9b0b1e; }
    .shipmentprovider-wrapper .sub-wrapper .dropDown {
      display: flex;
      align-items: center;
      height: 35px;
      background-color: #f5f5f5;
      border-radius: 4px;
      width: 11rem;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px; }
      .shipmentprovider-wrapper .sub-wrapper .dropDown .anticon.anticon-down {
        font-weight: 900; }
    .shipmentprovider-wrapper .sub-wrapper .artistDiv {
      display: flex;
      align-items: center; }
      .shipmentprovider-wrapper .sub-wrapper .artistDiv img {
        height: 11px;
        width: 11px;
        margin-right: 8px;
        margin-bottom: 2px; }
      .shipmentprovider-wrapper .sub-wrapper .artistDiv p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        width: 6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    .shipmentprovider-wrapper .sub-wrapper .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000; }
    .shipmentprovider-wrapper .sub-wrapper .add-button-box {
      display: flex;
      position: absolute;
      align-items: center;
      height: 45px;
      right: 2rem;
      bottom: 1rem; }
      .shipmentprovider-wrapper .sub-wrapper .add-button-box .add-btn {
        background-color: #ffffff;
        border: 1px solid #58514b;
        border-radius: 3px;
        height: 35px;
        border: 1px solid #58514b;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0.25px 0.25px 12px #00000029;
        cursor: pointer;
        color: #58514b;
        font-size: 12px;
        font-weight: 500; }
        .shipmentprovider-wrapper .sub-wrapper .add-button-box .add-btn p {
          font-size: 20px;
          color: #58514b;
          margin: 0;
          padding-right: 5px;
          font-weight: 500; }
    .shipmentprovider-wrapper .sub-wrapper .ant-select {
      margin-right: 10px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-select-selection:focus,
    .shipmentprovider-wrapper .sub-wrapper .ant-select-selection:active,
    .shipmentprovider-wrapper .sub-wrapper .ant-select-selection:hover {
      border-color: #000000;
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
    .shipmentprovider-wrapper .sub-wrapper .ant-table {
      color: #000000;
      font-weight: 500;
      margin-bottom: 40px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2rem;
      background-color: #f5f5f5;
      color: #000000; }
      .shipmentprovider-wrapper .sub-wrapper .ant-table-thead > tr > th:last-child {
        padding: 10px 2rem 10px 10px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem; }
      .shipmentprovider-wrapper .sub-wrapper .ant-table-tbody > tr > td:last-child {
        padding: 10px 10px 10px 10px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e3d2b0;
      box-shadow: 0px 5px 0px 0px #e3d2b0; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      margin-bottom: 20px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item {
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      border-color: #eeeeee;
      border-radius: 0; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-jump-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item a {
      color: #000000; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item:focus a,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item:hover a {
      color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item-active:focus a,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item-active:hover a,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item.ant-pagination-item-active a {
      border-color: #58514b;
      color: #ffffff; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item-active {
      background-color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item:focus,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-item:hover {
      border-color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .anticon {
      color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-prev .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-next .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .shipmentprovider-wrapper .sub-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #58514b; }
    .shipmentprovider-wrapper .sub-wrapper .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.shipment-provider-action-popover .ant-popover-inner {
  background-color: #000000; }

.shipment-provider-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.shipment-provider-action-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000;
  border-bottom-color: #000000;
  border-right-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain; }
  .action-image:hover {
    cursor: pointer; }

.action-span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8b8b8b;
  justify-content: flex-start;
  cursor: pointer; }
  .action-span .anticon {
    margin-right: 8px; }
  .action-span img {
    height: 20px;
    width: 20px;
    margin-right: 5px; }
  .action-span .pop-item {
    color: #ffffff;
    margin: 0; }
    .action-span .pop-item:hover {
      color: #ad9972; }

.add-shipment-provider-dialog .add-shipment-provider-content {
  width: 25rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body .bg-white {
      background-color: #fff; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-body .form-control input {
        width: 100%; }
  .add-shipment-provider-dialog .add-shipment-provider-content .ant-select-selection:focus,
  .add-shipment-provider-dialog .add-shipment-provider-content .ant-select-selection:active,
  .add-shipment-provider-dialog .add-shipment-provider-content .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-action {
    display: flex;
    width: 100%;
    justify-content: center; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-action button {
      width: 49%; }
      .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-action button:first-of-type {
        margin-right: 2%; }
    .add-shipment-provider-dialog .add-shipment-provider-content .add-shipment-provider-action .modal-button-loader {
      position: absolute;
      left: 70%; }

.shippingcharges-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem); }
  .shippingcharges-wrapper .filter-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2rem; }
    .shippingcharges-wrapper .filter-wrapper .filter-img {
      width: 26px;
      margin-right: 5px; }
  .shippingcharges-wrapper .sub-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    @media screen and (max-width: 1395px) {
      .shippingcharges-wrapper .sub-wrapper {
        overflow-x: scroll;
        overflow-y: scroll; } }
    .shippingcharges-wrapper .sub-wrapper .colWrapper {
      display: flex; }
    .shippingcharges-wrapper .sub-wrapper .labelImage {
      position: absolute;
      top: 0px;
      left: -0.5px; }
    .shippingcharges-wrapper .sub-wrapper .colImage {
      height: 60px;
      width: 60px;
      object-fit: fill; }
    .shippingcharges-wrapper .sub-wrapper .colDiv {
      display: flex; }
    .shippingcharges-wrapper .sub-wrapper .colText {
      color: #000000;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shippingcharges-wrapper .sub-wrapper .colGreyText {
      color: #727272;
      margin: 0;
      font-size: 12px;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shippingcharges-wrapper .sub-wrapper .rftext {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shippingcharges-wrapper .sub-wrapper .textBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      width: 7rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .shippingcharges-wrapper .sub-wrapper .blackText {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000; }
    .shippingcharges-wrapper .sub-wrapper .textCenterBlack {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      text-align: center; }
    .shippingcharges-wrapper .sub-wrapper .textOrange {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fe9600; }
    .shippingcharges-wrapper .sub-wrapper .textBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0b969b; }
    .shippingcharges-wrapper .sub-wrapper .textDarkBlue {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #0072d6; }
    .shippingcharges-wrapper .sub-wrapper .textGreen {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #499b0b; }
    .shippingcharges-wrapper .sub-wrapper .textBrown {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #d2502d; }
    .shippingcharges-wrapper .sub-wrapper .textRed {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #9b0b1e; }
    .shippingcharges-wrapper .sub-wrapper .dropDown {
      display: flex;
      align-items: center;
      height: 35px;
      background-color: #f5f5f5;
      border-radius: 4px;
      width: 11rem;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px; }
      .shippingcharges-wrapper .sub-wrapper .dropDown .anticon.anticon-down {
        font-weight: 900; }
    .shippingcharges-wrapper .sub-wrapper .artistDiv {
      display: flex;
      align-items: center; }
      .shippingcharges-wrapper .sub-wrapper .artistDiv img {
        height: 11px;
        width: 11px;
        margin-right: 8px;
        margin-bottom: 2px; }
      .shippingcharges-wrapper .sub-wrapper .artistDiv p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        width: 6rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
    .shippingcharges-wrapper .sub-wrapper .empty-table {
      display: flex;
      justify-content: center;
      height: calc(100% - 2rem);
      width: 100%;
      align-items: center;
      font-size: 15px;
      color: #000000; }
    .shippingcharges-wrapper .sub-wrapper .add-button-box {
      display: flex;
      position: absolute;
      align-items: center;
      height: 45px;
      right: 2rem;
      bottom: 1rem; }
      .shippingcharges-wrapper .sub-wrapper .add-button-box .add-btn {
        background-color: #ffffff;
        border: 1px solid #58514b;
        border-radius: 3px;
        height: 35px;
        border: 1px solid #58514b;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 10px;
        padding-right: 10px;
        box-shadow: 0.25px 0.25px 12px #00000029;
        cursor: pointer;
        color: #58514b;
        font-size: 12px;
        font-weight: 500; }
        .shippingcharges-wrapper .sub-wrapper .add-button-box .add-btn p {
          font-size: 20px;
          color: #58514b;
          margin: 0;
          padding-right: 5px;
          font-weight: 500; }
    .shippingcharges-wrapper .sub-wrapper .ant-select {
      margin-right: 10px; }
    .shippingcharges-wrapper .sub-wrapper .ant-select-selection:focus,
    .shippingcharges-wrapper .sub-wrapper .ant-select-selection:active,
    .shippingcharges-wrapper .sub-wrapper .ant-select-selection:hover {
      border-color: #000000;
      box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
    .shippingcharges-wrapper .sub-wrapper .ant-table {
      color: #000000;
      font-weight: 500;
      margin-bottom: 40px; }
    .shippingcharges-wrapper .sub-wrapper .ant-table-thead > tr > th {
      padding: 10px 10px 10px 2rem;
      background-color: #f5f5f5;
      color: #000000; }
      .shippingcharges-wrapper .sub-wrapper .ant-table-thead > tr > th:last-child {
        padding: 10px 2rem 10px 10px; }
    .shippingcharges-wrapper .sub-wrapper .ant-table-tbody > tr > td {
      padding: 10px 10px 10px 2rem; }
      .shippingcharges-wrapper .sub-wrapper .ant-table-tbody > tr > td:last-child {
        padding: 10px 10px 10px 10px; }
    .shippingcharges-wrapper .sub-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #e3d2b0;
      box-shadow: 0px 5px 0px 0px #e3d2b0; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      margin-bottom: 20px; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item {
      min-width: 22px;
      height: 22px;
      line-height: 22px;
      border-color: #eeeeee;
      border-radius: 0; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-jump-prev {
      min-width: 22px;
      height: 22px;
      line-height: 22px; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item a {
      color: #000000; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item:focus a,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item:hover a {
      color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item-active:focus a,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item-active:hover a,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item.ant-pagination-item-active a {
      border-color: #58514b;
      color: #ffffff; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item-active {
      background-color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item:focus,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-item:hover {
      border-color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .anticon {
      color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-prev .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-next .ant-pagination-item-link {
      border-color: #eeeeee;
      border-radius: 0; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-prev:focus .ant-pagination-item-link,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-next:focus .ant-pagination-item-link,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-prev:hover .ant-pagination-item-link,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-next:hover .ant-pagination-item-link {
      border-color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .shippingcharges-wrapper .sub-wrapper .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #58514b; }
    .shippingcharges-wrapper .sub-wrapper .circular-loader {
      top: 50%;
      position: absolute;
      left: 56%; }

.shipping-charges-action-popover .ant-popover-inner {
  background-color: #000000; }

.shipping-charges-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000; }

.shipping-charges-action-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000000;
  border-left-color: #000000;
  border-bottom-color: #000000;
  border-right-color: #000000; }

.action-image {
  height: 25px;
  width: 25px;
  object-fit: contain; }
  .action-image:hover {
    cursor: pointer; }

.action-span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #8b8b8b;
  justify-content: flex-start;
  cursor: pointer; }
  .action-span .anticon {
    margin-right: 8px; }
  .action-span img {
    height: 20px;
    width: 20px;
    margin-right: 5px; }
  .action-span .pop-item {
    color: #ffffff;
    margin: 0; }
    .action-span .pop-item:hover {
      color: #ad9972; }

.add-shipping-charges-dialog .add-shipping-charges-content {
  width: 25rem;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body .bg-white {
      background-color: #fff; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-body .form-control input {
        width: 100%; }
  .add-shipping-charges-dialog .add-shipping-charges-content .ant-select-selection:focus,
  .add-shipping-charges-dialog .add-shipping-charges-content .ant-select-selection:active,
  .add-shipping-charges-dialog .add-shipping-charges-content .ant-select-selection:hover {
    border-color: #000000;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
  .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-action {
    display: flex;
    width: 100%;
    justify-content: center; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-action button {
      width: 49%; }
      .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-action button:first-of-type {
        margin-right: 2%; }
    .add-shipping-charges-dialog .add-shipping-charges-content .add-shipping-charges-action .modal-button-loader {
      position: absolute;
      left: 70%; }

.lookup-wrapper {
  display: flex;
  height: calc(100% - 4rem); }
  .lookup-wrapper .lookup-main-list {
    width: 30%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
  .lookup-wrapper .ant-pagination-item.ant-pagination-item-active a {
    color: black !important;
    margin-left: 5px; }
  .lookup-wrapper .lookup-sub-list {
    width: 70%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
  .lookup-wrapper div.category-list-wrapper {
    height: 90%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    font-weight: 500; }
    .lookup-wrapper div.category-list-wrapper .circular-loader {
      margin-bottom: 10px; }
  .lookup-wrapper .list-head {
    display: flex;
    height: 2.5rem; }
    .lookup-wrapper .list-head .list-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e1e1;
      font-weight: 500;
      color: #000000;
      cursor: pointer; }
    .lookup-wrapper .list-head .active {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      background-color: #58514b;
      color: #ffffff; }
  .lookup-wrapper ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 90%;
    overflow-y: scroll; }
    .lookup-wrapper ul::-webkit-scrollbar {
      -webkit-appearance: none; }
    .lookup-wrapper ul::-webkit-scrollbar:vertical {
      width: 5px; }
    .lookup-wrapper ul::-webkit-scrollbar:horizontal {
      height: 0px; }
    .lookup-wrapper ul::-webkit-scrollbar-thumb {
      background-color: #fce9d1;
      border-radius: 5px;
      border: 2px solid transparent !important; }
    .lookup-wrapper ul::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
    .lookup-wrapper ul li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer; }
      .lookup-wrapper ul li .list-item-content {
        display: flex;
        align-items: center;
        flex: 3 1;
        padding: 0 0 0 1rem;
        height: 100%; }
        .lookup-wrapper ul li .list-item-content img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          margin-right: 15px;
          padding: 2px 1px;
          background-color: #eee;
          border: 1px solid #dcdcdc;
          border-radius: 2px; }
        .lookup-wrapper ul li .list-item-content p {
          margin: 0;
          color: #000000;
          font-size: 13px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 12rem; }
      .lookup-wrapper ul li .list-item-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem 0 0rem;
        flex: 1 1;
        height: 100%; }
        .lookup-wrapper ul li .list-item-action img.more-icon {
          display: block;
          width: 22px; }
        .lookup-wrapper ul li .list-item-action .edit-options {
          display: none; }
      .lookup-wrapper ul li.active {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .lookup-wrapper ul li.active .list-item-action img.more-icon {
          display: none; }
        .lookup-wrapper ul li.active .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .lookup-wrapper ul li.active .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .lookup-wrapper ul li.active .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .lookup-wrapper ul li.active .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
      .lookup-wrapper ul li:hover {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .lookup-wrapper ul li:hover .list-item-action img.more-icon {
          display: none; }
        .lookup-wrapper ul li:hover .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .lookup-wrapper ul li:hover .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .lookup-wrapper ul li:hover .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .lookup-wrapper ul li:hover .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
  .lookup-wrapper .add-btn-fixed {
    position: fixed !important;
    bottom: 20px;
    right: 100px;
    z-index: 999; }
  .lookup-wrapper .add-button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    position: unset; }
    .lookup-wrapper .add-button-box .add-btn {
      border: 1px solid #58514b;
      border-radius: 3px;
      height: 35px;
      border: 1px solid #58514b;
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 0.25px 0.25px 12px #00000029;
      cursor: pointer;
      color: #58514b;
      font-size: 12px; }
      .lookup-wrapper .add-button-box .add-btn p {
        font-size: 20px;
        color: #58514b;
        margin: 0;
        padding-right: 5px; }

.add-category-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .add-category-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.add-category-dialog .add-category-content {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  padding: 2% 14%;
  overflow-y: unset; }
  .add-category-dialog .add-category-content .add-category-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
    .add-category-dialog .add-category-content .add-category-body .upload-box {
      display: flex;
      background-color: #58514b;
      flex-direction: column;
      width: 9rem;
      height: 9rem;
      justify-content: center;
      align-items: center;
      border: 1px solid #cdcdcd;
      border-radius: 3px;
      margin-bottom: 1rem;
      font-size: 13px;
      color: #fff;
      font-weight: 500;
      cursor: pointer; }
      .add-category-dialog .add-category-content .add-category-body .upload-box img.image-upload {
        margin-bottom: 10px; }
      .add-category-dialog .add-category-content .add-category-body .upload-box img.media-thumb {
        max-width: 9rem;
        max-height: 9rem;
        object-fit: fill;
        min-height: 9rem;
        min-width: 9rem; }
    .add-category-dialog .add-category-content .add-category-body .bg-white {
      background-color: #fff; }
    .add-category-dialog .add-category-content .add-category-body .form-control {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .add-category-dialog .add-category-content .add-category-body .form-control input {
        width: 100%; }
  .add-category-dialog .add-category-content .add-category-featured-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 5px; }
    .add-category-dialog .add-category-content .add-category-featured-box .ant-checkbox-inner {
      border-color: #707070; }
    .add-category-dialog .add-category-content .add-category-featured-box .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #fe9600; }
  .add-category-dialog .add-category-content .add-category-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem; }
    .add-category-dialog .add-category-content .add-category-action button {
      width: 170px; }
      .add-category-dialog .add-category-content .add-category-action button:first-of-type {
        margin-right: 2%; }
    .add-category-dialog .add-category-content .add-category-action .modal-button-loader {
      position: absolute;
      right: 22%;
      color: #ffffff !important; }

.button-box-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .button-box-wrapper .button-box {
    height: 45px;
    background: #000000 0% 0% no-repeat padding-box;
    margin: 5px;
    display: flex;
    padding: 10px; }
    .button-box-wrapper .button-box p {
      text-align: left;
      font: normal normal normal 14px/28px Lato;
      letter-spacing: 0.35px;
      color: #FFFFFF; }
    .button-box-wrapper .button-box span {
      width: 22px;
      height: 22px;
      color: #FFFFFF;
      margin: 0 10PX;
      text-align: right;
      cursor: pointer; }

.filter-rule-wrapper {
  display: flex;
  height: calc(100% - 4rem); }
  .filter-rule-wrapper .lookup-main-list {
    width: 30%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
  .filter-rule-wrapper .ant-pagination-item.ant-pagination-item-active a {
    color: black !important;
    margin-left: 5px; }
  .filter-rule-wrapper .sub-list {
    width: 100%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
  .filter-rule-wrapper div.category-list-wrapper {
    height: 90%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    font-weight: 500; }
    .filter-rule-wrapper div.category-list-wrapper .circular-loader {
      margin-bottom: 10px; }
  .filter-rule-wrapper .list-head {
    display: flex;
    height: 2.5rem; }
    .filter-rule-wrapper .list-head .list-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e1e1;
      font-weight: 500;
      color: #000000;
      cursor: pointer; }
    .filter-rule-wrapper .list-head .active {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      background-color: #58514b;
      color: #ffffff; }
  .filter-rule-wrapper ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 90%;
    overflow-y: scroll; }
    .filter-rule-wrapper ul::-webkit-scrollbar {
      -webkit-appearance: none; }
    .filter-rule-wrapper ul::-webkit-scrollbar:vertical {
      width: 5px; }
    .filter-rule-wrapper ul::-webkit-scrollbar:horizontal {
      height: 0px; }
    .filter-rule-wrapper ul::-webkit-scrollbar-thumb {
      background-color: #fce9d1;
      border-radius: 5px;
      border: 2px solid transparent !important; }
    .filter-rule-wrapper ul::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
    .filter-rule-wrapper ul li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer; }
      .filter-rule-wrapper ul li .list-item-content {
        display: flex;
        align-items: center;
        flex: 3 1;
        padding: 0 0 0 1rem;
        height: 100%; }
        .filter-rule-wrapper ul li .list-item-content img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          margin-right: 15px;
          padding: 2px 1px;
          background-color: #eee;
          border: 1px solid #dcdcdc;
          border-radius: 2px; }
        .filter-rule-wrapper ul li .list-item-content p {
          margin: 0;
          color: #000000;
          font-size: 13px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 12rem; }
      .filter-rule-wrapper ul li .list-item-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem 0 0rem;
        flex: 1 1;
        height: 100%; }
        .filter-rule-wrapper ul li .list-item-action img.more-icon {
          display: block;
          width: 22px; }
        .filter-rule-wrapper ul li .list-item-action .edit-options {
          display: none; }
      .filter-rule-wrapper ul li.active {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .filter-rule-wrapper ul li.active .list-item-action img.more-icon {
          display: none; }
        .filter-rule-wrapper ul li.active .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .filter-rule-wrapper ul li.active .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .filter-rule-wrapper ul li.active .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .filter-rule-wrapper ul li.active .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
      .filter-rule-wrapper ul li:hover {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .filter-rule-wrapper ul li:hover .list-item-action img.more-icon {
          display: none; }
        .filter-rule-wrapper ul li:hover .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .filter-rule-wrapper ul li:hover .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .filter-rule-wrapper ul li:hover .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .filter-rule-wrapper ul li:hover .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
  .filter-rule-wrapper .add-btn-fixed {
    position: fixed !important;
    bottom: 20px;
    right: 100px;
    z-index: 999; }
  .filter-rule-wrapper .add-button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    position: unset; }
    .filter-rule-wrapper .add-button-box .add-btn {
      border: 1px solid #58514b;
      border-radius: 3px;
      height: 35px;
      border: 1px solid #58514b;
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 0.25px 0.25px 12px #00000029;
      cursor: pointer;
      color: #58514b;
      font-size: 12px; }
      .filter-rule-wrapper .add-button-box .add-btn p {
        font-size: 20px;
        color: #58514b;
        margin: 0;
        padding-right: 5px; }

.save-entry-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  height: 70px; }
  .save-entry-wrapper p {
    text-align: left;
    font: normal normal bold 16px/30px Lato;
    letter-spacing: 0.4px;
    color: black;
    margin: 0 10px; }
  .save-entry-wrapper button {
    width: 209px;
    height: 45px;
    margin: 0 10px;
    background: black 0% 0% no-repeat padding-box;
    border: 1px solid black;
    text-align: center;
    font: normal normal normal 18px/25px Lato;
    letter-spacing: 0.45px;
    color: #FFFFFF; }
  .save-entry-wrapper .search-text {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    width: 478px;
    height: 45px; }

.new-filter-wrapper {
  display: flex;
  height: calc(100% - 4rem); }
  .new-filter-wrapper .psuedo-dropdown {
    width: 80%;
    margin: 10px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding: 0 !important; }
  .new-filter-wrapper .filter-field {
    width: 80%;
    margin: 10px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding: 0 !important;
    color: #323643;
    font-size: 14px;
    font-weight: 500;
    height: 45px; }
  .new-filter-wrapper .ant-select-selection {
    border-bottom: 1px solid #eee;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding: 0 !important;
    width: 100%;
    color: black; }
  .new-filter-wrapper .main-list {
    width: 30%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 85%;
    overflow-x: auto; }
  .new-filter-wrapper .ant-pagination-item.ant-pagination-item-active a {
    color: black !important;
    margin-left: 5px; }
  .new-filter-wrapper .lookup-sub-list {
    width: 70%;
    border-right: 1px solid #eee;
    box-shadow: 3px 3px 8px -2px #eee;
    z-index: 11;
    height: 100%; }
  .new-filter-wrapper .button-box-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 150px;
    margin: 50px; }
    .new-filter-wrapper .button-box-wrapper .button-box {
      height: 45px;
      background: #000000 0% 0% no-repeat padding-box;
      margin: 5px;
      display: flex;
      padding: 10px; }
      .new-filter-wrapper .button-box-wrapper .button-box p {
        text-align: left;
        font: normal normal normal 14px/28px Lato;
        letter-spacing: 0.35px;
        color: #FFFFFF; }
      .new-filter-wrapper .button-box-wrapper .button-box span {
        width: 22px;
        height: 22px;
        color: #FFFFFF;
        margin: 0 10PX;
        text-align: right;
        cursor: pointer; }
  .new-filter-wrapper div.category-list-wrapper {
    height: 90%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    font-weight: 500; }
    .new-filter-wrapper div.category-list-wrapper .circular-loader {
      margin-bottom: 10px; }
  .new-filter-wrapper .list-head {
    display: flex;
    height: 2.5rem; }
    .new-filter-wrapper .list-head .list-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e1e1;
      font-weight: 500;
      color: #000000;
      cursor: pointer; }
    .new-filter-wrapper .list-head .active {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      background-color: #58514b;
      color: #ffffff; }
  .new-filter-wrapper ul {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 90%;
    overflow-y: scroll; }
    .new-filter-wrapper ul::-webkit-scrollbar {
      -webkit-appearance: none; }
    .new-filter-wrapper ul::-webkit-scrollbar:vertical {
      width: 5px; }
    .new-filter-wrapper ul::-webkit-scrollbar:horizontal {
      height: 0px; }
    .new-filter-wrapper ul::-webkit-scrollbar-thumb {
      background-color: #fce9d1;
      border-radius: 5px;
      border: 2px solid transparent !important; }
    .new-filter-wrapper ul::-webkit-scrollbar-track {
      border-radius: 50%;
      background-color: transparent !important; }
    .new-filter-wrapper ul li {
      display: flex;
      align-items: center;
      height: 2.5rem;
      color: #000000;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer; }
      .new-filter-wrapper ul li .list-item-content {
        display: flex;
        align-items: center;
        flex: 3 1;
        padding: 0 0 0 1rem;
        height: 100%; }
        .new-filter-wrapper ul li .list-item-content img {
          width: 22px;
          height: 22px;
          object-fit: contain;
          margin-right: 15px;
          padding: 2px 1px;
          background-color: #eee;
          border: 1px solid #dcdcdc;
          border-radius: 2px; }
        .new-filter-wrapper ul li .list-item-content p {
          margin: 0;
          color: #000000;
          font-size: 13px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 12rem; }
      .new-filter-wrapper ul li .list-item-action {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem 0 0rem;
        flex: 1 1;
        height: 100%; }
        .new-filter-wrapper ul li .list-item-action img.more-icon {
          display: block;
          width: 22px; }
        .new-filter-wrapper ul li .list-item-action .edit-options {
          display: none; }
      .new-filter-wrapper ul li.active {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .new-filter-wrapper ul li.active .list-item-action img.more-icon {
          display: none; }
        .new-filter-wrapper ul li.active .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .new-filter-wrapper ul li.active .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .new-filter-wrapper ul li.active .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .new-filter-wrapper ul li.active .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
      .new-filter-wrapper ul li:hover {
        background-color: #f2d6a0;
        border-left: 3px solid #d2502d;
        transition: all 0.3s, height 0s;
        font-weight: 500; }
        .new-filter-wrapper ul li:hover .list-item-action img.more-icon {
          display: none; }
        .new-filter-wrapper ul li:hover .list-item-action .edit-options {
          display: flex;
          width: 100%;
          height: 100%; }
          .new-filter-wrapper ul li:hover .list-item-action .edit-options .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%; }
            .new-filter-wrapper ul li:hover .list-item-action .edit-options .image-wrapper img {
              width: 20px; }
            .new-filter-wrapper ul li:hover .list-item-action .edit-options .image-wrapper:hover {
              background-color: #58514b; }
  .new-filter-wrapper .add-btn-fixed {
    position: fixed !important;
    bottom: 20px;
    right: 100px;
    z-index: 999; }
  .new-filter-wrapper .add-button-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    position: unset; }
    .new-filter-wrapper .add-button-box .add-btn {
      border: 1px solid #58514b;
      border-radius: 3px;
      height: 35px;
      border: 1px solid #58514b;
      display: flex;
      align-items: center;
      margin-right: 20px;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: 0.25px 0.25px 12px #00000029;
      cursor: pointer;
      color: #58514b;
      font-size: 12px; }
      .new-filter-wrapper .add-button-box .add-btn p {
        font-size: 20px;
        color: #58514b;
        margin: 0;
        padding-right: 5px; }

.bulk-main-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  padding: 10px 100px; }
  .bulk-main-container .form-control {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .bulk-main-container .form-control input {
      width: 100%; }
  .bulk-main-container .add-action {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem; }
    .bulk-main-container .add-action button {
      width: 170px; }
      .bulk-main-container .add-action button:first-of-type {
        margin-right: 2%; }
    .bulk-main-container .add-action .modal-button-loader {
      position: absolute;
      right: 22%;
      color: #ffffff !important; }
  .bulk-main-container .btn-color {
    color: #ffffff !important; }
  .bulk-main-container .error-list {
    width: 100%; }
    .bulk-main-container .error-list li {
      color: red; }

#manage-coupons .ant-pagination-item.ant-pagination-item-active a {
  color: black !important;
  margin-left: 5px; }

#manage-coupons .ant-table-pagination.ant-pagination {
  float: none; }

#manage-coupons .coupon-list {
  width: 100%;
  border-right: 1px solid #eee;
  box-shadow: 3px 3px 8px -2px #eee;
  z-index: 11;
  height: 100%; }

#manage-coupons .category-list-wrapper {
  height: 90%;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  font-weight: 500; }
  #manage-coupons .category-list-wrapper .circular-loader {
    margin-bottom: 10px; }

.add-coupon-dialog .dialog-title {
  border-bottom: 1px solid #eee; }
  .add-coupon-dialog .dialog-title h2 {
    font-size: 28px !important;
    font-weight: 700;
    color: #58514b; }

.add-coupon-dialog .add-coupon-content {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  padding: 2% 14%;
  overflow-y: unset; }
  .add-coupon-dialog .add-coupon-content .add-coupon-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center; }
  .add-coupon-dialog .add-coupon-content .form-control {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .add-coupon-dialog .add-coupon-content .form-control input {
      width: 100%; }

.add-coupon-dialog .add-coupon-action {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2rem; }
  .add-coupon-dialog .add-coupon-action button {
    width: 170px; }
    .add-coupon-dialog .add-coupon-action button:first-of-type {
      margin-right: 2%; }

.add-coupon-dialog .btn-orange {
  height: 45px;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #58514b;
  border: 1px solid #bababa;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .add-coupon-dialog .btn-orange:hover, .add-coupon-dialog .btn-orange:active, .add-coupon-dialog .btn-orange:focus {
    opacity: 0.7; }
  .add-coupon-dialog .btn-orange:disabled {
    background-color: #d0d5dc;
    border: 1px solid #d0d5dc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none; }
    .add-coupon-dialog .btn-orange:disabled:hover {
      transition: none; }

.add-coupon-dialog .btn-cancel {
  height: 45px;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #e1e1e1;
  border: 1px solid #bababa;
  text-transform: uppercase;
  color: #323643;
  font-size: 13px;
  font-weight: 500;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .add-coupon-dialog .btn-cancel:hover, .add-coupon-dialog .btn-cancel:active, .add-coupon-dialog .btn-cancel:focus {
    opacity: 0.7; }
  .add-coupon-dialog .btn-cancel:disabled {
    background-color: #d0d5dc;
    border: 1px solid #d0d5dc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none; }
    .add-coupon-dialog .btn-cancel:disabled:hover {
      transition: none; }

* {
  outline: none !important; }

html,
body,
#root {
  height: 100%;
  margin: 0; }

body {
  font-family: Roboto, sans-serif;
  box-sizing: border-box; }

div {
  box-sizing: border-box; }

button {
  font-family: Roboto, sans-serif;
  cursor: pointer; }

.close-btn {
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
div,
span,
ul,
li {
  font-family: Roboto, sans-serif; }

.circular-loader {
  color: #58514b !important;
  z-index: 100; }

.form-label {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #707070; }

.form-input {
  margin: 0rem 0rem 1rem;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  height: 45px;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #323643; }
  .form-input:focus, .form-input:active {
    border: 2px solid #58514b; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(/static/media/Arrow_down.666a053f.svg) no-repeat 98% 50%; }
  select:disabled {
    background-color: #ebebe4; }

textarea {
  resize: none !important; }

select::-webkit-input-placeholder {
  color: #eeeeee !important; }

select::-webkit-input-placeholder,
select::-moz-placeholder,
select::-ms-placeholder,
select::placeholder {
  color: #eeeeee !important; }

input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  opacity: 0;
  cursor: pointer; }

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none; }

.btn-orange {
  height: 45px;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #58514b;
  border: 1px solid #bababa;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .btn-orange:hover, .btn-orange:active, .btn-orange:focus {
    opacity: 0.7; }
  .btn-orange:disabled {
    background-color: #d0d5dc;
    border: 1px solid #d0d5dc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none; }
    .btn-orange:disabled:hover {
      transition: none; }

.btn-cancel {
  height: 45px;
  padding: 3px 10px;
  border-radius: 3px;
  background-color: #e1e1e1;
  border: 1px solid #bababa;
  text-transform: uppercase;
  color: #323643;
  font-size: 13px;
  font-weight: 500;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .btn-cancel:hover, .btn-cancel:active, .btn-cancel:focus {
    opacity: 0.7; }
  .btn-cancel:disabled {
    background-color: #d0d5dc;
    border: 1px solid #d0d5dc;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none; }
    .btn-cancel:disabled:hover {
      transition: none; }

.dialog-title {
  margin: 0 1rem 10px !important; }
  .dialog-title h2 {
    font-size: 15px !important;
    color: #000;
    text-align: center; }

.dialog-backdrop {
  background-color: rgba(250, 250, 250, 0.57) !important; }

.ant-modal-mask {
  background-color: rgba(250, 250, 250, 0.57) !important; }

.ant-modal {
  width: 25rem !important;
  top: 40px !important; }

.ant-modal-header {
  margin: 0 1rem 0 !important; }
  .ant-modal-header .ant-modal-title {
    font-size: 15px !important;
    color: #000;
    text-align: center; }

.text-normal {
  font-weight: 400 !important; }

.text-semibold {
  font-weight: 500 !important; }

.text-bold {
  font-weight: 700 !important; }

.text-center {
  text-align: center !important; }

.text-red {
  color: #ff0000 !important; }

.text-lightRed {
  color: #ffabab !important; }

.text-orange {
  color: #fe9600 !important; }

.text-skyblue {
  color: #2680eb !important; }

.text-green {
  color: #00a400 !important; }

.text-lightOrange {
  color: #f8efe3 !important; }

.text-black {
  color: #000 !important; }

.bg-red {
  background: #ff0000 !important; }

.bg-lightRed {
  background: #ffabab !important; }

.bg-orange {
  background: #fe9600 !important; }

.bg-lightOrange {
  background: #f8efe3 !important; }

.pointer {
  cursor: pointer !important; }

textarea {
  resize: none; }

.custom-scroll {
  overflow: scroll; }
  .custom-scroll::-webkit-scrollbar {
    -webkit-appearance: none; }
  .custom-scroll::-webkit-scrollbar:vertical {
    width: 12px; }
  .custom-scroll::-webkit-scrollbar:horizontal {
    height: 0px; }
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #c4c9ca;
    border-radius: 0px;
    border: 2px solid transparent !important; }
  .custom-scroll::-webkit-scrollbar-track {
    border-radius: 50%;
    background-color: transparent !important; }

.w-50 {
  width: 50%; }

.w-100 {
  width: 100%; }

.animated-border span {
  transition: all 0.2s ease-in-out;
  position: relative; }
  .animated-border span:before, .animated-border span:after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: all 0.2s ease-in-out;
    transition-duration: 0.4s;
    opacity: 0; }
  .animated-border span:before {
    left: calc(50%);
    background-color: #58514b; }
  .animated-border span:after {
    right: calc(50%);
    background-color: #58514b; }

.animated-border:hover {
  cursor: pointer; }
  .animated-border:hover span:before, .animated-border:hover span:after {
    width: 50%;
    opacity: 1; }

.floating-input-wrapper {
  position: relative;
  width: 100%; }

.floating-input-wrapper input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  margin: 0rem 0rem 1rem;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  height: 45px;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #323643; }

.floating-input-wrapper span {
  position: absolute;
  transition: 0.3s;
  pointer-events: none;
  background-color: #fff;
  font-family: Roboto;
  font-weight: 500;
  top: -5px;
  left: 12px;
  font-size: 12px;
  padding: 0px 4px;
  color: #000;
  line-height: 1; }

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus,
input[type="text"]:active,
input[type="email"]:active,
input[type="url"]:active,
input[type="password"]:active,
input[type="search"]:active,
input[type="number"]:active,
input[type="tel"]:active,
input[type="range"]:active,
input[type="date"]:active,
input[type="month"]:active,
input[type="week"]:active,
input[type="time"]:active,
input[type="datetime"]:active,
input[type="datetime-local"]:active,
input[type="color"]:active,
textarea:active {
  border: 2px solid #58514b; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important; }

input[type=number] {
  -moz-appearance: textfield !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #58514b !important;
  box-shadow: none !important; }

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #58514b !important; }

/* Ant Cascader */
.ant-cascader-picker.scroll-cascader {
  padding: 0;
  border-radius: 0; }

.ant-cascader-picker.scroll-cascader .ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  background-color: transparent !important;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  padding-right: 1.5rem;
  font-size: 14px;
  height: 45px;
  border-radius: 0; }

.ant-cascader-picker.scroll-cascader .ant-cascader-picker-label {
  height: 100%;
  top: 0;
  margin-top: 0px;
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #58514b;
  line-height: 35px; }

.scroll-cascader .ant-cascader-picker-label:hover + .ant-cascader-input {
  box-shadow: none !important;
  border: 1px solid #e5e5e5 !important; }

.scroll-cascader.ant-cascader-picker:focus .ant-cascader-input,
.ant-input:focus {
  box-shadow: none !important;
  border: 2px solid #58514b !important;
  border-right-width: 2px !important; }

/* Dropdown */
.ant-cascader-menus.scroll-cascader-menu {
  z-index: 1400 !important;
  max-width: 50% !important;
  overflow-x: scroll !important;
  border: 1px solid #e5e5e5; }

.ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu {
  min-width: 8rem !important; }

.ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu-item:hover {
  background: rgba(0, 0, 0, 0.158); }

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar {
  -webkit-appearance: none; }

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:vertical {
  width: 0px; }

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:horizontal {
  height: 5px; }

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.158);
  border-radius: 0px;
  border: 2px solid transparent !important; }

.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-track {
  border-radius: 50%;
  background-color: transparent !important; }

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #f5f5f5 !important;
  color: #58514b; }

.confirm-popup-overlay {
  z-index: 1800 !important; }

.confirm-popup-overlay .ant-popover-message {
  display: flex;
  align-items: center;
  justify-content: center; }

.confirm-popup-overlay .ant-popover-message img {
  width: 15px; }

.confirm-popup-overlay .ant-popover-message-title {
  padding-left: 10px; }

.confirm-popup-overlay .ant-btn-primary {
  color: #fff;
  background-color: #58514B;
  border-color: #58514B; }

.confirm-popup-overlay .ant-btn {
  cursor: pointer;
  border-radius: 0;
  font-size: 13px;
  height: 30px;
  padding: 0 2rem;
  font-weight: 500; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mt-1 {
  margin-top: 1rem !important; }

.mt-2 {
  margin-top: 2rem !important; }

.mt-3 {
  margin-top: 3rem !important; }

.mt-4 {
  margin-top: 4rem !important; }

.mt-5 {
  margin-top: 5rem !important; }

.mb-1 {
  margin-bottom: 1rem !important; }

.mb-2 {
  margin-bottom: 2rem !important; }

.mb-3 {
  margin-bottom: 3rem !important; }

.mb-4 {
  margin-bottom: 4rem !important; }

.mb-5 {
  margin-bottom: 5rem !important; }

.mr-05 {
  margin-right: 0.5rem !important; }

.mr-1 {
  margin-right: 1rem !important; }

.mr-2 {
  margin-right: 2rem !important; }

.mr-3 {
  margin-right: 3rem !important; }

.mr-4 {
  margin-right: 4rem !important; }

.mr-5 {
  margin-right: 5rem !important; }

.mr-6 {
  margin-right: 6rem !important; }

.mr-7 {
  margin-right: 7rem !important; }

.ml-1 {
  margin-left: 1rem !important; }

.ml-2 {
  margin-left: 2rem !important; }

.ml-3 {
  margin-left: 3rem !important; }

.ml-4 {
  margin-left: 4rem !important; }

.ml-5 {
  margin-left: 5rem !important; }

.p-0 {
  padding: 0 !important; }

.pr-0 {
  padding-right: 0rem !important; }

.pr-1 {
  padding-right: 1rem !important; }

.pr-2 {
  padding-right: 2rem !important; }

.pr-3 {
  padding-right: 3rem !important; }

.pr-4 {
  padding-right: 4rem !important; }

.pr-5 {
  padding-right: 5rem !important; }

.pl-1 {
  padding-left: 1rem !important; }

.pl-2 {
  padding-left: 2rem !important; }

.pl-3 {
  padding-left: 3rem !important; }

.pl-4 {
  padding-left: 4rem !important; }

.pl-5 {
  padding-left: 5rem !important; }

.pt-1 {
  padding-top: 1rem !important; }

.pt-2 {
  padding-top: 2rem !important; }

.pt-3 {
  padding-top: 3rem !important; }

.pt-4 {
  padding-top: 4rem !important; }

.pt-5 {
  padding-top: 5rem !important; }

.pb-1 {
  padding-bottom: 1rem !important; }

.pb-2 {
  padding-bottom: 2rem !important; }

.pb-3 {
  padding-bottom: 3rem !important; }

.pb-4 {
  padding-bottom: 4rem !important; }

.pb-5 {
  padding-bottom: 5rem !important; }

