.confirmation-dialog {
    .confirmation-content {
        width: 25rem;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 520px) {
            width: 100%;
        }
        p {
            font-size: 14px;
            text-align: center;
            line-height: 1.5;
        }
        
        button {
            height: 35px;
            min-width: 10rem;
            margin-top: 1rem;
            .modal-button-loader {
                position: absolute;
                left: 50%;
            }
        }
    }
}