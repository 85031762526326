.manageusers-wrapper {
	overflow-x: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	height: calc(100% - 4rem);
	// justify-content: space-between;
	// &::-webkit-scrollbar {
	// 	-webkit-appearance: none;
	// }
	// &::-webkit-scrollbar:vertical {
	// 	width: 0px;
	// }
	
	.filter-wrapper {
		display: flex;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 2rem;
		.filter-img {
			width: 26px;
			margin-right: 5px;
		}
		.filter-field {
			width: 200px;
			margin:0 5px;
			.ant-select-selection {
				height: 45px !important;
			}
			.ant-select-selection__rendered {
				line-height: 45px;
			}
		}
	}
	.top-wrapper {
		display: flex;
		justify-content: center;
		margin: 20px 0;
		span {
			height: 35px;
			border: 1px solid #929190;
			width: 8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #989898;
			cursor: pointer;
		}
		.active {
			background-color: #58514b;
			color: #ffffff;
		}
		.second-active {
			margin-left: 20px;
			background-color: #58514b;
			color: #ffffff;
		}
		.second-span {
			margin-left: 20px;
		}
	}
	.colWrapper {
		display: flex;
		align-items: center;
		width: 12rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		.colImage {
			height: 27px;
			width: 27px;
			object-fit: fill;
		}
		.colText {
			color: #000000;
			margin: 0;
			font-size: 14px;
			font-weight: 400;
			padding-left: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 12rem;
		}
		.icon-image {
			height: 27px;
			width: 27px;
			border: 1px solid #5a5a5a;
			font-size: 21px;
			padding: 2px;
			color: #1a1818;
		}
	}
	.star-image {
		margin-left: 18px;
		height: 15px;
		width: 15px;
	}
	.countText {
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 600;
		color: #000000;
		text-align: center;
		margin-right: 3rem;
	}
	.mailtext {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 12rem;
	}
	.textBlack {
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		color: #000000;
	}
	.textnewBlack {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #000000;
		text-align: center;
	}
	.textnewYellow {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #d2a44c;
		text-align: center;
	}
	.textOrange {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #fe9600;
	}

	.textBlue {
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		color: #0b969b;
	}
	.textDarkBlue {
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		color: #0072d6;
	}
	.textGreen {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #499b0b;
	}
	.textBrown {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #d2502d;
	}
	.textRed {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #9b0b1e;
	}
	.empty-table {
		display: flex;
		justify-content: center;
		height: calc(100% - 2rem);
		width: 100%;
		align-items: center;
		font-size: 15px;
		color: #000000;
	}

	.ant-select {
		margin-right: 10px;
	}
	.ant-select-selection:focus,
	.ant-select-selection:active,
	.ant-select-selection:hover {
		border-color: #000000;
		-webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
		box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
	}
	.ant-table {
		color: #000000;
		font-weight: 400;
		margin-bottom: 20px;
		@media screen and (max-width: 1395px) {
			overflow-x: scroll;
			overflow-y: scroll;
		}
	}
	.ant-table-thead > tr > th {
		padding: 10px 10px 10px 2.2rem;
		background-color: #f5f5f5;
		color: #000000;
	}
	.ant-table-tbody > tr {
		cursor: pointer;
		border-left: 4px solid #ffffff;
		&:hover {
			border-left: 4px solid #d2502d;
		}
	}
	.ant-table-tbody > tr > td {
		padding: 10px 10px 10px 2rem;
		border-bottom: none;
		p {
			margin-bottom: 0;
		}
	}
	.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
		background: #e3d2b0;
	}
	.ant-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 2rem;
		margin-bottom: 20px;
	}
	.ant-pagination-prev {
		min-width: 22px;
		height: 22px;
		line-height: 22px;
	}
	.ant-pagination-item {
		min-width: 22px;
		height: 22px;
		line-height: 22px;
		border-color: #eeeeee;
		border-radius: 0;
	}
	.ant-pagination-next {
		min-width: 22px;
		height: 22px;
		line-height: 22px;
	}
	.ant-pagination-jump-next {
		min-width: 22px;
		height: 22px;
		line-height: 22px;
	}
	.ant-pagination-jump-prev {
		min-width: 22px;
		height: 22px;
		line-height: 22px;
	}
	.ant-pagination-item a {
		color: #000000;
	}
	.ant-pagination-item:focus a,
	.ant-pagination-item:hover a {
		color: #58514b;
	}
	.ant-pagination-item-active:focus a,
	.ant-pagination-item-active:hover a,
	.ant-pagination-item.ant-pagination-item-active a {
		border-color: #58514b;
		color: #ffffff;
	}
	.ant-pagination-item-active {
		background-color: #58514b;
	}
	.ant-pagination-item:focus,
	.ant-pagination-item:hover {
		border-color: #58514b;
	}
	.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
		color: #58514b;
	}
	.anticon {
		color: #58514b;
	}
	.ant-pagination-prev .ant-pagination-item-link {
		border-color: #eeeeee;
		border-radius: 0;
	}
	.ant-pagination-next .ant-pagination-item-link {
		border-color: #eeeeee;
		border-radius: 0;
	}
	.ant-pagination-prev:focus .ant-pagination-item-link,
	.ant-pagination-next:focus .ant-pagination-item-link,
	.ant-pagination-prev:hover .ant-pagination-item-link,
	.ant-pagination-next:hover .ant-pagination-item-link {
		border-color: #58514b;
	}
	.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
	.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
		color: #58514b;
	}
	.circular-loader {
		top: 50%;
		position: absolute;
		left: 56%;
	}
	.action-span {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0px;
		img {
			height: 20px;
			width: 20px;
			margin-right: 0px;
		}
		.pop-item {
			color: #ffffff;
			margin: 0;
			&:hover {
				color: #ad9972;
				cursor: pointer;
			}
		}
	}
}

.manage-users-action-popover .ant-popover-inner {
	background-color: #000000;
}

.manage-users-action-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #000000;
	border-left-color: #000000;
}
.action-image {
	height: 25px;
	width: 25px;
	object-fit: contain;
	margin-left: 10px;
	&:hover {
		cursor: pointer;
	}
}
.action-span {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	color: #8b8b8b;
	justify-content: flex-start;
	cursor: pointer;
	.anticon {
		margin-right: 8px;
	}
	img {
		height: 20px;
		width: 20px;
		margin-right: 5px;
	}
	.pop-item {
		color: #ffffff;
		margin: 0;
		&:hover {
			color: #ad9972;
		}
	}
}
