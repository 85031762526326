* {
    outline: none !important;
}

html,
body,
#root {
    height: 100%;
    margin: 0;
}

body {
    // font-family: 'Noto Sans KR', Roboto, sans-serif;
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
}

div {
    box-sizing: border-box;
}

// ----------------------- Elements ----------------------- //

button {
    // font-family: 'Noto Sans KR', Roboto, sans-serif;
    font-family: Roboto, sans-serif;
    cursor: pointer;
}

.close-btn {
    position: absolute;
    right: 0;
    padding: 10px;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
div,
span,
ul,
li {
    // font-family: 'Noto Sans KR', Roboto, sans-serif;
    font-family: Roboto, sans-serif;
}

.circular-loader {
    color: #58514b !important;
    z-index: 100;
}

// ----------------------- Forms ----------------------- //
.form-label {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #707070;
}

.form-input {
    margin: 0rem 0rem 1rem;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    height: 45px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #323643;
    &:focus,
    &:active {
        border: 2px solid #58514b;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("./assets/images/common/Arrow_down.svg") no-repeat 98% 50%;
    &:disabled {
        background-color: rgb(235, 235, 228);
    }
}

textarea {
    resize: none !important;
}

// Placeholders
input::-webkit-input-placeholder,
input::-moz-placeholder,
input::-ms-placeholder,
input::placeholder {
    color: #eeeeee !important;
}

textarea::-webkit-input-placeholder,
textarea::-moz-placeholder,
textarea::-ms-placeholder,
textarea::placeholder {
    color: #eeeeee !important;
}

select::-webkit-input-placeholder,
select::-moz-placeholder,
select::-ms-placeholder,
select::placeholder {
    color: #eeeeee !important;
}

// ------- Date Field -------- //

input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
    cursor: pointer;
    // display: none;
}

input[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

// input[type=date]::-webkit-clear-button {
//     /* Hide the button */
//     display: none;
//     /* turn off default browser styling */
//     -webkit-appearance: none;
// }

// ------- Buttons ------- //

.btn-orange {
    height: 45px;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #58514b;
    border: 1px solid #bababa;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
    &:disabled {
        background-color: #d0d5dc;
        border: 1px solid #d0d5dc;
        color: #fff;
        cursor: not-allowed;
        pointer-events: none;
        &:hover {
            transition: none;
        }
    }
}

.btn-cancel {
    height: 45px;
    padding: 3px 10px;
    border-radius: 3px;
    background-color: #e1e1e1;
    border: 1px solid #bababa;
    text-transform: uppercase;
    color: #323643;
    font-size: 13px;
    font-weight: 500;
    transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover,
    &:active,
    &:focus {
        opacity: 0.7;
    }
    &:disabled {
        background-color: #d0d5dc;
        border: 1px solid #d0d5dc;
        color: #fff;
        cursor: not-allowed;
        pointer-events: none;
        &:hover {
            transition: none;
        }
    }
}

// ----------------------- Modal ----------------------- //
.dialog-title {
    margin: 0 1rem 10px !important;
    // border-bottom: 1px solid #EEEEEE;
    h2 {
        font-size: 15px !important;
        color: #000;
        text-align: center;
    }
}
.dialog-backdrop {
    background-color: rgba(250, 250, 250, 0.57) !important;
}

.ant-modal-mask {
    background-color: rgba(250, 250, 250, 0.57) !important;
}
.ant-modal {
    width: 25rem !important;
    top: 40px !important;
}
.ant-modal-header {
    margin: 0 1rem 0 !important;
    .ant-modal-title {
        font-size: 15px !important;
        color: #000;
        text-align: center;
    }
}

// ----------------------- Font ----------------------- //
.text-normal {
    font-weight: 400 !important;
}
.text-semibold {
    font-weight: 500 !important;
}
.text-bold {
    font-weight: 700 !important;
}
.text-center {
    text-align: center !important;
}
.text-red {
    color: #ff0000 !important;
}
.text-lightRed {
    color: #ffabab !important;
}
.text-orange {
    color: #fe9600 !important;
}
.text-skyblue {
    color: #2680eb !important;
}
.text-green {
    color: #00a400 !important;
}
.text-lightOrange {
    color: #f8efe3 !important;
}

.text-black {
    color: #000 !important;
}

.bg-red {
    background: #ff0000 !important;
}
.bg-lightRed {
    background: #ffabab !important;
}
.bg-orange {
    background: #fe9600 !important;
}
.bg-lightOrange {
    background: #f8efe3 !important;
}
// ----------------------- General Styles ----------------------- //
.pointer {
    cursor: pointer !important;
}

textarea {
    resize: none;
}

.custom-scroll {
    overflow: scroll;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 12px;
    }
    &::-webkit-scrollbar:horizontal {
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c4c9ca;
        border-radius: 0px;
        border: 2px solid transparent !important;
    }
    &::-webkit-scrollbar-track {
        border-radius: 50%;
        background-color: transparent !important;
    }
}

.w-50 {
    width: 50%;
}
.w-100 {
    width: 100%;
}

// ----------------------- ANIMATIONS ----------------------- //

// Animation for a border starts from center to ends
// Add class .animated-border to div and use a span element to wrap the text
// <div class="animated-border"> <span> TEXT </span> </div>

.animated-border {
    span {
        transition: all 0.2s ease-in-out;
        position: relative;
        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            width: 0px;
            height: 2px;
            margin: 5px 0 0;
            transition: all 0.2s ease-in-out;
            transition-duration: 0.4s;
            opacity: 0;
        }
        &:before {
            left: calc(50%);
            background-color: #58514b;
        }
        &:after {
            right: calc(50%);
            background-color: #58514b;
        }
    }
    &:hover {
        cursor: pointer;
        span {
            &:before,
            &:after {
                width: 50%;
                opacity: 1;
            }
        }
    }
}

// Animated Input - Floating label
.floating-input-wrapper {
    position: relative;
    width: 100%;
}
.floating-input-wrapper input {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    margin: 0rem 0rem 1rem;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    height: 45px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #323643;
}
.floating-input-wrapper span {
    position: absolute;
    transition: 0.3s;
    pointer-events: none;
    background-color: #fff;
    font-family: Roboto;
    font-weight: 500;
    top: -5px;
    left: 12px;
    font-size: 12px;
    padding: 0px 4px;
    color: #000;
    line-height: 1;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus,
input[type="text"]:active,
input[type="email"]:active,
input[type="url"]:active,
input[type="password"]:active,
input[type="search"]:active,
input[type="number"]:active,
input[type="tel"]:active,
input[type="range"]:active,
input[type="date"]:active,
input[type="month"]:active,
input[type="week"]:active,
input[type="time"]:active,
input[type="datetime"]:active,
input[type="datetime-local"]:active,
input[type="color"]:active,
textarea:active {
    border: 2px solid #58514b;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

input[type=number] {
    -moz-appearance:textfield !important;
}
// Disable the bacground color when input field is autofilled
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
    border-color: #58514b !important;
    box-shadow: none !important;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: #58514b !important;
}
/* Ant Cascader */
.ant-cascader-picker.scroll-cascader {
  padding: 0;
  border-radius: 0;
}
.ant-cascader-picker.scroll-cascader .ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  background-color: transparent !important;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  padding-right: 1.5rem;
  font-size: 14px;
  height: 45px;
  border-radius: 0;
}
.ant-cascader-picker.scroll-cascader .ant-cascader-picker-label {
  height: 100%;
  top: 0;
  margin-top: 0px;
  padding-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #58514b;
  line-height: 35px;
}
.scroll-cascader .ant-cascader-picker-label:hover + .ant-cascader-input {
  box-shadow: none !important;
  border: 1px solid #e5e5e5 !important;
}
.scroll-cascader.ant-cascader-picker:focus .ant-cascader-input,
.ant-input:focus {
  box-shadow: none !important;
  border: 2px solid #58514b !important;
  border-right-width: 2px !important;
}

/* Dropdown */
.ant-cascader-menus.scroll-cascader-menu {
  z-index: 1400 !important;
  max-width: 50% !important;
  overflow-x: scroll !important;
  border: 1px solid #e5e5e5;
}
.ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu {
  min-width: 8rem !important;
}
.ant-cascader-menus.scroll-cascader-menu .ant-cascader-menu-item:hover {
  background: rgba(0, 0, 0, 0.158);
}
.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar {
  -webkit-appearance: none;
}
.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:vertical {
  width: 0px;
}
.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar:horizontal {
  height: 5px;
}
.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.158);
  border-radius: 0px;
  border: 2px solid transparent !important;
}
.ant-cascader-menus.scroll-cascader-menu::-webkit-scrollbar-track {
  border-radius: 50%;
  background-color: transparent !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #f5f5f5 !important;
  color: #58514b;
}
// popup confirm
.confirm-popup-overlay {
    z-index: 1800 !important;
}
.confirm-popup-overlay .ant-popover-message {
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirm-popup-overlay .ant-popover-message img {
    width: 15px;
}
.confirm-popup-overlay .ant-popover-message-title {
    padding-left: 10px;
}
.confirm-popup-overlay .ant-btn-primary {
    color: #fff;
    background-color: #58514B;
    border-color: #58514B;
}
.confirm-popup-overlay .ant-btn {
    cursor: pointer;
    border-radius: 0;
    font-size: 13px;
    height: 30px;
    padding: 0 2rem;
    font-weight: 500;
}

// ----------------------- Margins and Paddings ----------------------- //
.m-0 {
    margin: 0 !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.ml-0 {
    margin-left: 0 !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mt-2 {
    margin-top: 2rem !important;
}
.mt-3 {
    margin-top: 3rem !important;
}
.mt-4 {
    margin-top: 4rem !important;
}
.mt-5 {
    margin-top: 5rem !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-2 {
    margin-bottom: 2rem !important;
}
.mb-3 {
    margin-bottom: 3rem !important;
}
.mb-4 {
    margin-bottom: 4rem !important;
}
.mb-5 {
    margin-bottom: 5rem !important;
}
.mr-05 {
    margin-right: 0.5rem !important;
}
.mr-1 {
    margin-right: 1rem !important;
}
.mr-2 {
    margin-right: 2rem !important;
}
.mr-3 {
    margin-right: 3rem !important;
}
.mr-4 {
    margin-right: 4rem !important;
}
.mr-5 {
    margin-right: 5rem !important;
}
.mr-6 {
    margin-right: 6rem !important;
}
.mr-7 {
    margin-right: 7rem !important;
}
.ml-1 {
    margin-left: 1rem !important;
}
.ml-2 {
    margin-left: 2rem !important;
}
.ml-3 {
    margin-left: 3rem !important;
}
.ml-4 {
    margin-left: 4rem !important;
}
.ml-5 {
    margin-left: 5rem !important;
}

.p-0 {
    padding: 0 !important;
}
.pr-0 {
    padding-right: 0rem !important;
}
.pr-1 {
    padding-right: 1rem !important;
}
.pr-2 {
    padding-right: 2rem !important;
}
.pr-3 {
    padding-right: 3rem !important;
}
.pr-4 {
    padding-right: 4rem !important;
}
.pr-5 {
    padding-right: 5rem !important;
}
.pl-1 {
    padding-left: 1rem !important;
}
.pl-2 {
    padding-left: 2rem !important;
}
.pl-3 {
    padding-left: 3rem !important;
}
.pl-4 {
    padding-left: 4rem !important;
}
.pl-5 {
    padding-left: 5rem !important;
}
.pt-1 {
    padding-top: 1rem !important;
}
.pt-2 {
    padding-top: 2rem !important;
}
.pt-3 {
    padding-top: 3rem !important;
}
.pt-4 {
    padding-top: 4rem !important;
}
.pt-5 {
    padding-top: 5rem !important;
}
.pb-1 {
    padding-bottom: 1rem !important;
}
.pb-2 {
    padding-bottom: 2rem !important;
}
.pb-3 {
    padding-bottom: 3rem !important;
}
.pb-4 {
    padding-bottom: 4rem !important;
}
.pb-5 {
    padding-bottom: 5rem !important;
}
